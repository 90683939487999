/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, Button, Divider } from 'antd';
import { faArrowRight, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoginButton from 'Src/common/components/authentication/loginButton';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import VerificationGroupTag from '../groupTag';

import './style.scss';

/**
 * This component is rendered when the given user does not have enough permissions
 * What I mean by permissions?
 * So lets us say that, you are creating a form and you would like only alumni to submit that form.
 * So as an admin you create a form with alumni as a verification group required to submit this form (This verification group will again have some joining form which will decide the joining criteria )
 * now when user comes to form url, there can be following scenarios
 * 1) User is authenticated and verified : user will be able to submit the form with
 * 2) User is authenticated and not verified: User will see list of verifications groups and see the button with Text Get verified and on click of it, it will call onVerify button with
 * 3) User is not authenticated: user will still see the verification groups list but now will see the button with the test Verify which is auth0 login button
*/
function VerificationGroupSelector({
  groups,
  isAuthenticated,
  onVerify,
  pendingVerifications,
  theme,
  title,
}) {
  return (
    <div className="verification-groups-container">
      <h5 className="arc-H500 arc-font-weight-400">{title}</h5>
      <p className="arc-H150 arc-font-weight-400 arc-color-black mt16">
        <I18nCustomFormatter id="group-selector-title" values={{ name: theme.collegeName }} />
      </p>
      <div className="mt16">
        {
          groups.map((group) => (
            <VerificationGroupTag group={group} key={group.id} />
          ))
        }
      </div>
      <Divider />
      <Choose>
        <When condition={!isAuthenticated}>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} className="arc-color-B55"/>
            <span className="arc-p arc-color-B55 ml8">
              <I18nCustomFormatter id="past-email-label" />
            </span>
          </p>
          <LoginButton type="primary" className="action-btn mt16" theme={theme}>
            <span className="arc-p arc-color-white">
              <I18nCustomFormatter id="authenticate-btn-text" />
            </span>
            <FontAwesomeIcon icon={faArrowRight} className="arc-color-white" />
          </LoginButton>
        </When>
        <Otherwise>
          <p>
            <FontAwesomeIcon icon={faInfoCircle} className="arc-color-B55"/>
            <span className="arc-p arc-color-B55 ml8">
              <I18nCustomFormatter id="group-selector-notice" />
            </span>
          </p>
          <Button type="primary" className="action-btn mt16" onClick={onVerify}>
            <span className="arc-p arc-color-white">
              <I18nCustomFormatter id="verify-btn-text" />
            </span>
            <FontAwesomeIcon icon={faArrowRight} className="arc-color-white" />
          </Button>
        </Otherwise>
      </Choose>
      {
        pendingVerifications.map((pendingVerification) => (
          <Alert
            key={pendingVerification.id}
            message={`Your verification request for '${pendingVerification.name}' is under review.`}
            type="warning"
            className="mt16 mb8"
          />
        ))
      }
    </div>
  );
}

export default VerificationGroupSelector;
