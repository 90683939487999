import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons';

import './style.scss';

const { Panel } = Collapse;

/**
 * Pinned post component
 */
function PinnedPosts({ posts }) {
  return (
    <div className="pinned-post-container">
      <Collapse defaultActiveKey={['1']}>
        <Panel header={<p className="arc-H100">PINNED POSTS</p>} key="1">
          {posts.map((post) => (
            <a href={`/sp/${post.id}`} key={post.id} className="pinned-post-item-wrapper">
              <FontAwesomeIcon icon={faThumbtack} className="mr8 pinned-post-icon" />
              <p>{post.heading}</p>
            </a>
          ))}
        </Panel>
      </Collapse>
    </div>
  );
}

PinnedPosts.defaultProps = {
  posts: [],
};

PinnedPosts.propTypes = {
  posts: arrayOf(shape()),
};

export default PinnedPosts;
