import React from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import './style.scss';

/**
 * Empty state component
 */
function EmptyState({ history, heading, description, buttonText, redirectLink, illustrationPath }) {
  const fadeInAndSlideProps = useSpring({ to: { opacity: 1, y: 0 }, from: { opacity: 0, y: 50 }, delay: 100 });

  /**
   * Handle redirect
   */
  function navigateTo(path) {
    history.push(path);
  }
  return (
    <animated.div className="not-found-container arc-text-align-c" style={fadeInAndSlideProps}>
      <img alt="Not Found" src={`${window.staticPath}${illustrationPath}`} />
      <h2 className="arc-H250 arc-color-B85 mt12 header">{heading}</h2>
      <p className="arc-color-B55 sub-header">{description}</p>
      <If condition={buttonText}>
        <Button type="primary" onClick={() => navigateTo(redirectLink)} className="mt32 mb32">
          {buttonText}
        </Button>
      </If>
    </animated.div>
  );
}

export default withRouter(EmptyState);
