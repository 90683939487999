import {
  SPACE_DETAILS_VFX_PATH,
  SPACE_POST_DETAILS_VFX_PATH,
  SPACE_EXPLORE_VFX_PATH,
  SPACE_FEED_VFX_PATH,
  SPACE_SUBSCRIPTIONS_VFX_PATH,
  CHANEL_FEED_VFX_PATH,
} from './endpoints';

import * as ROUTES from './routes';

export const LIST_OF_BANNER_IMAGES = [
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_9.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_10.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_11.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_12.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_13.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_14.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_15.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_16.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_17.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_18.png`,
  `${staticPath}img/react-modules/empty-cover-images/empty_cover_19.png`,
];

export const ROUTES_NAME = {
  EXPLORE: 'explore',
  FEED: 'feed',
  POST_DETAILS: 'postDetails',
  SUBSCRIPTION: 'subscription',
  SPACE_DETAILS: 'spaceDetails',
  CHANNEL_FEED: 'channelFeed',
};

export const VFX_ENDPOINTS = {
  explore: SPACE_EXPLORE_VFX_PATH,
  feed: SPACE_FEED_VFX_PATH,
  postDetails: SPACE_POST_DETAILS_VFX_PATH,
  subscription: SPACE_SUBSCRIPTIONS_VFX_PATH,
  spaceDetails: SPACE_DETAILS_VFX_PATH,
  channelFeed: CHANEL_FEED_VFX_PATH,
};

export const IMAGE_VARIANT = {
  small: 'size_small',
  medium: 'size_medium',
  large: 'size_large',
  original: 'original',
};

export const MEMBER_STATUSES = {
  AUTO: 'auto',
  APPROVED: 'approved',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  NOT_MEMBER: 'not_member',
};

export const ROUTES_WITHOUT_VFX = [ROUTES.FEEDS_PATH, ROUTES.BASE_PATH, ROUTES.EXPLORE_PATH, ROUTES.SUBSCRIPTION_PATH];

export const RESOURCE_TYPES = {
  LINK: 'link',
  DIRECTORY: 'directory',
};

export const POST_ACTION = {
  DELETE: 'delete',
  REPORT_ABUSE: 'report_abuse',
  SEND_AS_EMAIL: 'send_as_email',
  EDIT_POST: 'edit_post',
  PIN_POST: 'pin_post',
};
