/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { Avatar, notification } from 'antd';
import UserProfileContext from 'Src/spaces/context/user';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import PostModal from './modal';

import './style.scss';

const createPostComponent = ({ handleCreatePost, spaceId, isSpaceUnpublished }) => {
  const { userProfile } = useContext(UserProfileContext);
  const [createPostModalOpen, setCreatePostModalOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('createPostModal') === 'true') {
      toggleCreatePostModal();
    }
  }, []);
  /**
   * Toggles the create post modal
   */
  function toggleCreatePostModal() {
    if (isSpaceUnpublished) {
      notification.error({
        message: 'Adding topics or posts to unpublished space is not allowed',
        description:
          'We do not allow adding topics and posts to unpublished space. Please publish this space to add topics and posts to space.',
      });
    } else {
      setCreatePostModalOpen(!createPostModalOpen);
    }
  }

  /**
   * Handles enter key press
   */
  function handleEnterKeyPress(event, func) {
    if (event.key === 'Enter') {
      func();
    }
  }

  /**
   * Handle post submit
   */
  function handlePostSubmission(values, sendAsEmail) {
    handleCreatePost(values, sendAsEmail);
    setCreatePostModalOpen(false);
  }

  return (
    <div>
      <div
        className="create-post-container"
        role="button"
        tabIndex={0}
        onClick={toggleCreatePostModal}
        onKeyDown={(e) => handleEnterKeyPress(e, toggleCreatePostModal)}>
        <Avatar src={userProfile ? userProfile.present_picture_url : null} />
        <p className="create-post-text">
          <I18nCustomFormatter id="create-post-label" />
        </p>
      </div>
      <If condition={createPostModalOpen}>
        <PostModal handleSubmit={handlePostSubmission} spaceId={spaceId} togglePostModal={toggleCreatePostModal} />
      </If>
    </div>
  );
};

export default createPostComponent;
