import React from 'react';
import { LoadingOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Input, Modal, Avatar, Tooltip } from 'antd';
import { isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { Share } from 'arcl';
import { faShare } from '@fortawesome/pro-solid-svg-icons';
import { faMessage, faSort } from '@fortawesome/pro-light-svg-icons';
import { getProfileAttributeLabel } from 'Src/spaces/components/common';

import './style.scss';

function SubscribersModal({
  count,
  toggleModal,
  handleChange,
  subscribersList,
  loading,
  hasMore,
  loadMore,
  profileAttribute: profileAttributeKey,
  handleSendMessage,
  handleSort,
}) {
  const sortMenu = (
    <Menu onClick={(e) => handleSort(e.key)}>
      <Menu.Item key="-created_at">
        <p>Most Recent</p>
      </Menu.Item>
      <Menu.Item key="first_name">
        <p>Alphabetical (First Name)</p>
      </Menu.Item>
      <Menu.Item key="last_name">
        <p>Alphabetical (Last Name)</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      width={400}
      title={
        <div className="arc-d-flex arc-flex-vertical-align">
          <Button type="link" onClick={toggleModal} size="small" className="modal-back-button">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <h6 className="arc-H200">{count} subscribers</h6>
        </div>
      }
      footer={
        <div className="share-button-wrapper">
          <Share title="Share" url={`${window.location.origin}${window.location.pathname}`} disableNativeShare>
            <div className="share-card-icon-text arc-text-align-c">
              <FontAwesomeIcon icon={faShare} className="mr12" />
              <span className="arc-H100">INVITE A FRIEND</span>
            </div>
          </Share>
        </div>
      }
      closable={false}
      onCancel={toggleModal}
      visible
      wrapClassName="subscribers-list-modal">
      <div className="pr20 pl20 arc-d-flex arc-flex-vertical-align">
        <Input
          className="custom-input-search"
          prefix={<SearchOutlined />}
          bordered={false}
          placeholder="Search"
          onChange={handleChange}
          size="large"
          suffix={
            <Dropdown placement="bottomRight" overlay={sortMenu} className="guest-sort-menu">
              <FontAwesomeIcon icon={faSort} className="ml16" />
            </Dropdown>
          }
        />
      </div>

      <div className="subscribers-profiles-list pr20 pl20 mb16">
        <If condition={isEmpty(subscribersList) && !loading}>
          <h4 className="arc-text-align-c m24">No subscribers found.</h4>
        </If>
        {map(subscribersList, ({ profile }) => (
          <div key={profile.id} className="mt24 arc-d-flex arc-flex-justify-space-between arc-flex-vertical-align">
            <div className="arc-d-flex arc-flex-vertical-align subscriber-info">
              <Avatar src={profile?.present_picture_url} icon={<UserOutlined />} size={42} />
              <div className="ml12">
                <a href={profile?.profile_url} target="_blank" className="arc-H200" rel="noreferrer">
                  {profile.full_name}
                </a>
                <If condition={profile.profile_attribute}>
                  <div>
                    <Tooltip
                      placement="topLeft"
                      title={
                        profileAttributeKey === 'verifications'
                          ? profile?.profile_attribute?.map((val) => val.name).join(', ')
                          : null
                      }>
                      <span className="profile-attribute-label arc-color-gray7">
                        {getProfileAttributeLabel(profileAttributeKey, profile.profile_attribute)}
                      </span>
                    </Tooltip>
                  </div>
                </If>
              </div>
            </div>
            <Tooltip title="Send a message">
              <Button type="link" onClick={() => handleSendMessage(profile.slug)} className="send-message-btn">
                <FontAwesomeIcon icon={faMessage} />
              </Button>
            </Tooltip>
          </div>
        ))}
        <If condition={hasMore}>
          <div className="arc-d-flex arc-flex-justify-center mt20">
            <Button type="primary" loading={loading} onClick={loadMore}>
              Load more
            </Button>
          </div>
        </If>

        <If condition={loading}>
          <div className="guest-list-loader-wrapper">
            <LoadingOutlined id="guest-list-loader" spin />
          </div>
        </If>
      </div>
    </Modal>
  );
}

export default SubscribersModal;
