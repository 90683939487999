import React from 'react';
import { forEach, isEmpty, map } from 'lodash';
import { Typography } from 'antd';
import moment from 'moment';

import WaitingScreen from 'Src/common/components/waitingScreen';
import { asyncForEach } from 'Src/common/utilities/data_util';
import AssetManagerHelper from 'Src/common/utilities/asset_manager';

export const POST_ACTION = {
  DELETE: 'delete',
  REPORT_ABUSE: 'report_abuse',
  SEND_AS_EMAIL: 'send_as_email',
  EDIT_POST: 'edit_post',
  PIN_POST: 'pin_post',
};

export const ROUTES_NAME = {
  EXPLORE: 'explore',
  FEED: 'feed',
  POST_DETAILS: 'postDetails',
  SUBSCRIPTION: 'subscription',
  SPACE_DETAILS: 'spaceDetails',
  CHANNEL_FEED: 'channelFeed',
};

export const transformAttachment = (attachment) =>
  new Promise((resolve, reject) => {
    const assetManagerHelper = new AssetManagerHelper(attachment.id);
    assetManagerHelper.getVariants('size_large').then((value) => {
      resolve({ ...attachment, ...value });
    });
  });

function getMenuItems(input) {
  const menuItems = [];
  if (input?.permissions?.can_pin_post) {
    menuItems.push({ title: input.is_pinned ? 'Unpin' : 'Pin post', key: POST_ACTION.PIN_POST });
  }
  if (input?.permissions?.can_update_post) {
    menuItems.push({ title: 'Edit Post', key: POST_ACTION.EDIT_POST });
  }
  if (input?.permissions?.can_send_email) {
    menuItems.push({ title: 'Send as announcement', key: POST_ACTION.SEND_AS_EMAIL });
  }
  if (input?.permissions?.can_flag_post) {
    menuItems.push({ title: 'Report', key: POST_ACTION.REPORT_ABUSE });
  }
  if (input?.permissions?.can_delete_post) {
    menuItems.push({ title: 'Delete', key: POST_ACTION.DELETE });
  }
  return menuItems;
}

export function encodeTaggedUsersText(description, taggedUsers) {
  let content = description;

  forEach(taggedUsers, ({ original }) => {
    const username = `@${original.key}`;
    const userExcludingPipeRegex = new RegExp(`${username}(?!\\|)`); // To avoid multiple profiles with same name

    if (content.match(userExcludingPipeRegex)) {
      const encodededUser = original.old ? `^^^ ${original.replaceWith} ^^^` : `^^^ ${original.id}|userprofile ^^^`;
      content = content.replace(userExcludingPipeRegex, encodededUser);
    }
  });

  return content;
}

export function decodeTaggedUsersText(description, skipLinks = false) {
  // Tag format: ^^^ profile_id|userprofile|text|profile_url ^^^
  const splittedDescription = description.split('^^^');
  const taggedUsers = [];

  if (splittedDescription.length > 1) {
    const updatedDescription = map(splittedDescription, (item, index) => {
      let value = item;

      if (index % 2 !== 0) {
        const data = value.trim().split('|');

        if (data.length > 2) {
          value = skipLinks ? `${data[2]}` : `<a href="${data[3]}">${data[2]}</a>`;

          taggedUsers.push({
            original: {
              old: true,
              key: data[2]?.replace('@', ''),
              replaceWith: item?.trim(),
            },
          });
        }
      }

      return value;
    });

    return { data: updatedDescription.join(''), taggedUsers };
  }

  return { data: description, taggedUsers };
}

export const transformPost = (input, handleRedirect, onReaction, showEllipsis, handleImageClick) => {
  const description = decodeTaggedUsersText(input.description, true);

  return {
    ...input,
    header: input.heading,
    author: {
      avatarConfig: {
        size: 'default',
        url: input.created_by?.present_picture_url,
      },
      title: `${input.created_by?.first_name} ${input.created_by?.last_name}`,
      subtitle: input.created_by?.groups.map((val) => val.name).join(', '),
      color: window.primaryColor,
    },
    taggedUsers: description.taggedUsers,
    descriptionString: description.data,
    description: (
      <Typography.Paragraph ellipsis={showEllipsis ? { rows: 2 } : false}>
        <div dangerouslySetInnerHTML={{ __html: decodeTaggedUsersText(input.description).data }} />
      </Typography.Paragraph>
    ),
    reactions: [
      {
        type: 'comment',
        count: input.count_comments,
      },
    ],
    comments: [],
    menuItems: getMenuItems(input),
    metadata: [
      { text: moment(input.created_at).fromNow(), isClickable: false },
      { text: input.space.name, isClickable: true, onClick: () => handleRedirect(input, ROUTES_NAME.SPACE_DETAILS) },
      { text: input.channel.name, isClickable: true, onClick: () => handleRedirect(input, ROUTES_NAME.CHANNEL_FEED) },
    ],
    permissions: input.permissions,
    handlePostClick: () => handleRedirect(input, ROUTES_NAME.POST_DETAILS),
    shareUrl: input.post_url,
    profileUrl: input.created_by?.profile_url,
    hasAttachments: !isEmpty(input.attachments),
    reactionsSummary: input.permissions.can_create_reaction ? input.reactions_summary : [],
    onReaction,
    imageLoader: <WaitingScreen showIcon={false} />,
    onImageClick: handleImageClick,
    isEdited: input.is_edited,
    // isPinned: input.is_pinned,
    // Hiding pinned icon on post for now
    isPinned: false,
    directoryName: input.directory_name,
    actionButtonLabel: 'View details',
    actionButtonURL: `/d/${input.directory_slug}?i=${input.directory_item}`,
  };
};

export const formatPost = async (
  posts,
  callback,
  handlePostDetailsRedirect,
  onReaction,
  showEllipsis,
  handleImageClick,
) => {
  const newPosts = [];
  await asyncForEach(posts, async (post, index) => {
    const formattedPost = transformPost(post, handlePostDetailsRedirect, onReaction, showEllipsis, handleImageClick);
    newPosts.push(formattedPost);
    if (index === posts.length - 1) {
      callback(newPosts);
    }
  });
};

export const fetchPostAttachments = async (post, callback) => {
  const attachments = [];
  if (!isEmpty(post.attachments)) {
    await asyncForEach(post.attachments, async (attachment) => {
      const formattedAttachment = await transformAttachment(attachment);
      attachments.push(formattedAttachment);
    });
    callback(attachments);
  } else {
    callback(attachments);
  }
};
