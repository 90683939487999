import React, { useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import AlmasightsContext from 'Src/common/context/almasights';
import AuthHelper from 'Src/common/factories/auth';

import './styles.scss';

/**
 * Post actions component
 */
function PostActions({ actions, post }) {
  const { almasightsInstance, isLoading } = useContext(AlmasightsContext);

  useEffect(() => {
    if (!isEmpty(post) && !isLoading) {
      almasightsInstance.trackEvent({
        touchpointType: 'spaces-viewed-a-post',
        triggerType: 'click',
        authToken: window.csrfToken,
        jwtToken: AuthHelper.token(),
        object: {
          id: post.id,
          name: post.heading,
          type: 'spacepost',
        },
      });
    }
  }, [post, isLoading]);

  return (
    <div className="post-actions-wrapper">
      <div className="post-actions-container">
        {actions?.map((action) => (
          <div className="post-action" onClick={action.handler}>
            {action.icon} {action.text}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PostActions;
