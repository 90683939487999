/* eslint-disable max-statements */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { bool, shape, string } from 'prop-types';
import { isValidNumber, parseNumber, formatNumber } from 'libphonenumber-js';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Tooltip, Select } from 'antd';
import { getKeyValue } from 'Src/common/utilities/data_util';
import { PHONE_NUMBER_INPUT_PREFIX } from 'Src/adminFormsX/constants';
import countryCodes from '../../../../../data/en-US/countryCode.json';

import './style.scss';

const FormItem = Form.Item;
const { Option } = Select;

const ignoredCountriesCode = [
  'BS',
  'BY',
  'TD',
  'CN',
  'FJ',
  'HK',
  'IS',
  'MV',
  'MU',
  'NZ',
  'PG',
  'PE',
  'RO',
  'WS',
  'SC',
  'TW',
  'TJ',
  'TM',
  'UA',
  'UY',
  'UZ',
];

function PhoneNumberInput({ form, suffix, formItem, formRecord, extra, validateTrigger }) {
  const [phoneNumberInputFieldName, setPhoneNumberInputFieldName] = useState(
    `${PHONE_NUMBER_INPUT_PREFIX}${suffix ? formItem.key : ''}`,
  );
  const [selectedCountry, setSelectedCountry] = useState();
  const [validateStatus, setValidateStatus] = useState({});

  const MESSAGES = {
    SUCCESS: {
      validateStatus: 'success',
      help: '',
    },
    WARNING: {
      validateStatus: 'warning',
      help: 'It looks like the number is not valid.',
    },
    ERROR: {
      validateStatus: 'error',
      help: formItem.message || 'Please enter a valid phone number',
    },
    OVER_LIMIT: {
      message: 'error',
      help: formItem.message || 'Please enter a valid phone number',
    },
  };

  // Sets the country with given dialcode (+1, +91) in the dropdown
  // If there are multiple entries with same dialcode then filters by country code (US, IN)
  const setCountryPhoneCode = (dialCode, countryCode, setCountryPhoneCodeL) => {
    let countries = [];
    if (setCountryPhoneCodeL) {
      countries = filter(countryCodes, { code: dialCode });
    } else {
      countries = filter(countryCodes, { dial_code: dialCode });
    }
    if (countries.length > 1) {
      setSelectedCountry(filter(countries, { code: countryCode })[0]);
    } else {
      setSelectedCountry(countries[0]);
    }
  };

  // Gets the country code and the dial code from the ipgeolocation api
  // and sets the country in the dropdown
  const getCountryCode = () => {
    axios(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${window.ipgeolocationPublicKey}&fields=calling_code,country_code2`,
    )
      .then((response) => {
        if (response.status !== 200) throw Error(`Code ${response.status} - ${response.statusText}`);
        return response.data;
      })
      .then((body) => {
        if (body.calling_code && body.country_code2) setCountryPhoneCode(body.calling_code, body.country_code2);
        else throw Error('Calling code and country not found!!');
      })
      .catch(() => {
        // If it's an error set the default country code to US
        setCountryPhoneCode('+1', 'US');
      });
  };

  /*
  - This function is called if the api couldn't parse the number
  - It finds the auto_format false country and sets the country to the first found object
  - Returns false if no country is found
  */
  const getNonFormatCountry = (number) => {
    // Find the auto format false country
    const countryDialCode = number.split(' ')[0];
    const countryObj = filter(countryCodes, {
      dial_code: countryDialCode,
      auto_format: false,
    })[0];
    return countryObj || false;
  };

  /*
  - Removes dial code from the given number using selected country
  - replacePlus argument is used to control whether the number should be
    evaluated without plus or with plus
  */
  const removeDialCodeFromNumber = (number, replacePlus = true) => {
    // const { selectedCountry } = this.state;
    if (!isEmpty(selectedCountry)) {
      const dialCode = replacePlus ? selectedCountry.dial_code.replace('+', '') : selectedCountry.dial_code;
      if (number.substr(0, dialCode.length) === dialCode) {
        number = number.substr(dialCode.length);
      }
      return number;
    }
    return number;
  };

  /*
  - This function should only be called if there is area_code
    for the selected country
  - Removes area code from number (expects area code to be within brackets)
  */
  const removeAreaCodeFromNumber = (number) => {
    const areaCode = selectedCountry.area_code;
    // Trim the formatted number
    number = number.trim();
    if (number.substr(1, areaCode.length) === areaCode && number[0] === '(') {
      number = number.substr(areaCode.length + 3);
    }
    return number;
  };

  /*
  - If there are two arguments set the hidden field value to the formatted phone number with dial code
    and set the input field to the second argument.
  - If there is only one argument set the hidden field value to the formatted number and
    the input field value to the formatted number with area code removed if it exists.
  */
  const setFieldsValue = (formattedNumber, number) => {
    // Trim the formatted Number
    formattedNumber = formattedNumber.trim();
    let numberShown = formattedNumber;
    if (selectedCountry && selectedCountry.area_code) numberShown = removeAreaCodeFromNumber(formattedNumber);
    form.setFieldsValue({
      [phoneNumberInputFieldName]: number || numberShown,
      [formItem.key]: selectedCountry ? `${selectedCountry.dial_code} ${formattedNumber}` : formattedNumber,
    });
    return selectedCountry ? `${selectedCountry.dial_code} ${formattedNumber}` : formattedNumber;
  };

  /*
  - Returns the formatted number by taking the parsed number object
  - Formats the parsedNumber into national format
  - Also removes if there are any zeroes in the beginning
    (to handle cases for wrong formatting of Indian numbers 09192365215 -> 9192365215)
  */
  const getFormattedNumber = (parsedNumber) => {
    let nationalNumber = removeDialCodeFromNumber(formatNumber(parsedNumber, 'National'));
    nationalNumber = nationalNumber.replace(/^0+/, '');
    return nationalNumber;
  };

  const initialize = () => {
    const formItemKey = formItem.key;
    let phoneNumberInputFieldNameL = PHONE_NUMBER_INPUT_PREFIX;
    // Set the form field key if we got suffix as true
    if (suffix) phoneNumberInputFieldNameL += formItemKey;

    setPhoneNumberInputFieldName(phoneNumberInputFieldNameL);
    // Get the number if the component is showing existing data
    const number = getKeyValue(formRecord, formItemKey, null);
    if (number) {
      let parsedNumber = parseNumber(number);
      const nonFormatCountry = getNonFormatCountry(number);
      // If number is not parsed then try to check the non formatted number which means number is not parsed
      // by lib due to some reason else set default country code to the location of IP address
      if (!isEmpty(parsedNumber)) {
        setCountryPhoneCode(parsedNumber.country, false, true);
      } else if (nonFormatCountry) {
        setCountryPhoneCode(nonFormatCountry.code);
        parsedNumber = parseNumber(removeDialCodeFromNumber(number, false), selectedCountry?.code);
      } else {
        getCountryCode();
      }
      setFieldsValue(number, isEmpty(parsedNumber) ? number : getFormattedNumber(parsedNumber));
    } else {
      getCountryCode();
    }
  };

  /*
  - If a formRecord exists, sets the countrycode and formats the phone number in the record.
  - Otherwise, set the country code from the IP address
  */
  useEffect(() => {
    const countryCodeSelector = document.getElementById('country-select-box');
    countryCodeSelector.setAttribute('autocomplete', 'countrycode');
    initialize();
  }, []);

  useEffect(() => {
    const value = form.getFieldValue(phoneNumberInputFieldName);
    if (value && selectedCountry) {
      if (ignoredCountriesCode.indexOf(selectedCountry.code) === -1) {
        const parsedNumber = value ? parseNumber(value, selectedCountry.code) : {};
        if (isEmpty(parsedNumber) || (!isEmpty(parsedNumber) && !isValidNumber(parsedNumber))) {
          setValidateStatus(MESSAGES.WARNING);
        } else {
          const formattedNumber = getFormattedNumber(parsedNumber);
          setFieldsValue(formattedNumber);
          setValidateStatus(MESSAGES.SUCCESS);
        }
      } else {
        const formattedNumber = getFormattedNumber(value);
        setFieldsValue(formattedNumber);
        setValidateStatus(MESSAGES.SUCCESS);
      }
    }
  }, [selectedCountry]);

  // If the country is changed in the drop down set the country in the state
  // This will trigger the componentDidUpdate and all the formatting of number is done there
  const handleCountryChange = (countryCode) => {
    const countryObj = filter(countryCodes, { code: countryCode })[0];
    // this.setState({ selectedCountry: countryObj });
    setSelectedCountry(countryObj);
  };

  const displayValueCountryCode = (countryObj) => {
    if (countryObj) {
      const displayValue = `${countryObj.dial_code}${countryObj.area_code || ''} (${countryObj.name})`;
      return displayValue;
    }
    return '';
  };

  /*
  - Tries to validate the number by parsing it with the selected country code
  - If it is valid, it return the validated number
  - Otherwise, it validates the number without dial code and returns the validated number if it is valid
  - If all validations fail, it returns false
  */
  const validatePhoneAlgo = (value) => {
    // const { selectedCountry } = this.state;
    const cleanedNumber = value.replace(/^(00)/, ''); // This will replace stating zero from number
    let parsedNumber = parseNumber(cleanedNumber, selectedCountry.code);
    let isValidate = !isEmpty(parsedNumber) ? isValidNumber(parsedNumber) : false;
    // If not validated try to remove country code if user input the value with country code.
    if (!isValidate) {
      const withoutDialCodeNumber = removeDialCodeFromNumber(cleanedNumber);
      if (cleanedNumber !== withoutDialCodeNumber) {
        parsedNumber = parseNumber(withoutDialCodeNumber, selectedCountry.code);
        isValidate = !isEmpty(parsedNumber) ? isValidNumber(parsedNumber) : false;
      }
      return isValidate ? parsedNumber : false;
    }
    return !isEmpty(parsedNumber) ? parsedNumber : false;
  };

  /*
  * Antd validator for validating phone numbers based on the entered country code
  Cases handled:
  - For all the phone numbers starting with plus - country will be changed and the number is formatted
    without dial code and area code
  - Dial code and area code from the numbers are removed if
    the selected country is having the same dialcode and area code
  - All the remaining phone numbers will be re-formatted as soon as the focus from the component is removed

  Validation Messages:
  - Success -> The entered number could be formatted correctly. No message is shown
  - Warning -> The entered number is in invalid format. A warning message is shown
  - Error -> If the field is required and the user doen't enter any number. An error message is shown
  */
  // eslint-disable-next-line complexity
  const validateNumber = (rule, value, callback) => {
    if (value) {
      let number;
      number = setFieldsValue(value);
      // If value is starting with + change the country code if exists
      if (value[0] === '+') {
        const parsedNumber = parseNumber(value);
        let countries = [];
        if (parsedNumber.country) countries = filter(countryCodes, { code: parsedNumber.country });
        if (countries.length !== 1) {
          // Make default as US if no country is parsed
          countries = filter(countryCodes, { code: 'US' });
        }
        if (countries.length === 1) {
          // Set the national number and change the country
          number = setFieldsValue(getFormattedNumber(parsedNumber));
          // If it is the only country then set the country in the dropdown
          handleCountryChange(countries[0].code);
        }
        callback();
      } else if (ignoredCountriesCode.indexOf(selectedCountry.code) > -1) {
        callback();
        let cleanedValue = value.replace(/^(00)/, '');
        cleanedValue = removeDialCodeFromNumber(cleanedValue);
        const formattedNumber = getFormattedNumber({
          country: selectedCountry.code,
          phone: cleanedValue,
        });
        number = setFieldsValue(formattedNumber, value);
      } else {
        const parsedNumber = validatePhoneAlgo(value);
        if (parsedNumber) {
          callback();
          const formattedNumber = getFormattedNumber(parsedNumber);
          number = setFieldsValue(formattedNumber);
        } else if (formItem.maxLength && number?.length > formItem.maxLength) {
          callback(MESSAGES.OVER_LIMIT);
          setValidateStatus(MESSAGES.OVER_LIMIT);
          return;
        } else {
          callback();
          setValidateStatus(MESSAGES.WARNING);
          return;
        }
      }
      setValidateStatus(MESSAGES.SUCCESS);
    } else {
      // Empty value case: Show an error if the field is required
      callback();
      if (formItem.required) setValidateStatus(MESSAGES.ERROR);
    }
  };

  const renderFormItemLabel = (formItemL) => {
    const tooltipText = getKeyValue(formItemL, 'tooltip_text');
    if (tooltipText) {
      return (
        <span>
          <span style={{ marginRight: '0.5rem' }}>{formItemL.label}</span>
          <Tooltip title={tooltipText}>
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      );
    }
    return <span>{formItemL.label}</span>;
  };

  const countryCodeSelector = (
    <Select
      showSearch
      filterOption
      aria-expanded="true"
      id="country-select-box"
      optionFilterProp="label"
      optionLabelProp="label"
      dropdownStyle={{ width: 300 }}
      aria-label="Select country code"
      placeholder="Select country code"
      dropdownMatchSelectWidth={false}
      style={{ marginBottom: 10, textAlign: 'left' }}
      onChange={(value) => handleCountryChange(value)}
      disabled={getKeyValue(formItem.field, 'disabled', false)}
      value={selectedCountry && displayValueCountryCode(selectedCountry)}>
      {map(countryCodes, (code) => (
        <Option value={code.code} key={code.code} label={`${code.dial_code}${code.area_code || ''}`}>
          {code.name} ({code.dial_code}
          {code.area_code || ''})
        </Option>
      ))}
    </Select>
  );

  return (
    <div id="phone-number-input-box">
      <FormItem
        {...formItem.layout}
        extra={extra}
        label={renderFormItemLabel(formItem)}
        validateStatus={validateStatus.message || 'success'}
        help={<span aria-live="polite">{validateStatus.help || ''}</span>}>
        {countryCodeSelector}
        {form.getFieldDecorator(phoneNumberInputFieldName, {
          rules: [
            {
              validator: validateNumber,
            },
            {
              required: formItem.required || false,
            },
          ],
          validateTrigger: validateTrigger || 'onBlur',
        })(
          <Input
            aria-required={formItem.required}
            type="tel"
            autoComplete="tel"
            placeholder={getKeyValue(formItem, 'placeholder')}
            disabled={getKeyValue(formItem, 'disabled', false)}
          />,
        )}
      </FormItem>
      <FormItem label={null} style={{ margin: '0', display: 'none' }}>
        {form.getFieldDecorator(formItem.key, {})(<Input type="hidden" />)}
      </FormItem>
    </div>
  );
}

PhoneNumberInput.propTypes = {
  form: shape().isRequired,
  formItem: shape().isRequired,
  formRecord: shape().isRequired,
  extra: string.isRequired,
  suffix: bool.isRequired,
  validateTrigger: string.isRequired,
};

export default PhoneNumberInput;
