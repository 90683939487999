import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import SessionAuthProvider from 'Src/common/providers/sessionAuth';
import Root from './root';
import 'Src/common/arc.scss';

/**
 * Callback for redirect
 */
function onRedirectCallback(appState) {
  if (appState && appState.targetUrl) {
    window.location.href = appState.targetUrl;
  }
}

if (document.getElementById('root')) {
  setTimeout(() => {
    ReactDOM.render(
      <Auth0Provider
        domain={window.auth0TenantDomain}
        clientId={window.auth0ClientId}
        redirectUri={`${window.location.origin}/s/auth0/callback`}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
        useRefreshTokens>
        <SessionAuthProvider>
          <Root />
        </SessionAuthProvider>
      </Auth0Provider>,
      document.getElementById('root'),
    );
  }, 2000);
}
