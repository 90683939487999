import React from 'react';
import { isEmpty } from 'lodash';
import { shape, arrayOf, string, func, bool, number } from 'prop-types';
import { Col, Input, Row, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpaceCard } from 'arcl';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { Transition, animated } from 'react-spring';
import pluralize from 'pluralize';
import EmptyState from 'Src/spaces/components/emptyState';
import WaitingScreen from 'Src/common/components/waitingScreen';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { fadeInAnimation } from 'Src/spaces/animations';

import './style.scss';

/**
 * My Subscription component
 */
function Subscriptions({
  loading,
  cards,
  width,
  isSubscribed,
  searchText,
  setSearchText,
  digestFrequency,
  HandlePressEnter,
  updateContextAndNavigate,
  handleSubscribeChange,
}) {
  const AnimatedCol = animated(Col);

  return (
    <Choose>
      <When condition={loading}>
        <div className="loading-container">
          <WaitingScreen showIcon={false} />
        </div>
      </When>
      <Otherwise>
        <div className="subscription-wrapper">
          <animated.div className="subscription-wrapper-header" style={fadeInAnimation()}>
            <h5 className="arc-H500 arc-color-white">
              <I18nCustomFormatter id="subscriptions-label" />
            </h5>
            <div className="search-wrapper">
              <Input
                size="large"
                placeholder="Search"
                value={searchText}
                prefix={<FontAwesomeIcon icon={faSearch} />}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </animated.div>
          <animated.div style={fadeInAnimation()}>
            <If condition={!isEmpty(cards)}>
              <div className="digest-subscription-header">
                <div className="left-wrapper">
                  <p className="arc-H100 arc-color-black">
                    <Checkbox checked={isSubscribed} onChange={handleSubscribeChange} />
                    <span className="ml8">SUBSCRIBE TO DIGEST</span>
                  </p>
                  <p className="arc-support arc-color-B55 ml24 mr16 mt4 mb4">
                    We'll send you a {digestFrequency || 'weekly'} round up of the most popular posts from all the
                    spaces you are a part of.
                  </p>
                </div>
              </div>
            </If>
          </animated.div>
          <div className="subscription-content-wrapper mt16">
            <div className="content-header">
              <div className="left-wrapper">
                <span className="arc-H150 arc-color-B55">{pluralize('SPACES', cards.length, true)}</span>
              </div>
            </div>
            <div className="mt16">
              <Choose>
                <When condition={isEmpty(cards)}>
                  <EmptyState
                    heading={<I18nCustomFormatter id="subscriptions-page-empty-state-header" />}
                    redirectLink="/s/explore/"
                    buttonText="Explore Spaces"
                    illustrationPath="img/spaces/empty-subscription.svg"
                    description={<I18nCustomFormatter id="subscriptions-page-empty-state-description" />}
                  />
                </When>
                <Otherwise>
                  <Row gutter={[width > 768 ? 32 : 16, width > 768 ? 32 : 16]}>
                    <Transition
                      items={cards}
                      from={{ opacity: 0, y: 50 }}
                      enter={{ opacity: 1, y: 0 }}
                      leave={{ opacity: 0, y: 50 }}
                      keys={(item) => item.id}>
                      {(style, item) => (
                        <AnimatedCol style={{ ...style }} xs={24} sm={24} md={12} lg={12} xl={8}>
                          <div
                            role="link"
                            tabIndex={0}
                            className="space-card-wrapper arc-cursor-p"
                            onClick={() => updateContextAndNavigate(item)}
                            onKeyDown={(e) => HandlePressEnter(e, updateContextAndNavigate(item))}>
                            <SpaceCard {...item} />
                          </div>
                        </AnimatedCol>
                      )}
                    </Transition>
                  </Row>
                </Otherwise>
              </Choose>
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
}

Subscriptions.propTypes = {
  loading: bool.isRequired,
  cards: arrayOf(shape()).isRequired,
  width: number.isRequired,
  isSubscribed: bool.isRequired,
  searchText: string.isRequired,
  setSearchText: func.isRequired,
  HandlePressEnter: func.isRequired,
  updateContextAndNavigate: func.isRequired,
  handleSubscribeChange: func.isRequired,
  digestFrequency: string.isRequired,
};

export default Subscriptions;
