import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import { Post, Tooltip, Almatip } from 'arcl';
import { isEmpty } from 'lodash';
import { animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck, faLightbulbOn, faMapMarkerAlt, faLock } from '@fortawesome/pro-light-svg-icons';
import ProgressiveImage from 'react-progressive-image';
import CreatePost from 'Src/spaces/components/createPost';
import Channels from 'Src/spaces/components/spaceDetails/channels';
import Resources from 'Src/spaces/components/spaceDetails/Resources';
import Moderators from 'Src/spaces/components/spaceDetails/moderators';
import EmptyState from 'Src/spaces/components/emptyState';
import WaitingScreen from 'Src/common/components/waitingScreen';
import { MEMBER_STATUSES, LIST_OF_BANNER_IMAGES } from 'Src/spaces/constants';
import { fadeInAndSlideAnimation, Trail } from 'Src/spaces/animations';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import PinnedPost from 'Src/spaces/components/pinnedPosts';
import SubscriberCount from '../subscribersCount';

import './style.scss';

const { TabPane } = Tabs;

const TAB_CHANNELS = 'channels';
const TAB_POSTS = 'posts';
const TAB_LINKS = 'links';
const TAB_MODERATORS = 'moderators';

/**
 * Space detail component for small screen.
 */
function SpaceDetailsSmallScreen({
  space,
  posts,
  channels,
  handleCreatePost,
  handleMenuClick,
  handleChannelClick,
  handleChannelSubscribe,
  handleResourceAdd,
  handleResourceDelete,
  handleResourceSorting,
  channelId,
  NewPostTrail,
  newPost,
  loadingFeed,
  subscribe,
  updatingSubscription,
  unsubscribe,
  canCreatePost,
  applyForVerification,
  spaceLogo,
  setSelectedDirectory,
  loadingBannerImage,
  bannerImage,
  bannerImageThumbnail,
  pinnedPosts,
  handleChannelCreate,
  handleChannelDelete,
  handleResourceClick,
  selectedDirectory,
  subscribersOverview,
  toggleSpaceSubscriberModal,
}) {
  const [selectedTab, setSelectedTab] = useState(TAB_POSTS);

  function onChannelClick(channel) {
    setSelectedTab(TAB_POSTS);
    handleChannelClick(channel);
  }

  function onResourceClick(val) {
    setSelectedTab(TAB_POSTS);
    handleResourceClick(val);
  }

  return (
    <div className="small-screen-space-container">
      <animated.div className="space-wrapper" style={fadeInAndSlideAnimation()}>
        <div className="banner-image">
          <Choose>
            <When condition={loadingBannerImage}>
              <WaitingScreen showIcon={false} />
            </When>
            <Otherwise>
              <Choose>
                <When condition={bannerImage}>
                  <ProgressiveImage src={bannerImage} placeholder={bannerImageThumbnail || bannerImage}>
                    {(src) => <img src={src} />}
                  </ProgressiveImage>
                </When>
                <Otherwise>
                  <div className="default-image" style={{ backgroundImage: `url(${LIST_OF_BANNER_IMAGES[0]})` }} />
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </div>
        <div className="space-details-wrapper">
          <If condition={spaceLogo}>
            <img src={spaceLogo} className="mb16" height="65px" />
          </If>
          <p className="arc-H300 arc-color-black">{space.name}</p>
          <Tooltip
            title="This space is reserved for the following members"
            variant="simple"
            toolTipProps={{ placement: 'topLeft' }}>
            <p className="arc-support arc-color-B55 mt16">
              <FontAwesomeIcon icon={faShieldCheck} className="mr8 arc-color-primary spaces-vfx-icon" />
              <span className="arc-H150 arc-color-B85">{space?.groups?.map((group) => group.name).join(', ')}</span>
            </p>
          </Tooltip>
          <If condition={space.location}>
            <p className="arc-support arc-color-B55 mt16">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr8 arc-color-primary" />
              <span className="arc-H150 arc-color-B85">{space.location.name}</span>
            </p>
          </If>
          <div className="mt16">
            <p className="arc-p arc-color-B55" dangerouslySetInnerHTML={{ __html: space.description }} />
            <If condition={subscribersOverview.count > 0}>
              <SubscriberCount
                subscribersOverview={subscribersOverview}
                toggleSpaceSubscriberModal={toggleSpaceSubscriberModal} />
            </If>
            <If
              condition={
                [MEMBER_STATUSES.APPROVED, MEMBER_STATUSES.UNSUBSCRIBED, MEMBER_STATUSES.AUTO].includes(space.status)
                && space?.permissions?.can_subscribe_space
              }>
              <div className="arc-d-flex arc-flex-space-between-align arc-flex-vertical-align mt16">
                <Button
                  type="primary"
                  sie="large"
                  className="subscribe-btn"
                  loading={updatingSubscription}
                  onClick={subscribe}>
                  <If condition={!updatingSubscription}>
                    <I18nCustomFormatter id="subscribe-label" />
                  </If>
                </Button>
                <Almatip
                  heading="Tips"
                  trigger="hover"
                  description="Subscribing will add posts to your digest and feed"
                  illustrationPath={`${window.staticPath}img/almasights/almatip-bg.svg`}>
                  <FontAwesomeIcon
                    icon={faLightbulbOn}
                    className="arc-color-primary arc-cursor-p"
                    onClick={(e) => e.preventDefault()} />
                </Almatip>
              </div>
            </If>
            <If condition={space.status === MEMBER_STATUSES.SUBSCRIBED && space?.permissions?.can_unsubscribe_space}>
              <div className="arc-d-flex arc-flex-space-between-align arc-flex-vertical-align mt16">
                <Button
                  type="primary"
                  sie="large"
                  className="subscribe-btn"
                  loading={updatingSubscription}
                  onClick={unsubscribe}>
                  <If condition={!updatingSubscription}>
                    <I18nCustomFormatter id="unsubscribe-label" />
                  </If>
                </Button>
                <Almatip
                  heading="Tips"
                  trigger="hover"
                  description="Unsubscribing will remove posts from your digest and feed"
                  illustrationPath={`${window.staticPath}img/almasights/almatip-bg.svg`}>
                  <FontAwesomeIcon
                    icon={faLightbulbOn}
                    className="arc-color-primary arc-cursor-p"
                    onClick={(e) => e.preventDefault()} />
                </Almatip>
              </div>
            </If>
            <If condition={space.status === MEMBER_STATUSES.NOT_MEMBER}>
              <div className="arc-d-flex arc-flex-space-between-align arc-flex-vertical-align mt16">
                <Button
                  type="primary"
                  sie="large"
                  className="subscribe-btn"
                  icon={<FontAwesomeIcon icon={faLock} />}
                  onClick={applyForVerification}>
                  <p className="ml8">Subscribe</p>
                </Button>
                <Almatip
                  heading="Tips"
                  trigger="hover"
                  description="Verifications keeps the community safe. You may need additional verifications on your account to subscribe to updates and participate in this space."
                  illustrationPath="">
                  <FontAwesomeIcon
                    icon={faLightbulbOn}
                    className="arc-color-primary arc-cursor-p"
                    onClick={(e) => e.preventDefault()} />
                </Almatip>
              </div>
            </If>
          </div>
          <Tabs onChange={(key) => setSelectedTab(key)} activeKey={selectedTab} className="space-meta-tabs">
            <TabPane tab="Posts" key="posts" />
            <TabPane tab="Topics" key="channels" />
            <If condition={!isEmpty(space.resources) || space?.permissions?.can_create_resource}>
              <TabPane tab="Resources" disabled={!space.resources} key="links" />
            </If>
            <If condition={!isEmpty(space.moderators)}>
              <TabPane tab="Moderators" disabled={!space.moderators} key="moderators" />
            </If>
          </Tabs>
        </div>
      </animated.div>
      <If condition={selectedTab === TAB_CHANNELS}>
        <div className="space-card mt16 p16">
          <Channels
            space={space}
            channels={channels}
            handleChannelClick={onChannelClick}
            canEdit={space.permissions.can_create_channel}
            handleChannelSubscribe={handleChannelSubscribe}
            channelId={channelId}
            setSelectedDirectory={setSelectedDirectory}
            handleChannelCreate={handleChannelCreate}
            handleChannelDelete={handleChannelDelete} />
        </div>
      </If>
      <If condition={selectedTab === TAB_POSTS}>
        <Choose>
          <When condition={loadingFeed}>
            <WaitingScreen showIcon={false} />
          </When>
          <Otherwise>
            <Choose>
              <When condition={!isEmpty(selectedDirectory)}>
                <div id="iframe-preview-block" />
                <script>
                  {(function (i, u, h, s, c) {
                    if (!h || !i || !u || !s) {
                      return;
                    }
                    const doc = document;
                    const sb = doc.createElement('script');
                    sb.async = 1;
                    sb.src = `${h}js/almasdk/almasdk.js`;
                    // eslint-disable-next-line no-multi-assign
                    sb.onload = sb.onreadystatechange = () => {
                      window.almaSDK.init(i, u + i, h, s, c, selectedDirectory.name);
                    };
                    doc.body.appendChild(sb);
                  }(
                    selectedDirectory.value,
                    `${window.location.protocol}//${window.location.host}/d/`,
                    window.staticPath,
                    window.location.origin,
                    'directory',
                  ))}
                </script>
              </When>
              <Otherwise>
                <div className="feed-wrapper mt16">
                  <Trail reverse={false}>
                    <If condition={canCreatePost}>
                      <CreatePost handleCreatePost={handleCreatePost} spaceId={space.id} isSpaceUnpublished={!space?.is_published} />
                    </If>
                    <If condition={!isEmpty(pinnedPosts)}>
                      <div className="mb24">
                        <PinnedPost posts={pinnedPosts} />
                      </div>
                    </If>
                    <Choose>
                      <When condition={isEmpty(posts)}>
                        <EmptyState
                          heading={<I18nCustomFormatter id="space-details-page-empty-state-header" />}
                          redirectLink="/s/explore/"
                          // buttonText="Explore Spaces"
                          illustrationPath="img/spaces/empty-feed.svg"
                          description="" />
                      </When>
                      <Otherwise>
                        <NewPostTrail reverse={false}>
                          <If condition={newPost}>
                            <Post {...newPost} handleMenuClick={handleMenuClick} style={{ marginBottom: '20px' }} />
                          </If>
                        </NewPostTrail>
                        {posts.map((post) => (
                          <div className="post-item mb16">
                            <Post {...post} handleMenuClick={handleMenuClick} className="mb16" />
                          </div>
                        ))}
                      </Otherwise>
                    </Choose>
                  </Trail>
                </div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </If>
      <If condition={selectedTab === TAB_LINKS}>
        <div className="space-card mt16 p16">
          <Resources
            items={space.resources}
            canEdit={space.permissions.can_create_resource}
            addResource={handleResourceAdd}
            removeResource={handleResourceDelete}
            sortResource={handleResourceSorting}
            handleResourceClick={onResourceClick} />
        </div>
      </If>
      <If condition={selectedTab === TAB_MODERATORS}>
        <div className="space-card mt16 p16">
          <p className="arc-H100">MODERATORS</p>
          <Moderators data={space.moderators} />
        </div>
      </If>
    </div>
  );
}

export default SpaceDetailsSmallScreen;
