import React from 'react';
import { animated } from 'react-spring';
import { Button, Divider } from 'antd';
import { Tooltip, Almatip } from 'arcl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck, faLightbulbOn, faMapMarkerAlt, faLock } from '@fortawesome/pro-light-svg-icons';
import { isEmpty } from 'lodash';
import Channels from 'Src/spaces/components/spaceDetails/channels';
import WaitingScreen from 'Src/common/components/waitingScreen';
import ProgressiveImage from 'react-progressive-image';
import { LIST_OF_BANNER_IMAGES, MEMBER_STATUSES } from 'Src/spaces/constants';
import { fadeInAndSlideAnimation } from 'Src/spaces/animations';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import Resources from '../Resources';
import Moderators from '../moderators';
import SubscriberCount from '../subscribersCount';

import './style.scss';

/**
 * Space information card component
 */
function InfoCard({
  space,
  spaceId,
  channels,
  channelId,
  loadingBannerImage,
  bannerImage,
  bannerImageThumbnail,
  handleChannelCreate,
  handleChannelSorting,
  handleChannelClick,
  handleChannelSubscribe,
  handleChannelDelete,
  handleResourceAdd,
  handleResourceDelete,
  handleResourceSorting,
  subscribe,
  updatingSubscription,
  unsubscribe,
  spaceLogo,
  applyForVerification,
  handleResourceClick,
  setSelectedDirectory,
  subscribersOverview,
  toggleSpaceSubscriberModal,
}) {
  return (
    <div className="space-info-main-container">
      <animated.div className="space-wrapper" style={fadeInAndSlideAnimation()}>
        <div dataKey={spaceId}>
          <div className="banner-image">
            <Choose>
              <When condition={loadingBannerImage}>
                <WaitingScreen showIcon={false} />
              </When>
              <Otherwise>
                <Choose>
                  <When condition={bannerImage}>
                    <ProgressiveImage src={bannerImage} placeholder={bannerImageThumbnail || bannerImage}>
                      {(src) => <img src={src} />}
                    </ProgressiveImage>
                  </When>
                  <Otherwise>
                    <div className="default-image" style={{ backgroundImage: `url(${LIST_OF_BANNER_IMAGES[0]})` }} />
                  </Otherwise>
                </Choose>
              </Otherwise>
            </Choose>
          </div>
          <div className="space-details-wrapper">
            <If condition={spaceLogo}>
              <img src={spaceLogo} className="mb16" height="65px" />
            </If>
            <p className="arc-H300 arc-color-black">{space?.name}</p>
            <Tooltip
              title="This space is reserved for the following members"
              variant="simple"
              toolTipProps={{ placement: 'topLeft' }}>
              <p className="arc-support arc-color-B55 mt16">
                <FontAwesomeIcon
                  icon={space.status === MEMBER_STATUSES.NOT_MEMBER ? faLock : faShieldCheck}
                  className="mr8 arc-color-primary"
                  style={{ fontSize: 16 }} />
                <span className="arc-H150 arc-color-B85">{space?.groups?.map((group) => group.name).join(', ')}</span>
              </p>
            </Tooltip>
            <If condition={space.location}>
              <p className="arc-support arc-color-B55 mt16">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr8 arc-color-primary" style={{ fontSize: 16 }} />
                <span className="arc-H150 arc-color-B85">{space.location.name}</span>
              </p>
            </If>
            <p className="arc-p arc-color-B55 mt16" dangerouslySetInnerHTML={{ __html: space?.description }} />
            <If condition={subscribersOverview.count > 0}>
              <SubscriberCount
                subscribersOverview={subscribersOverview}
                toggleSpaceSubscriberModal={toggleSpaceSubscriberModal} />
            </If>
            <If
              condition={
                [MEMBER_STATUSES.APPROVED, MEMBER_STATUSES.UNSUBSCRIBED, MEMBER_STATUSES.AUTO].includes(space.status)
                && space?.permissions?.can_subscribe_space
              }>
              <div className="arc-d-flex arc-flex-space-between-align arc-flex-vertical-align mt16">
                <Button
                  type="primary"
                  sie="large"
                  className="subscribe-btn"
                  loading={updatingSubscription}
                  onClick={subscribe}>
                  <If condition={!updatingSubscription}>
                    <div className="ml8">
                      <I18nCustomFormatter id="subscribe-label" />
                    </div>
                  </If>
                </Button>
                <Almatip
                  heading="Tips"
                  trigger="hover"
                  description="Subscribing will add posts to your digest and feed"
                  illustrationPath={`${window.staticPath}img/almasights/almatip-bg.svg`}>
                  <FontAwesomeIcon
                    icon={faLightbulbOn}
                    className="arc-color-primary arc-cursor-p"
                    onClick={(e) => e.preventDefault()} />
                </Almatip>
              </div>
            </If>
            <If condition={space.status === MEMBER_STATUSES.SUBSCRIBED && space?.permissions?.can_unsubscribe_space}>
              <div className="arc-d-flex arc-flex-space-between-align arc-flex-vertical-align mt16">
                <Button
                  type="primary"
                  sie="large"
                  className="subscribe-btn"
                  loading={updatingSubscription}
                  onClick={unsubscribe}>
                  <If condition={!updatingSubscription}>
                    <div className="ml8">
                      <I18nCustomFormatter id="unsubscribe-label" />
                    </div>
                  </If>
                </Button>
                <Almatip
                  heading="Tips"
                  trigger="hover"
                  description="Unsubscribing will remove posts from your digest and feed"
                  illustrationPath={`${window.staticPath}img/almasights/almatip-bg.svg`}>
                  <FontAwesomeIcon
                    icon={faLightbulbOn}
                    className="arc-color-primary arc-cursor-p"
                    onClick={(e) => e.preventDefault()} />
                </Almatip>
              </div>
            </If>
            <If condition={space.status === MEMBER_STATUSES.NOT_MEMBER}>
              <div className="arc-d-flex arc-flex-space-between-align arc-flex-vertical-align mt16">
                <Button
                  type="primary"
                  sie="large"
                  className="subscribe-btn"
                  icon={<FontAwesomeIcon icon={faLock} />}
                  onClick={applyForVerification}>
                  <p className="ml8">Subscribe</p>
                </Button>
              </div>
            </If>
          </div>
        </div>
      </animated.div>

      <div className="topics-resources-container">
        <animated.div style={fadeInAndSlideAnimation()}>
          <If condition={space?.permissions?.can_read_channels}>
            <Channels
              space={space}
              channels={channels}
              spaceId={spaceId}
              handleChannelCreate={handleChannelCreate}
              handleChannelSorting={handleChannelSorting}
              canEdit={space.permissions.can_create_channel}
              handleChannelClick={handleChannelClick}
              handleChannelSubscribe={handleChannelSubscribe}
              handleChannelDelete={handleChannelDelete}
              channelId={channelId}
              handleResourceClick={handleResourceClick}
              setSelectedDirectory={setSelectedDirectory} />
          </If>
          <If condition={space?.permissions?.can_read_resources}>
            <If condition={!isEmpty(space.resources) || space?.permissions?.can_create_resource}>
              <Divider className="custom-divider" />
              <Resources
                items={space.resources}
                canEdit={space.permissions.can_create_resource}
                canListDirectories={space.permissions.can_list_directories}
                addResource={handleResourceAdd}
                removeResource={handleResourceDelete}
                sortResource={handleResourceSorting}
                handleResourceClick={handleResourceClick} />
            </If>
          </If>
          <Divider className="moderators-divider" />
          <If condition={!isEmpty(space.moderators)}>
            <p className="arc-H100">MODERATORS</p>
            <Moderators data={space.moderators} />
          </If>
        </animated.div>
      </div>
    </div>
  );
}

export default InfoCard;
