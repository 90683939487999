import React from 'react';
import { Button } from 'antd';
import { slice } from 'lodash';
import { func, instanceOf } from 'prop-types';
import { StackedAvatar } from 'arcl';

import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import './style.scss';

function SubscriberCount({ subscribersOverview, toggleSpaceSubscriberModal }) {
  const { count: subscribersCount, results: subscribers } = subscribersOverview;
  const topThreeSubscribers = subscribers.length > 3 ? slice(subscribers, 0, 3) : subscribers;

  const getFirstProfileFullName = () => {
    const { first_name: firstName, full_name: fullName } = subscribers[0]?.profile || {};

    if (fullName?.length > 20) {
      if (firstName?.length > 20) {
        return firstName?.slice(0, 20);
      }
      return firstName;
    }

    return fullName;
  };

  return (
    <div className="space-subscribers-list-wrapper arc-d-flex arc-flex-vertical-align">
      <div className="space-subscribers-avatars mr8">
        <StackedAvatar
          overlap={8}
          data={topThreeSubscribers.map((subscriber) => ({ url: subscriber?.profile?.present_picture_url }))}
          avatarConfig={{ size: 28 }}
          className="info-icon" />
      </div>
      <span className="arc-p space-subscribers-list">
        <Button
          type="link"
          size="small"
          className="subscriber-list-trigger arc-color-primary arc-cursor-p"
          onClick={toggleSpaceSubscriberModal}>
          <Choose>
            <When condition={subscribersCount === 1}>{`${getFirstProfileFullName()} `}</When>
            <Otherwise>
              <I18nCustomFormatter
                id="space-subscriber-more-than-one-prefix"
                values={{
                  full_name: getFirstProfileFullName(),
                  count: subscribersCount - 1,
                }} />
            </Otherwise>
          </Choose>
        </Button>
        <I18nCustomFormatter
          id="space-subscriber-more-than-one-suffix"
          values={{
            count: subscribersCount - 1,
          }} />
      </span>
    </div>
  );
}

SubscriberCount.propTypes = {
  subscribersOverview: instanceOf(Object).isRequired,
  toggleSpaceSubscriberModal: func.isRequired,
};

export default SubscriberCount;
