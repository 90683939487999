import React from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowLeft, faEdit, faPlus } from '@fortawesome/pro-light-svg-icons';
import ThemeXSortableList from 'Src/common/components/themeXSortableList';

import './style.scss';

const DragHandle = SortableHandle(() => (
  <span className="arc-d-inline-block arc-vertical-m">
    <FontAwesomeIcon icon={faBars} className="arc-reorder-icon draggable-item" />
  </span>
));

const SortableItem = SortableElement(({ value, onEdit, hasEdit, displayKey }) => (
  <li className="sortable-list-item arc-d-inline-block arc-vertical-m arc-page-bg arc-card-border-radius pt16 pr8 pb16 pl16 mt16 ml0 mr0">
    <DragHandle />
    <span className="arc-p arc-d-inline-block arc-vertical-m arc-text-ellipsis sortable-list-item-title ml16">
      {value[displayKey]}
    </span>
    <If condition={hasEdit}>
      <span>
        <FontAwesomeIcon
          icon={faEdit}
          className="mr16  arc-float-right arc-color-primary mt6 arc-cursor-p"
          onClick={() => {
            onEdit(value);
          }}
        />
      </span>
    </If>
  </li>
));

function ReorderableModal({
  title,
  items,
  closeModal,
  children,
  handleSort,
  hasAdd = false,
  hasEdit = true,
  onAdd,
  onEdit,
  pressDelay = 200,
  footer = null,
  displayKey = 'name',
}) {
  return (
    <Modal
      onCancel={closeModal}
      closable={false}
      footer={footer}
      visible
      wrapClassName="reorderable-modal"
      title={
        <div className="arc-d-flex arc-flex-justify-space-between arc-flex-v-align-middle">
          <div>
            <FontAwesomeIcon icon={faArrowLeft} className="arc-color-primary mr16 arc-cursor-p" onClick={closeModal} />
            <span className="arc-H200">{title}</span>
          </div>
          <If condition={hasAdd}>
            <FontAwesomeIcon icon={faPlus} className="arc-color-primary arc-cursor-p" onClick={onAdd} />
          </If>
        </div>
      }>
      <div className="reorderable-wrapper">
        <div className="sortable-container">
          <ThemeXSortableList
            items={items}
            sortableItem={SortableItem}
            onSortEnd={handleSort}
            hasEdit={hasEdit}
            onEdit={onEdit}
            displayKey={displayKey}
            pressDelay={pressDelay}
            useDragHandle
          />
        </div>
        <If condition={children}>
          <div className="extra-container">{children}</div>
        </If>
      </div>
    </Modal>
  );
}

export default ReorderableModal;
