/* eslint-disable max-statements */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, message } from 'antd';
import { findIndex, isEmpty, find, cloneDeep } from 'lodash';
import { ImagePreview } from 'arcl';
import FeedComponent from 'Src/spaces/components/feed';
import { handleRedirect, onReaction } from 'Src/spaces/utils';
import HybridAuthContext from 'Src/spaces/context/auth';
import VerificationContext from 'Src/common/context/verification';
import { POST_ACTION } from 'Src/spaces/constants';
import UpdatePostModal from 'Src/spaces/components/createPost/modal';
import {
  createPost,
  deletePost,
  fetchUserFeed,
  updateOrDeleteReaction,
  reportAbusePost,
  sendPostAsEmail,
  editPost,
  pinPost,
  fetchSpaces,
} from 'Src/spaces/actions';
import AlmasightsProvider from 'Src/common/providers/almasights';
import { formatPost, fetchPostAttachments, transformPost } from 'Src/common/utilities/spaces_utils';

const { confirm } = Modal;
let page = 1;

/**
 * Feed container
 */
function Feed() {
  const { isUserAuthenticated } = useContext(HybridAuthContext);
  const verificationData = useContext(VerificationContext);
  const [posts, setPosts] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [newPost, setNewPost] = useState(null);
  const [postAttachments, setPostAttachments] = useState({});
  const [activeImageIndex, setActiveImageIndex] = useState();
  const [previewImageList, setPreviewImageList] = useState([]);
  const [postToUpdate, setPostToUpdate] = useState({});
  const [loadingMorePosts, setLoadingMorePosts] = useState(false);

  const postRef = useRef();

  postRef.current = posts;

  /**
   * Handles reaction
   */
  function onReactionL(postId, summary) {
    updateOrDeleteReaction(summary.self ? 'delete' : 'post', { kind: summary.kind }, postId);
    setPosts(onReaction(postRef.current, postId, summary).slice());
  }

  /**
   * Handles image click
   */
  function handleImageClick(index, images, postId) {
    const arr = [];
    const post = find(postRef.current, (o) => o.id === postId);
    images.forEach((image) => {
      arr.push({ url: image.url, shareUrl: post.post_url, shareText: post.heading });
    });
    setActiveImageIndex(index);
    setPreviewImageList(arr);
  }

  useEffect(() => {
    if (!isEmpty(posts)) {
      const postContainer = document.getElementsByClassName('feed-wrapper');

      if (postContainer) {
        postContainer[0]?.addEventListener('click', (e) => {
          if (e.target.tagName === 'A') {
            e.stopPropagation();
          }
        });
      }
    }
  }, [posts]);

  useEffect(() => {
    page = 1;
    setLoading(true);
    fetchUserFeed(page)
      .then((res) => {
        if (res.data.count) {
          formatPost(
            res.data.results,
            (newPosts) => {
              setPosts(newPosts);
              setHasMore(res.data.next !== null);
              setLoading(false);
              newPosts.forEach((newPost) => {
                fetchPostAttachments(newPost, (attachments) => {
                  postAttachments[newPost.id] = attachments;
                  setPostAttachments({ ...postAttachments });
                });
              });
            },
            (input, routeName) => handleRedirect(input, routeName, verificationData),
            (postId, summary) => onReactionL(postId, summary),
            true,
            handleImageClick,
          );
        } else {
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));

    fetchSpaces({
      fields: 'id,name,permissions',
      extraQueryParams: '',
    }).then(async (res) => {
      const allowedSpaces = [];
      res.data.results.forEach((space) => {
        if (space.permissions.can_create_post_in_any_channel) {
          allowedSpaces.push(space);
        }
      });
      setSpaces(allowedSpaces);
    });
  }, [isUserAuthenticated]);

  useEffect(() => {
    posts.forEach((post) => {
      if (postAttachments[post.id]) {
        post.attachments = postAttachments[post.id];
      }
    });
    setPosts([...posts]);
  }, [postAttachments]);

  /**
   * Show delete confirm modal
   */
  function showDeletePostModal(id) {
    confirm({
      title: 'Delete Post',
      content: 'Deleted posts cannot be retrieved. Would you like to proceed?',
      okText: 'Delete Post',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deletePost(id);
        // if post was newly created and user haven't refreshed the page
        if (newPost && newPost.id === id) {
          setNewPost(null);
        } else {
          const filteredPost = posts.filter((post) => post.id !== id);
          setPosts(filteredPost);
        }
      },
    });
  }

  function showEmailSendConfirmationModal(postId) {
    confirm({
      title: 'Are you sure to send this post as announcement',
      content: 'As a moderator, you can send this post out immediately to all subscribers',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const post = find(postRef.current, (o) => o.id === postId);
        sendPostAsEmail(post?.space?.id, postId).then(() => {
          message.success('Post has been sent as email');
        });
      },
    });
  }

  /**
   * Handles click on menu item
   */
  function handleMenuClick(postId, key) {
    if (key === POST_ACTION.DELETE) {
      showDeletePostModal(postId);
    } else if (key === POST_ACTION.REPORT_ABUSE) {
      reportAbusePost(postId).then((res) => {
        message.success('This post has been reported to the moderators of this space');
        const index = findIndex(posts, (val) => val.id === postId);
        if (index > -1) {
          // Remove report_abuse menu item from post
          const clonedPosts = cloneDeep(posts);
          const post = clonedPosts[index];
          const postItems = post.menuItems.filter((item) => item.key !== POST_ACTION.REPORT_ABUSE);
          post.permissions.can_flag_post = false;
          post.menuItems = postItems;
          clonedPosts[index] = post;
          setPosts(clonedPosts);
        }
      });
    } else if (key === POST_ACTION.SEND_AS_EMAIL) {
      showEmailSendConfirmationModal(postId);
    } else if (key === POST_ACTION.EDIT_POST) {
      const post = find(posts, (o) => o.id === postId);
      setPostToUpdate(post);
    } else if (key === POST_ACTION.PIN_POST) {
      handlePinPost(postId);
    }
  }

  /**
   * Creates a new post
   */
  function handleCreatePost(values, sendAsEmail) {
    createPost(values).then((res) => {
      const formattedPost = transformPost(
        res.data,
        (input, routeName) => handleRedirect(input, routeName, verificationData),
        (postId, summary) => onReactionL(postId, summary),
        true,
        handleImageClick,
      );
      setPosts([formattedPost, ...posts]);
      if (sendAsEmail) {
        sendPostAsEmail(formattedPost?.space?.id, formattedPost.id).then(() => {
          message.success('Post has been sent as email');
        });
      }
    });
  }

  /**
   * Loads more posts
   */
  function handleViewMore() {
    page += 1;
    setLoadingMorePosts(true);
    fetchUserFeed(page).then((res) => {
      formatPost(
        res.data.results,
        (newPosts) => {
          // Creating new post will shift all posts by one, Remove last post to prevent duplication
          if (newPost && posts.length <= 10) {
            const previousPosts = [...posts];
            previousPosts.pop();
            setPosts([...previousPosts, ...newPosts]);
          } else {
            setPosts([...posts, ...newPosts]);
          }
          setHasMore(res.data.next !== null);
          newPosts.forEach((newPost) => {
            fetchPostAttachments(newPost, (attachments) => {
              postAttachments[newPost.id] = attachments;
              setPostAttachments({ ...postAttachments });
            });
          });
        },
        (input, routeName) => handleRedirect(input, routeName, verificationData),
        (postId, summary) => onReactionL(postId, summary),
        true,
        handleImageClick,
      );
      setLoadingMorePosts(false);
    });
    setLoading(false);
  }

  /**
   * Handles preview modal close
   */
  function handleImagePreviewClose() {
    setPreviewImageList([]);
  }

  /**
   * Closes update post modal
   */
  function handleUpdatePostModalClose() {
    setPostToUpdate({});
  }

  function handlePostUpdate(postData) {
    const updatedPosts = [];
    const formattedPost = transformPost(
      postData,
      (input, routeName) => handleRedirect(input, routeName, verificationData),
      (postId, summary) => onReactionL(postId, summary),
      true,
      handleImageClick,
    );
    posts.forEach((post) => {
      if (post.id === formattedPost.id) {
        updatedPosts.push(formattedPost);
      } else {
        updatedPosts.push(post);
      }
    });
    setPosts(updatedPosts);
  }

  function handlePostUpdateSubmit(value, sendAsEmail) {
    value.is_edited = true;
    editPost(postToUpdate.id, value).then((res) => {
      handlePostUpdate(res.data);
      if (sendAsEmail) {
        sendPostAsEmail(res.data?.space?.id, res.data.id).then(() => {
          message.success('Post has been sent as email');
        });
      }
    });
    setPostToUpdate({});
  }

  function handlePinPost(postId) {
    const post = find(postRef.current, (o) => o.id === postId);
    pinPost(postId, { is_pinned: !post.isPinned }).then((res) => {
      handlePostUpdate(res.data);
    });
  }

  return (
    <>
      <AlmasightsProvider
        parentObject={{
          type: 'site',
          id: window.siteId,
          name: 'Space Feed',
        }}>
        <div>
          <FeedComponent
            posts={posts}
            spaces={spaces}
            hasMore={hasMore}
            loading={loading}
            newPost={newPost}
            handleViewMore={handleViewMore}
            isUserAuthenticated={isUserAuthenticated}
            handleCreatePost={handleCreatePost}
            handleMenuClick={handleMenuClick}
            loadingMorePosts={loadingMorePosts}
          />
        </div>
      </AlmasightsProvider>

      <If condition={!isEmpty(previewImageList)}>
        <ImagePreview images={previewImageList} activeIndex={activeImageIndex} onClose={handleImagePreviewClose} />
      </If>
      <If condition={!isEmpty(postToUpdate)}>
        <UpdatePostModal
          postData={postToUpdate}
          spaceId={postToUpdate.space.id}
          togglePostModal={handleUpdatePostModalClose}
          handleSubmit={handlePostUpdateSubmit}
        />
      </If>
    </>
  );
}

export default Feed;
