import React, { useEffect, useRef, useState, useContext } from 'react';
import { message } from 'antd';
import { isEmpty } from 'lodash';

import { fetchSubscribersList, sendMessage } from 'Src/spaces/actions';
import useDebounce from 'Src/common/hooks/useDebounce';
import AlmasightsContext from 'Src/common/context/almasights';
import AuthHelper from 'Src/common/factories/auth';
import SubscribersModal from '../../components/spaceDetails/subscribersModal';

function SpaceSubscribersModal({ subscribersOverview, toggleModal, spaceId, profileAttribute }) {
  const [subscribersList, setSubscribersList] = useState(subscribersOverview?.results || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(!!subscribersOverview.next);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(subscribersOverview?.count || null);
  const [ordering, setOrdering] = useState('first_name');
  const debouncedQuery = useDebounce(search, 500);
  const isMounted = useRef(false);
  const { almasightsInstance } = useContext(AlmasightsContext);

  const getSubscribers = () => {
    fetchSubscribersList(spaceId, `page=1&ordering=${ordering}&search=${debouncedQuery}`)
      .then((res) => {
        setSubscribersList(res.data.results);
        setHasMore(!!res.data.next);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if (isEmpty(subscribersList)) {
        setLoading(true);
        getSubscribers();
      }

      if (almasightsInstance?.trackEvent) {
        almasightsInstance.trackEvent({
          touchpointType: 'spaces-opened-subscribers-list',
          triggerType: 'view',
          authToken: window.csrfToken,
          jwtToken: AuthHelper.token(),
          object: null,
        });
      }
    } else {
      setLoading(true);
      setCurrentPage(1);
      getSubscribers();
    }
  }, [debouncedQuery, ordering]);

  const loadMore = () => {
    setLoading(true);

    fetchSubscribersList(spaceId, `page=${currentPage + 1}&ordering=${ordering}&search=${debouncedQuery}`)
      .then((res) => {
        const newResults = [...subscribersList, ...res.data.results];
        setSubscribersList(newResults);
        setHasMore(!!res.data.next);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    setCurrentPage((prev) => prev + 1);
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSendMessage = (slug) => {
    const formData = new FormData();
    formData.set('recipient', slug);

    sendMessage(formData)
      .then((res) => {
        if (almasightsInstance?.trackEvent) {
          almasightsInstance.trackEvent({
            touchpointType: 'spaces-clicked-on-send-message-cta',
            triggerType: 'click',
            authToken: window.csrfToken,
            jwtToken: AuthHelper.token(),
            object: null,
          });
        }

        window.open(res.request.responseURL);
      })
      .catch(() => {
        message.error('Failed to send message');
      });
  };

  const handleSort = (key) => {
    setOrdering(key);
  };

  return (
    <SubscribersModal
      count={count}
      toggleModal={toggleModal}
      handleChange={handleChange}
      subscribersList={subscribersList}
      loading={loading}
      hasMore={hasMore}
      loadMore={loadMore}
      profileAttribute={profileAttribute}
      handleSendMessage={handleSendMessage}
      handleSort={handleSort}
    />
  );
}

export default SpaceSubscribersModal;
