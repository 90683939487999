/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-statements */
import React, { useState, useRef, useEffect } from 'react';
import { Button, Divider, Input, Select, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShield, faClock, faShieldCheck, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { find, findIndex, isEmpty } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { validateFullName } from 'Src/common/utilities/data_util';
import axiosInstance from 'Src/common/utilities/axios_util';
import { convertFormLayoutToComponentFields, getFormValue, getNameFieldValue } from 'Src/common/utilities/form_utils';
import CustomFormItem from 'Src/common/components/customFormItem';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { lookAndAddAutoFillOptions } from 'Src/common/components/customFormItem/helper';
import Header from './header';
import '@ant-design/compatible/assets/index.css';
import './style.scss';
import { PHONE_NUMBER_INPUT_PREFIX } from 'Src/adminFormsX/constants';
import FroalaEditorView from 'Src/common/components/froalaEditor/froalaEditorView';
import { INPUT_TYPES } from 'Src/common/constants';

/**
 * Component to apply for verification
 * @param {*} param0
 */
function SelectAndApplyForVerificationGroup({
  groups,
  user,
  form,
  next,
  setGroup,
  back,
  isAuthenticated,
  collegeEmail,
  closeModal,
  collegeName,
}) {
  const MEMBER_STATUS_UNKNOWN = 'unknown';
  const MEMBER_STATUS_APPROVED = 'member';
  const MEMBER_STATUS_PENDING = 'pending';
  const EMAIL_CHANGE_IDENTIFER = '{session.email}';
  const MEMBER_STATUSES = [MEMBER_STATUS_UNKNOWN, MEMBER_STATUS_APPROVED, MEMBER_STATUS_PENDING];
  const [selectedVerificationGroup, setSelectedVerificationGroup] = useState({});
  const [memberStatus, setMemberStatus] = useState(MEMBER_STATUS_UNKNOWN);
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [fields, setFields] = useState([]);
  const [fieldsXHash, setFieldsXHash] = useState({});
  const [showHardCodedFormFields, setShowHardCodedFormFields] = useState(null)
  const [sectionsData, setSectionsData] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [formId, setFormId] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const { getFieldDecorator } = form;

  /**
   * Get joining form details
   * @param {*} groupId
   */
  async function getJoiningForm(groupId) {
    // This is needed otherwise claims come as null
    let jwtToken;
    let csrfToken;
    if (isAuthenticated) {
      await getAccessTokenSilently();
      const tokenClaims = await getIdTokenClaims();
      jwtToken = tokenClaims.__raw;
    } else {
      csrfToken = window.csrfToken;
    }

    const response = await axiosInstance({
      method: 'get',
      url: `/groups/${groupId}/`,
      baseURL: '/api/v2/',
      jwtToken,
      csrfToken,
    });
    return response.data;
  }

  /**
   * on change of the verification group, get a associated joining form and set fields
   * @param {*} id
   */
  function changeVerificationGroup(id) {
    const index = findIndex(groups, (val) => val.id === id);
    if (index > -1) {
      const resultHash = {};
      setSelectedVerificationGroup({ ...groups[index] });
      getJoiningForm(id).then(async (group) => {
        let result = convertFormLayoutToComponentFields(group.joining_form.fields)
        // find email field and make it disabled 
        for (let item of result) {
          // creating formx field hashmap with id as key
          resultHash[item.id] = item;
          if (item.type === INPUT_TYPES.EMAIL) {
            Object.assign(item, { disabled: true })
          }
        }
        await lookAndAddAutoFillOptions(result);
        setFields(result);
        setFieldsXHash(resultHash);
        let showHardCodedFormFields = true;
        for (const field of group.joining_form.fields) {
          const { field_type } = field
          if (field_type === 3) {
            showHardCodedFormFields = false
          }
        }
        setShowHardCodedFormFields(showHardCodedFormFields)
        setSectionsData(group.joining_form.sections.filter(({ form_fields }) => form_fields && form_fields.length).map((data, key) => {
          return { ...data, key, description: data["description"].replaceAll(EMAIL_CHANGE_IDENTIFER, user.email || "") };
        }))
        setFormId(group.joining_form.id);
      });
    }
  }

  /**
   * Verify the joining form values
   * @param {*} values
   */
  async function verify(values) {
    let jwtToken;
    let csrfToken;
    if (isAuthenticated) {
      await getAccessTokenSilently();
      const tokenClaims = await getIdTokenClaims();
      jwtToken = tokenClaims.__raw;
    } else {
      csrfToken = window.csrfToken;
    }
    return axiosInstance({
      method: 'post',
      url: `/groups/${selectedVerificationGroup.id}/join/`,
      baseURL: '/api/v2/',
      data: values,
      jwtToken,
      csrfToken,
    });
  }

  /**
   * Apply for verification
   */
  function applyForVerification() {
    let validateFun = sectionsData.length > 0 ? form.validateFields : form.validateFieldsAndScroll;
    validateFun((err, values) => {
      if (!err) {
        setVerifying(true);
        // eslint-disable-next-line no-param-reassign
        let payload = {};

        if (values.full_name) {
          const spliitedFullName = values.full_name.split(' ');
          delete values.full_name;
          payload = {
            first_name: spliitedFullName[0],
            last_name: spliitedFullName[1],
          };
        }

        const field_entries = fields
          .filter((field) => typeof values[field.id] !== 'undefined')
          .map((field) => {
            if (field.type === INPUT_TYPES.NAME) {
              let optionValue = ""
              if (field.options[0])
                optionValue = field.options[0]['value']
              return {
                value: getNameFieldValue(values[field.id], optionValue || ""),
                field_id: field.id
              }
            }
            return {
              value: getFormValue(values[field.id]),
              field_id: field.id
            }
          });

        const valuesToSubmit = {
          joining_form_entry: {
            form_id: formId,
            field_entries,
            ...payload,
          },
        };

        setGroup(selectedVerificationGroup.name);
        verify(valuesToSubmit)
          .then(() => {
            setVerifying(false);
            setMemberStatus(MEMBER_STATUS_PENDING);
          })
          .catch((err) => {
            // if error response has status 400, it means the profile already is member of the group
            if (err.response.status === 400) {
              setMemberStatus(MEMBER_STATUS_APPROVED);
            }
            setVerifying(false);
          });
      } else {
        moveToErrorField(err);
      }
    });
  }

  useEffect(() => {
    if (showHardCodedFormFields !== null && showHardCodedFormFields === false) {
      let desiredField = find(fields, ({ type }) => type === INPUT_TYPES.EMAIL)
      if (desiredField)
        form.setFieldsValue({ [desiredField.key]: user.email })
    }
  }, [showHardCodedFormFields, fields])

  const fieldsRef = useRef([]);
  const myRef = useRef(null);
  fieldsRef.current = {};

  const addToRefs = (el, key) => {
    if (el) {
      Object.assign(fieldsRef.current, { [key]: el });
    }
  };

  useEffect(() => {
    if (myRef.current) {
      const reqSection = sectionsData[currentSection];
      const firstField = reqSection.form_fields[0];
      const headerElm = document.querySelector('.verification-header')
      if (headerElm) {
        headerElm.scrollIntoView({ behavior: 'smooth' });
      } else if (firstField) {
        const { id } = firstField;
        const parentElm = fieldsRef.current[id];
        if (parentElm) {
          parentElm.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      myRef.current = true;
    }
  }, [currentSection]);

  const shouldFieldVisible = (fieldId, trueReturnValue = {}, falseReturnValue = { display: 'none' }) => {
    if (sectionsData.length === 0) {
      return trueReturnValue;
    }
    let valid = false;
    const section = find(sectionsData, ({ key }) => key === currentSection);
    if (section) {
      for (let field of section.form_fields) {
        if (field.id === fieldId) {
          valid = true;
          break;
        }
      }
    }
    return valid ? trueReturnValue : falseReturnValue;
  };

  const getVisibleFormFields = () => {
    let visibleFormFieldsId = [];
    for (let field of fields) {
      if (shouldFieldVisible(field.id, true, false)) {
        let id = field.id;
        if (field.type === 'phone_number') id = PHONE_NUMBER_INPUT_PREFIX + id;
        visibleFormFieldsId.push(id.toString());
        if (field.type === 'name' && field.options && field.options[0]['value'] === 'split_name') {
          visibleFormFieldsId.push('last_name')
          visibleFormFieldsId.push('first_name')
        }
      }
    }
    return visibleFormFieldsId;
  };

  const scrollToTop = () => {
    const elements = document.getElementsByClassName('ant-modal-wrap') || []
    if (elements && elements.length > 0) {
      const requiredElement = elements[0]
      requiredElement.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  };

  const getNameFieldOrder = () => {
    let nameFieldOrder = 0;
    for (const field of fields) {
      if (field['type'] === 'name') {
        nameFieldOrder = field['order']
        break;
      }
    }
    return nameFieldOrder
  }

  const validateAndMoveToNext = () => {
    form.validateFields(getVisibleFormFields(), (err, _) => {
      if (!err) {
        setCurrentSection(currentSection + 1);
      } else {
        moveToErrorField(err);
      }
    });
  };

  const moveToErrorField = (err = {}) => {
    let ids = Object.keys(err);
    ids = ids.map((id) => {
      if (id.includes(PHONE_NUMBER_INPUT_PREFIX)) {
        id = id.substring(PHONE_NUMBER_INPUT_PREFIX.length);
      }
      return id;
    });

    const getOrderOfField = (id) => {
      if (id === 'last_name' || id === 'first_name') return getNameFieldOrder()
      if (fieldsXHash[id]) {
        return fieldsXHash[id]['order'];
      }
      return 0;
    };

    ids = ids.sort((a, b) => getOrderOfField(a) - getOrderOfField(b));
    let id = ids[0];
    if (id) {
      let parentElm = null
      if (id === 'first_name' || id === 'last_name') {
        parentElm = document.querySelector(`label[for=${id}]`)
      } else {
        parentElm = fieldsRef.current[id];
      }
      if (parentElm) {
        parentElm.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const SectionHeader = () => {
    const { name, description, key } = sectionsData[currentSection];
    const total = sectionsData.length;
    return (
      <div className="front-section-header">
        <div className="section-info">
          Step {key + 1} of {total}
        </div>
        <div className="section-title">{name}</div>
        <If condition={description}>
          <FroalaEditorView model={description} />
        </If>
      </div>
    );
  };

  const SectionFooter = () => {
    return (
      <div className="front-section-footer">
        <Space>
          <Button
            className="previous-button"
            style={{ visibility: currentSection <= 0 ? 'hidden' : '' }}
            onClick={() => { scrollToTop(); setCurrentSection(currentSection - 1) }}>
            Previous
          </Button>
          <Choose>
            <When condition={currentSection < sectionsData.length - 1}>
              <Button type="primary" onClick={validateAndMoveToNext}>
                Next
              </Button>
            </When>
            <Otherwise>
              <Button
                type="primary"
                className={`${isEmpty(fields) ? 'hide' : ''}`}
                onClick={applyForVerification}
                loading={verifying}
                disabled={isEmpty(fields)}>
                <span className="arc-p arc-color-white">
                  <I18nCustomFormatter id="apply-for-verification-btn-label" />
                </span>
              </Button>
            </Otherwise>
          </Choose>
        </Space>
      </div>
    );
  };

  return (
    <div className="select-and-apply-for-verification-wrapper">
      <div className="body">
        <div>
          <Button type="link" className="link-btn" onClick={back}>
            <FontAwesomeIcon icon={faArrowLeft} className="mr16 arc-color-primary" />
            <div className="email-wrapper">{user.email}</div>
          </Button>
        </div>
        <img src={window.customerLogo} className="customer-logo" width="50px" alt={`${window.collegeName} logo`} />
        <If condition={memberStatus === MEMBER_STATUS_UNKNOWN}>
          <p className="arc-H200 arc-color-black mt16">
            <I18nCustomFormatter id="apply-for-verification-label" />
          </p>
          <Select
            style={{ width: '100%' }}
            value={selectedVerificationGroup.id}
            placeholder="Select Verification"
            onChange={changeVerificationGroup}
            className="mt16"
            size="large">
            {groups.map((group) => (
              <Select.Option value={group.id} key={group.id}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </If>
        <If condition={fields.length && memberStatus === MEMBER_STATUS_UNKNOWN}>
          <Divider />
          <Header icon={faShield} title="VERIFICATION FORM" verificationGroupName={selectedVerificationGroup.name} />
          <div className="custom-form-item-wrapper mt16">
            <If condition={showHardCodedFormFields}>
              <Form.Item label="Full Name">
                {getFieldDecorator('full_name', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your full name',
                    },
                    {
                      validator: validateFullName,
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(<Input placeholder="Enter your full name" />)}
              </Form.Item>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  initialValue: user.email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Invalid email',
                      transform: (value) => value && value.trim(),
                    },
                    {
                      required: true,
                      message: 'Please enter your E-mail!',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(<Input placeholder="Enter your email" disabled />)}
              </Form.Item>
            </If>
            <If condition={sectionsData.length > 0}>
              <SectionHeader />
            </If>
            {fields.map((field) => (
              <div ref={(el) => addToRefs(el, field.id)} style={shouldFieldVisible(field.id)}>
                <CustomFormItem
                  key={field.id}
                  form={form}
                  formInput={field}
                  formRecord={{}}
                  labelCol={{ sm: 24 }}
                  wrapperCol={{ sm: 24 }}
                  metadata={{ source_type: 'customform', source_id: formId, access_level: 'protected' }}
                />
              </div>
            ))}
            <If condition={sectionsData.length > 0}>
              <SectionFooter />
            </If>
          </div>
        </If>
        <If condition={memberStatus === MEMBER_STATUS_PENDING}>
          {/* <Divider /> */}
          <Header icon={faClock} title="AWAITING VERIFICATION" verificationGroupName={selectedVerificationGroup.name} />
          <p className="arc-support arc-color-black mt16">
            <I18nCustomFormatter id="pending-verification-title" />
          </p>
          <p className="arc-p arc-color-black mt16">
            <I18nCustomFormatter
              id="pending-verification-description"
              values={{
                email: user.email,
              }}
            />
          </p>
          <p className="arc-p arc-color-black mt16">
            <I18nCustomFormatter id="verification-assistance-label" />
            &nbsp;
            <a className="arc-color-primary" href={`mailto:${collegeEmail}`}>
              {collegeEmail}
            </a>
          </p>
        </If>
        <If condition={memberStatus === MEMBER_STATUS_APPROVED}>
          <Divider />
          <Header icon={faShieldCheck} title="VERIFIED MEMBER" verificationGroupName={selectedVerificationGroup.name} />
          <p className="arc-p arc-color-black mt16">
            <I18nCustomFormatter id="verification-assistance-label" />
            &nbsp;
            <a className="arc-color-primary" href={`mailto:${collegeEmail}`}>
              {collegeEmail}
            </a>
          </p>
        </If>
        <If condition={[MEMBER_STATUS_APPROVED, MEMBER_STATUS_PENDING].indexOf(memberStatus) !== -1}>
          <Divider />
          <div className="action-block">
            <a
              href="javascript:void(0)"
              className="arc-p arc-color-primary"
              onClick={() => {
                setMemberStatus(MEMBER_STATUS_UNKNOWN)
                setCurrentSection(0)
              }}>
              <FontAwesomeIcon icon={faShieldCheck} className="mr16" />
              <I18nCustomFormatter
                id="verification-feedback-apply-for-another-verification-label"
                values={{ group: selectedVerificationGroup.name }}
              />
            </a>
          </div>
          <div className="action-block mt8">
            <a href="javascript:void(0)" className="arc-p arc-color-primary" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimesCircle} className="mr16" />
              <I18nCustomFormatter id="close-modal-label" />
            </a>
          </div>
        </If>
      </div>
      <If condition={memberStatus === MEMBER_STATUS_UNKNOWN && sectionsData.length === 0}>
        <Button
          type="primary"
          className={`verification-btn mt16 ${isEmpty(fields) ? 'hide' : ''}`}
          onClick={applyForVerification}
          loading={verifying}
          disabled={isEmpty(fields)}>
          <span className="arc-p arc-color-white">
            <I18nCustomFormatter id="apply-for-verification-btn-label" />
          </span>
        </Button>
      </If>
    </div>
  );
}

export default Form.create()(SelectAndApplyForVerificationGroup);
