/* eslint-disable max-statements */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { IntlProvider } from 'react-intl';
import WaitingScreen from 'Src/common/components/waitingScreen';
import VerificationProvider from 'Src/common/providers/verification';
import { isUserVerified } from 'Src/spaces/actions';
import SpacesWrapper from './spacesWrapper';
import { VFX_ENDPOINTS, ROUTES_NAME } from 'Src/spaces/constants';

let spaceMessages = require('Data/en-US/space.json');
let vfxMessages = require('Data/en-US/vfx.json');
const commonI18Messages = require('Data/en-US/common.json');

spaceMessages = { ...commonI18Messages, ...spaceMessages };

// take the messages for directory and vfx and merge them
spaceMessages = { ...spaceMessages, ...vfxMessages };

if (!isEmpty(window.i18nMetadata) && !isEmpty(window.i18nMetadata.common)) {
  spaceMessages = { ...spaceMessages, ...window.i18nMetadata.common };
}

if (!isEmpty(window.i18nMetadata) && !isEmpty(window.i18nMetadata.spaces)) {
  spaceMessages = { ...spaceMessages, ...window.i18nMetadata.spaces };
}

const VerificationWrapper = ({ component, routeName, isPublic, ...rest }) => {
  const [verificationGroups, setVerificationGroups] = useState([]);
  const { isLoading } = useAuth0();

  function formatUrl(url) {
    if (routeName === ROUTES_NAME.SPACE_DETAILS) {
      return url.replace(':spaceSlug', rest.props.match.params.spaceSlug);
    } else if (routeName === ROUTES_NAME.POST_DETAILS) {
      return url.replace(':postId', rest.props.match.params.postId);
    } else if (routeName === ROUTES_NAME.CHANNEL_FEED) {
      return url
        .replace(':spaceSlug', rest.props.match.params.spaceSlug)
        .replace(':channelSlug', rest.props.match.params.channelSlug);
    }
    return url;
  }

  async function getVerification() {
    try {
      const response = await isUserVerified(formatUrl(VFX_ENDPOINTS[routeName]));
      setVerificationGroups(response.data?.groups ? response.data.groups : []);
      return response.data;
    } catch (error) {
      return {
        isVerifying: false,
        isVerified: false,
        isError: true,
        groups: [],
      };
    }
  }

  return (
    <Choose>
      <When condition={isLoading}>
        <WaitingScreen text="Preparing Space" />
      </When>
      <Otherwise>
        <VerificationProvider getVerification={isPublic ? undefined : getVerification}>
          <IntlProvider locale="en" messages={spaceMessages}>
            <SpacesWrapper
              component={component}
              isPublic={isPublic}
              routeName={routeName}
              verificationGroups={verificationGroups}
              {...rest}
            />
          </IntlProvider>
        </VerificationProvider>
      </Otherwise>
    </Choose>
  );
};

export default VerificationWrapper;
