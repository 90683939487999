import React from 'react';
import { Button } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Auth0 Login Button
 * @param {*} props
 */
function LoginButton(props) {
  const { loginWithPopup } = useAuth0();
  const searchParams = `${window.location.search ? window.location.search.concat('&is_verification_popup_open=true') : '?is_verification_popup_open=true'} `;

  /**
   * Login function
   */
  function login() {
    // Set isAuthenticated in local storage for account selector redirect
    localStorage.setItem('isAuthenticated', false);
    const newWindow = window.open();
    loginWithPopup({
      // adding prompt login so it does not re authenticate user automatically ones he is logged out
      prompt: 'login',
      appState: {
        targetUrl: `${window.location.pathname + searchParams}`,
      },
      primaryColor: props.theme.primaryColor,
      logo: props.theme.logo,
    }, {
            timeoutInSeconds: 600,
            popup: newWindow,
    });
  }

  return (
    <Button onClick={login} {...props}>
      {props.children}
    </Button>
  );
}

export default LoginButton;
