/* eslint-disable require-jsdoc */
/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable complexity */
/* eslint-disable max-nested-callbacks */
/* eslint-disable max-statements */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { shape } from 'prop-types';
import { isEmpty, findIndex, find, remove, cloneDeep } from 'lodash';
import { message, Modal } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import arrayMove from 'array-move';
import queryString from 'query-string';
import { ImagePreview } from 'arcl';
import postal from 'postal';
import HybridAuthContext from 'Src/spaces/context/auth';
import VerificationContext from 'Src/common/context/verification';
import AlmasightsProvider from 'Src/common/providers/almasights';
import useWindowDimensions from 'Src/common/hooks/useWindowDimension';
import AssetManagerHelper from 'Src/common/utilities/asset_manager';
import * as ENDPOINTS from 'Src/spaces/endpoints';
import * as ROUTES from 'Src/spaces/routes';
import { MEMBER_STATUSES, POST_ACTION } from 'Src/spaces/constants';
import SpaceDetailsComponent from 'Src/spaces/components/spaceDetails';
import WaitingScreen from 'Src/common/components/waitingScreen';
import { handleRedirect, onReaction } from 'Src/spaces/utils';
import { formatPost, fetchPostAttachments, transformPost } from 'Src/common/utilities/spaces_utils';
import UpdatePostModal from 'Src/spaces/components/createPost/modal';
import {
  fetchSpace,
  createPost,
  deletePost,
  createChannel,
  fetchSpaceFeed,
  reorderSpaceChannels,
  updateChannelSubscription,
  updateChannel,
  isUserVerified,
  updateOrDeleteReaction,
  updateResource,
  createResource,
  subscribeSpace,
  unsubscribeSpace,
  deleteResource,
  deleteChannel,
  reportAbusePost,
  sendPostAsEmail,
  editPost,
  getPinnedPosts,
  pinPost,
  reorderSpaceResources,
  fetchSubscribersList,
} from 'Src/spaces/actions';
import SpaceSubscribersModal from './spaceSubscribersModal';

const { confirm } = Modal;
let page = 1;

/**
 * Space details container
 */
function SpaceDetails({ history, match }) {
  const [postAttachments, setPostAttachments] = useState({});
  const [isApplyForVerificationModalVisible, setIsApplyForVerificationModalVisible] = useState(false);
  const { isUserAuthenticated } = useContext(HybridAuthContext);
  // if channelSpaceId is there then it means we are in channel specific feed, Id becomes channelId in that case
  const verificationData = useContext(VerificationContext);
  const {
    isVerified,
    isVerifying,
    isNewUser,
    pendingVerifications,
    space_id: spaceId,
    channel_id: channelId,
    update,
    isError,
  } = verificationData;
  const { width } = useWindowDimensions();
  const { logout, loginWithPopup } = useAuth0();
  const [isChannelSettingsModalVisible, setIsChannelSettingsModalVisible] = useState(false);
  const [space, setSpace] = useState({});
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [is404, setIs404] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loadingFeed, setLoadingFeed] = useState(true);
  const [bannerImage, setBannerImage] = useState(null);
  const [spaceLogo, setSpaceLogo] = useState(null);
  const [bannerImageThumbnail, setBannerImageThumbnail] = useState(null);
  const [loadingBannerImage, setLoadingBannerImage] = useState(true);
  const [newPost, setNewPost] = useState(null);
  const [updatingSubscription, setUpdatingSubscription] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState();
  const [previewImageList, setPreviewImageList] = useState([]);
  const [postToUpdate, setPostToUpdate] = useState({});
  const [pinnedPosts, setPinnedPosts] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [subscribersOverview, setSubscribersOverview] = useState({});
  const [isSpaceSubscribersModalVisible, setIsSpaceSubscribersModalVisible] = useState(false);
  const postRef = useRef();

  postRef.current = posts;

  // Opening create channel modal via query params
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);

    if (queryParams.create_topic === 'true') {
      toggleChannelSettingsModal();
    }
  }, []);

  function onReactionL(postId, summary) {
    updateOrDeleteReaction(summary.self ? 'delete' : 'post', { kind: summary.kind }, postId);
    setPosts(onReaction(postRef.current, postId, summary).slice());
  }

  useEffect(() => {
    if (!isEmpty(posts)) {
      const postContainer = document.getElementsByClassName('feed-wrapper');

      if (postContainer) {
        postContainer[0]?.addEventListener('click', (e) => {
          if (e.target.tagName === 'A') {
            e.stopPropagation();
          }
        });
      }
    }
  }, [isRendered, posts]);

  /**
   * Handles image click
   */
  function handleImageClick(index, images, postId) {
    const arr = [];
    const post = find(postRef.current, (o) => o.id === postId);
    images.forEach((image) => {
      arr.push({ url: image.url, shareUrl: post.post_url, shareText: post.heading });
    });
    setActiveImageIndex(index);
    setPreviewImageList(arr);
  }

  useEffect(() => {
    if (isRendered) {
      if (match?.params?.channelSlug) {
        isUserVerified(
          ENDPOINTS.CHANEL_FEED_VFX_PATH.replace(':spaceSlug', match?.params?.spaceSlug).replace(
            ':channelSlug',
            match?.params?.channelSlug,
          ),
        ).then((res) => {
          update({
            isVerifying: false,
            isVerified: res.data.is_verified,
            isNewUser: res.data.is_new_user,
            pendingVerifications,
            update,
            id: res.data.id,
            space_id: res.data.space_id,
            channel_id: res.data.channel_id,
            post_id: res.data.post_id,
            groups: res.data.groups,
          });
        });
      } else {
        isUserVerified(ENDPOINTS.SPACE_DETAILS_VFX_PATH.replace(':spaceSlug', match?.params?.spaceSlug)).then((res) => {
          update({
            isVerifying: false,
            isVerified: res.data.is_verified,
            isNewUser: res.data.is_new_user,
            pendingVerifications,
            update,
            id: res.data.id,
            space_id: res.data.space_id,
            channel_id: res.data.channel_id,
            post_id: res.data.post_id,
            groups: res.data.groups,
          });
        });
      }
    }
  }, [match?.params?.spaceSlug, match?.params?.channelSlug, isRendered]);

  const getSpaceSubscribers = () => {
    fetchSubscribersList(spaceId, 'page=1&ordering=first_name')
      .then((res) => {
        setSubscribersOverview(res.data);
      })
      .catch(() => {
        setSubscribersOverview({});
      });
  };

  useEffect(() => {
    if (!isVerifying) {
      page = 1;
      if (spaceId !== space.id) {
        setLoading(true);
        fetchSpace(spaceId)
          .then((res) => {
            setSpace(res.data);
            getSpaceSubscribers();

            const queryParams = queryString.parse(window.location.search);

            if (queryParams.show_subscribers === 'true') {
              toggleSpaceSubscriberModal();
            }

            if (!isEmpty(res.data.banner_image_asset)) {
              async function _getAsset(assetId) {
                const assetManagerHelper = new AssetManagerHelper(assetId);
                const variants = await assetManagerHelper.getVariants();
                setBannerImage(variants.url);
                if (variants.thumbnailUrl) {
                  setBannerImageThumbnail(variants.thumbnailUrl);
                }
                setTimeout(() => {
                  setLoadingBannerImage(false);
                }, 300);
              }
              _getAsset(res.data.banner_image_asset.id);
            } else {
              setBannerImage(null);
              setBannerImageThumbnail(null);
              setTimeout(() => {
                setLoadingBannerImage(false);
              }, 300);
            }
            if (!isEmpty(res.data.logo_asset)) {
              async function _getAsset(assetId) {
                const assetManagerHelper = new AssetManagerHelper(assetId);
                const variants = await assetManagerHelper.getVariants();
                setSpaceLogo(variants.url);
              }
              _getAsset(res.data.logo_asset.id);
            } else {
              setSpaceLogo(null);
            }
            setChannels(res.data.channels);
            setIs404(false);
            setTimeout(() => {
              setLoading(false);
            }, 300);
          })
          .catch((err) => {
            if (err.response.status === 404) {
              setIs404(true);
            } else {
              setIs404(false);
            }
            setLoading(false);
          });
      }
      setLoadingFeed(true);
      fetchSpaceFeed(spaceId, channelId, page).then((res) => {
        if (!isEmpty(res.data.results)) {
          formatPost(
            res.data.results,
            (newPosts) => {
              setPosts(newPosts);
              setHasMore(res.data.next !== null);
              setLoadingFeed(false);
              setIsRendered(true);
              newPosts.forEach((newPost) => {
                fetchPostAttachments(newPost, (attachments) => {
                  postAttachments[newPost.id] = attachments;
                  setPostAttachments({ ...postAttachments });
                });
              });
            },
            (input, routeName) => handleRedirect(input, routeName, verificationData),
            (postId, summary) => onReactionL(postId, summary),
            true,
            handleImageClick,
          );
        } else {
          setPosts([]);
          setHasMore(false);
          setLoadingFeed(false);
        }
      });
      if (!isVerified) {
        const queryS = queryString.parse(history.location.search);
        if (!isEmpty(queryS) && queryS.is_verification_popup_open) {
          toggleApplyForVerificationModal();
          history.push(ROUTES.SPACE_DETAILS.replace(':spaceSlug', match?.params?.spaceSlug));
        }
      }
    }
  }, [spaceId, channelId, isVerified, isVerifying]);

  useEffect(() => {
    if (!isEmpty(postAttachments)) {
      posts.forEach((post) => {
        if (postAttachments[post.id]) {
          post.attachments = postAttachments[post.id];
        }
      });
      setPosts([...posts]);
    }
  }, [postAttachments]);

  useEffect(() => {
    if (spaceId && isVerified) {
      getPinnedPosts(spaceId, channelId).then((res) => {
        setPinnedPosts(res.data.results);
      });
    }
  }, [spaceId, channelId, isVerified]);

  useEffect(() => {
    if (isError) {
      setIs404(true);
      setLoading(false);
    }
  }, [isError]);

  /**
   * toggle channel settings
   */
  function toggleChannelSettingsModal() {
    setIsChannelSettingsModalVisible((val) => !val);
  }

  /**
   * Creates a new post
   */
  function handleCreatePost(values, sendAsEmail) {
    createPost(values).then((res) => {
      const formattedPost = transformPost(
        res.data,
        (input, routeName) => handleRedirect(input, routeName, verificationData),
        (postId, summary) => onReactionL(postId, summary),
        true,
        handleImageClick,
      );
      setPosts([formattedPost, ...posts]);
      if (sendAsEmail) {
        sendPostAsEmail(formattedPost?.space?.id, formattedPost.id).then(() => {
          message.success('Post has been sent as email');
        });
      }
    });
  }

  /**
   * Show delete confirm modal
   */
  function showDeletePostModal(id) {
    confirm({
      title: 'Delete Post',
      content: 'Deleted posts cannot be retrieved. Would you like to proceed?',
      okText: 'Delete Post',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deletePost(id);
        // if post was newly created and user haven't refreshed the page
        if (newPost && newPost.id === id) {
          setNewPost(null);
        } else {
          const filteredPost = posts.filter((post) => post.id !== id);
          setPosts(filteredPost);
        }
        // Remove from pinned post list if post is deleted
        remove(pinnedPosts, (o) => o.id === id);
        setPinnedPosts(pinnedPosts);
      },
    });
  }

  function showEmailSendConfirmationModal(postId) {
    confirm({
      title: 'Are you sure to send this post as announcement',
      content: 'As a moderator, you can send this post out immediately to all subscribers',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        sendPostAsEmail(spaceId, postId).then(() => {
          message.success('Post has been sent as email');
        });
      },
    });
  }

  /**
   * Handles click on menu item
   */
  function handleMenuClick(postId, key) {
    if (key === POST_ACTION.DELETE) {
      showDeletePostModal(postId);
    } else if (key === POST_ACTION.REPORT_ABUSE) {
      reportAbusePost(postId).then((res) => {
        message.success('This post has been reported to the moderators of this space');
        const index = findIndex(posts, (val) => val.id === postId);
        if (index > -1) {
          // Remove report_abuse menu item from post
          const clonedPosts = cloneDeep(posts);
          const post = clonedPosts[index];
          const postItems = post.menuItems.filter((item) => item.key !== POST_ACTION.REPORT_ABUSE);
          post.permissions.can_flag_post = false;
          post.menuItems = postItems;
          clonedPosts[index] = post;
          setPosts(clonedPosts);
        }
      });
    } else if (key === POST_ACTION.SEND_AS_EMAIL) {
      showEmailSendConfirmationModal(postId);
    } else if (key === POST_ACTION.EDIT_POST) {
      const post = find(posts, (o) => o.id === postId);
      setPostToUpdate(post);
    } else if (key === POST_ACTION.PIN_POST) {
      handlePinPost(postId);
    }
  }

  function handleChannelCreate(values) {
    const data = { name: values.name, only_moderators_can_post: values.only_moderators_can_post };
    const channelPromise = values.id ? updateChannel(spaceId, values.id, data) : createChannel(spaceId, data);
    channelPromise.then(() => {
      fetchSpace(spaceId)
        .then((res) => {
          setSpace(res.data);
          setChannels(res.data.channels);
          setIs404(false);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setIs404(true);
          } else {
            setIs404(false);
          }
          setLoading(false);
        });
    });
  }

  function handleResourceAdd(values) {
    const total = space.resources.length || 0;
    const resourcePromise = values.id
      ? updateResource(spaceId, values.id, values)
      : createResource(spaceId, { ...values, display_order: total + 1 });
    resourcePromise.then((res) => {
      if (values.id) {
        const index = findIndex(space.resources, (val) => val.id === values.id);
        if (index > -1) {
          space.resources[index] = res.data;
        }
      } else {
        space.resources.push(res.data);
      }
      setSpace({ ...space });
    });
  }

  function handleResourceDelete(resource) {
    deleteResource(spaceId, resource.id).then((res) => {
      const index = findIndex(space.resources, (val) => val.id === resource.id);
      if (index > -1) {
        space.resources.splice(index, 1);
        setSpace({ ...space });
      }
    });
  }

  function handleChannelDelete(channel) {
    deleteChannel(spaceId, channel.id).then(() => {
      setChannels(channels.filter((val) => val.id !== channel.id));
      message.success(`Topic ${channel.name} deleted successfully`);
    });
  }

  function handleChannelSorting({ oldIndex, newIndex }) {
    const reorderedChannels = arrayMove(channels, oldIndex, newIndex);
    setChannels(reorderedChannels.slice());
    const formattedChannelsData = [];
    reorderedChannels.forEach((channel, idx) => {
      formattedChannelsData.push({ id: channel.id, display_order: idx + 1 });
    });
    reorderSpaceChannels(spaceId, formattedChannelsData);
    message.success('Changes saved');
  }

  function handleResourceSorting({ oldIndex, newIndex }) {
    const reorderedResources = arrayMove(space.resources, oldIndex, newIndex);
    const formattedResourcesData = [];
    reorderedResources.forEach((resource, idx) => {
      formattedResourcesData.push({ id: resource.id, display_order: idx + 1 });
    });
    reorderSpaceResources(spaceId, formattedResourcesData);
    space.resources = reorderedResources;
    setSpace({ ...space });
    message.success('Changes saved');
  }

  function handleLogin() {
    const searchParams = `${
      window.location.search
        ? window.location.search.concat('&is_verification_popup_open=true')
        : '?is_verification_popup_open=true'
    } `;
    const newWindow = window.open();
    const targetUrl = `/select-account?next=${window.location.pathname + searchParams}`;
    sessionStorage.setItem('targetUrl', targetUrl);
    loginWithPopup(
      {
        // adding prompt login so it does not re authenticate user automatically ones he is logged out
        prompt: 'login',
        primaryColor: window.primaryColor,
        logo: window.customerLogo,
      },
      {
        timeoutInSeconds: 600,
        popup: newWindow,
      },
    );
  }

  function toggleApplyForVerificationModal() {
    if (!isApplyForVerificationModalVisible && !isUserAuthenticated) {
      handleLogin();
    } else {
      setIsApplyForVerificationModalVisible(!isApplyForVerificationModalVisible);
    }
  }

  function handleChannelClick(channel) {
    if (channel && channel.channel_url) {
      history.push(new URL(channel.channel_url).pathname);
    } else {
      history.push(new URL(space.space_url).pathname);
    }
  }

  function handleChannelSubscribe(channelId, canUnsubscribe) {
    updateChannelSubscription(spaceId, channelId, canUnsubscribe).then((res) => {
      let channelName = '';
      const updatedChannels = channels.map((channel) => {
        if (channel.id === channelId) {
          channel.permissions.can_unsubscribe = !channel.permissions.can_unsubscribe;
          channel.permissions.can_subscribe = !channel.permissions.can_subscribe;
          channelName = channel.name;
        }
        return channel;
      });
      let msg = '';
      if (canUnsubscribe) {
        msg = `You have unsubscribed from ${channelName}`;
      } else {
        msg = `You will now be notified for new posts on ${channelName}`;
      }
      message.success(msg);
      setChannels(updatedChannels);
    });
  }

  /**
   * Loads more posts
   */
  function handleViewMore() {
    page += 1;
    fetchSpaceFeed(spaceId, channelId, page).then((res) => {
      formatPost(
        res.data.results,
        (newPosts) => {
          // Creating new post will shift all posts by one, Remove last post to prevent duplication
          if (newPost && posts.length <= 10) {
            const previousPosts = [...posts];
            previousPosts.pop();
            setPosts([...previousPosts, ...newPosts]);
          } else {
            setPosts([...posts, ...newPosts]);
          }
          setHasMore(res.data.next !== null);
          newPosts.forEach((newPost) => {
            fetchPostAttachments(newPost, (attachments) => {
              postAttachments[newPost.id] = attachments;
              setPostAttachments({ ...postAttachments });
            });
          });
        },
        (input, routeName) => handleRedirect(input, routeName, verificationData),
        (postId, summary) => onReactionL(postId, summary),
        true,
        handleImageClick,
      );
    });
  }

  function subscribe() {
    setUpdatingSubscription(true);
    subscribeSpace(space.id)
      .then((res) => {
        setTimeout(() => {
          setSpace({ ...space, status: MEMBER_STATUSES.SUBSCRIBED });
          setUpdatingSubscription(false);
          message.success('You have successfully subscribed to this space');
          postal.publish({
            channel: 'spaces',
            topic: 'space.subscribed',
          });
        }, 1000);
      })
      .catch(() => {
        setUpdatingSubscription(false);
      });
  }

  function unsubscribe() {
    setUpdatingSubscription(true);
    unsubscribeSpace(space.id)
      .then((res) => {
        setTimeout(() => {
          setSpace({ ...space, status: MEMBER_STATUSES.UNSUBSCRIBED });
          setUpdatingSubscription(false);
          message.success('You have successfully unsubscribed from this space');
          postal.publish({
            channel: 'spaces',
            topic: 'space.unsubscribed',
          });
        }, 1000);
      })
      .catch(() => {
        setUpdatingSubscription(false);
      });
  }

  /**
   * Handles preview modal close
   */
  function handleImagePreviewClose() {
    setPreviewImageList([]);
  }

  /**
   * Closes update post modal
   */
  function handleUpdatePostModalClose() {
    setPostToUpdate({});
  }

  function handlePostUpdate(postData) {
    const updatedPosts = [];
    const formattedPost = transformPost(
      postData,
      (input, routeName) => handleRedirect(input, routeName, verificationData),
      (postId, summary) => onReactionL(postId, summary),
      true,
      handleImageClick,
    );
    // Updated post in local state
    posts.forEach((post) => {
      if (post.id === formattedPost.id) {
        updatedPosts.push(formattedPost);
      } else {
        updatedPosts.push(post);
      }
    });
    setPosts(updatedPosts);

    // Updated pinned post heading when post is edited
    const clonedPosts = cloneDeep(pinnedPosts);
    const index = findIndex(clonedPosts, (val) => val.id === postToUpdate.id);
    if (index > -1) {
      clonedPosts[index] = {
        id: formattedPost.id,
        heading: formattedPost.header,
        is_pinned: formattedPost.isPinned,
        post_url: formattedPost.shareUrl,
      };
      setPinnedPosts(clonedPosts);
    }
  }

  function handlePostUpdateSubmit(value, sendAsEmail) {
    value.is_edited = true;
    editPost(postToUpdate.id, value).then((res) => {
      handlePostUpdate(res.data);
      if (sendAsEmail) {
        sendPostAsEmail(res.data.space?.id, res.data.id).then(() => {
          message.success('Post has been sent as email');
        });
      }
    });
    setPostToUpdate({});
  }

  function handlePinPost(postId) {
    const post = find(postRef.current, (o) => o.id === postId);
    const pinnedPost = find(pinnedPosts, (o) => o.id === postId);
    pinPost(postId, { is_pinned: !post.isPinned }).then((res) => {
      // Add or remove pined post from state
      if (isEmpty(pinnedPost)) {
        let clonedPosts = cloneDeep(pinnedPosts);
        clonedPosts = [
          { id: post.id, heading: post.header, is_pinned: !post.isPinned, post_url: post.shareUrl },
          ...clonedPosts,
        ];
        setPinnedPosts(clonedPosts);
      } else {
        remove(pinnedPosts, (o) => o.id === postId);
        setPinnedPosts(pinnedPosts);
      }
      // Updated current feed state
      handlePostUpdate(res.data);
    });
  }

  function toggleSpaceSubscriberModal() {
    setIsSpaceSubscribersModalVisible((prev) => !prev);
  }

  return (
    <Choose>
      <When condition={isVerifying || loading}>
        <WaitingScreen text={loading ? 'Getting post' : 'Checking Verifications'} />
      </When>
      <Otherwise>
        <AlmasightsProvider
          parentObject={{
            type: 'space',
            id: space.id,
            name: space.name,
          }}>
          <>
            <SpaceDetailsComponent
              is404={is404}
              width={width}
              space={space}
              posts={posts}
              hasMore={hasMore}
              spaceId={spaceId}
              newPost={newPost}
              channels={channels}
              bannerImage={bannerImage}
              loadingFeed={loadingFeed}
              channelId={channelId}
              bannerImageThumbnail={bannerImageThumbnail}
              handleViewMore={handleViewMore}
              handleChannelClick={handleChannelClick}
              handleCreatePost={handleCreatePost}
              handleMenuClick={handleMenuClick}
              handleChannelCreate={handleChannelCreate}
              handleChannelDelete={handleChannelDelete}
              handleChannelSorting={handleChannelSorting}
              handleChannelSubscribe={handleChannelSubscribe}
              verificationData={verificationData}
              isVerifying={isVerifying}
              loading={loading}
              isVerified={isVerified}
              isNewUser={isNewUser}
              loadingBannerImage={loadingBannerImage}
              pendingVerifications={pendingVerifications}
              isUserAuthenticated={isUserAuthenticated}
              toggleChannelSettingsModal={toggleChannelSettingsModal}
              isChannelSettingsModalVisible={isChannelSettingsModalVisible}
              toggleApplyForVerificationModal={toggleApplyForVerificationModal}
              isApplyForVerificationModalVisible={isApplyForVerificationModalVisible}
              handleResourceAdd={handleResourceAdd}
              handleResourceDelete={handleResourceDelete}
              handleResourceSorting={handleResourceSorting}
              updatingSubscription={updatingSubscription}
              subscribe={subscribe}
              unsubscribe={unsubscribe}
              logout={logout}
              spaceLogo={spaceLogo}
              applyForVerification={toggleApplyForVerificationModal}
              pinnedPosts={pinnedPosts}
              subscribersOverview={subscribersOverview}
              toggleSpaceSubscriberModal={toggleSpaceSubscriberModal}
            />
            <If condition={!isEmpty(previewImageList)}>
              <ImagePreview
                images={previewImageList}
                activeIndex={activeImageIndex}
                onClose={handleImagePreviewClose}
              />
            </If>
            <If condition={!isEmpty(postToUpdate)}>
              <UpdatePostModal
                postData={postToUpdate}
                spaceId={postToUpdate.space.id}
                togglePostModal={handleUpdatePostModalClose}
                handleSubmit={handlePostUpdateSubmit}
              />
            </If>
            <If condition={isSpaceSubscribersModalVisible}>
              <SpaceSubscribersModal
                profileAttribute={space.subscribers_profile_attribute}
                spaceId={spaceId}
                subscribersOverview={subscribersOverview}
                toggleModal={toggleSpaceSubscriberModal}
              />
            </If>
          </>
        </AlmasightsProvider>
      </Otherwise>
    </Choose>
  );
}

SpaceDetails.propTypes = {
  history: shape().isRequired,
};

export default withRouter(SpaceDetails);
