import React, { useState, useEffect, useContext } from 'react';
import { Alert, message, Button } from 'antd';
import { Feed, Post } from 'arcl';
import { isEmpty, findIndex } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import CreatePost from 'Src/spaces/components/createPost';
import useSessionAuth from 'Src/common/hooks/useSessionAuth';
import ChannelSettings from 'Src/spaces/components/channel/settings';
import WaitingScreen from 'Src/common/components/waitingScreen';
import VerificationGroupSelector from 'Src/common/components/verifications/groupSelector';
import ApplyForVerification from 'Src/common/components/verifications/applyForVerification';
import EmptyState from 'Src/spaces/components/emptyState';
import { Trail, NewPostTrail } from 'Src/spaces/animations';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import PinnedPost from 'Src/spaces/components/pinnedPosts';
import AlmasightsContext from 'Src/common/context/almasights';
import AuthHelper from 'Src/common/factories/auth';
import InfoCard from './InfoCard';
import SpaceDetailsSmallScreen from './smallScreen';

import './style.scss';

/**
 * Feeds Component
 */
function SpaceDetails({
  is404,
  space,
  posts,
  hasMore,
  spaceId,
  newPost,
  isNewUser,
  channels,
  bannerImage,
  loadingFeed,
  channelId,
  bannerImageThumbnail,
  handleViewMore,
  handleChannelClick,
  handleCreatePost,
  handleMenuClick,
  handleChannelCreate,
  handleChannelDelete,
  handleChannelSorting,
  handleChannelSubscribe,
  handleResourceAdd,
  handleResourceDelete,
  handleResourceSorting,
  verificationData,
  isVerifying,
  loading,
  pendingVerifications,
  loadingBannerImage,
  isUserAuthenticated,
  isChannelSettingsModalVisible,
  toggleChannelSettingsModal,
  toggleApplyForVerificationModal,
  isApplyForVerificationModalVisible,
  width,
  subscribe,
  updatingSubscription,
  unsubscribe,
  logout,
  spaceLogo,
  applyForVerification,
  pinnedPosts,
  subscribersOverview,
  toggleSpaceSubscriberModal,
}) {
  const { isAuthenticated, user } = useAuth0();
  const { isSessionAuthenticated, userData } = useSessionAuth();
  const [selectedDirectory, setSelectedDirectory] = useState({});
  const { almasightsInstance, isLoading } = useContext(AlmasightsContext);

  useEffect(() => {
    if (!isLoading && !isEmpty(space)) {
      almasightsInstance.trackEvent({
        touchpointType: 'spaces-visited-a-space',
        triggerType: 'view',
        authToken: window.csrfToken,
        jwtToken: AuthHelper.token(),
        object: {
          id: space.id,
          name: space.name,
          type: 'space',
        },
      });
    }
  }, [isLoading, space]);

  useEffect(() => {
    setSelectedDirectory({});
  }, [space]);

  function hasCreatePostPermission() {
    if (channelId) {
      const index = findIndex(channels, (val) => val.id === channelId);
      if (index > -1) {
        return channels[index]?.permissions?.can_create_post;
      }
    } else {
      return space?.permissions?.can_create_post_in_any_channel;
    }
    return false;
  }

  function handleResourceClick(val) {
    const elementToScroll = document.getElementsByClassName('main-content-container');
    if (!isEmpty(elementToScroll)) {
      elementToScroll[0].scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (val === selectedDirectory) {
      return;
    }

    // Remove previously selected directory
    if (!isEmpty(selectedDirectory)) {
      const oldDirectoryContainer = document.getElementById(
        `almabase-listing-page-container-${selectedDirectory.value}`,
      );
      oldDirectoryContainer?.remove();
    }
    setSelectedDirectory(val);
    message.success('Loading Directory');
  }

  return (
    <Choose>
      <When condition={isVerifying || loading}>
        <div className="loading-container">
          <WaitingScreen showIcon={false} />
        </div>
      </When>
      <Otherwise>
        <Choose>
          <When condition={is404}>
            <EmptyState
              heading="It's a 404"
              redirectLink="/s/explore/"
              buttonText="Explore Spaces"
              illustrationPath="img/spaces/404.svg"
              description="Sorry, we can't find this page. We have plenty other Spaces for you to visit though."
            />
          </When>
          <Otherwise>
            <Choose>
              <When condition={space?.permissions?.can_read_feed}>
                <Choose>
                  <When condition={width <= 768}>
                    <SpaceDetailsSmallScreen
                      space={space}
                      posts={posts}
                      channels={channels}
                      handleChannelClick={handleChannelClick}
                      handleCreatePost={handleCreatePost}
                      handleMenuClick={handleMenuClick}
                      handleChannelSubscribe={handleChannelSubscribe}
                      channelId={channelId}
                      NewPostTrail={NewPostTrail}
                      newPost={newPost}
                      loadingFeed={loadingFeed}
                      handleResourceAdd={handleResourceAdd}
                      handleResourceDelete={handleResourceDelete}
                      handleResourceSorting={handleResourceSorting}
                      subscribe={subscribe}
                      updatingSubscription={updatingSubscription}
                      unsubscribe={unsubscribe}
                      spaceLogo={spaceLogo}
                      canCreatePost={hasCreatePostPermission()}
                      applyForVerification={applyForVerification}
                      setSelectedDirectory={setSelectedDirectory}
                      loadingBannerImage={loadingBannerImage}
                      bannerImage={bannerImage}
                      bannerImageThumbnail={bannerImageThumbnail}
                      pinnedPosts={pinnedPosts}
                      handleChannelCreate={handleChannelCreate}
                      handleChannelDelete={handleChannelDelete}
                      handleResourceClick={handleResourceClick}
                      selectedDirectory={selectedDirectory}
                      subscribersOverview={subscribersOverview}
                      toggleSpaceSubscriberModal={toggleSpaceSubscriberModal}
                    />
                  </When>
                  <Otherwise>
                    <div className="large-screen-space-container space-details-container">
                      <div className="feed-wrapper">
                        <If condition={!space?.is_published}>
                          <Alert className="mb16" message="" description="This is an unpublished space." type="error" />
                        </If>

                        <Choose>
                          <When condition={!isEmpty(selectedDirectory)}>
                            <div id="iframe-preview-block" />
                            <script>
                              {(function (i, u, h, s, c) {
                                if (!h || !i || !u || !s) {
                                  return;
                                }
                                const doc = document;
                                const sb = doc.createElement('script');
                                sb.async = 1;
                                sb.src = `${h}js/almasdk/almasdk.js`;
                                // eslint-disable-next-line no-multi-assign
                                sb.onload = sb.onreadystatechange = () => {
                                  window.almaSDK.init(i, u + i, h, s, c, selectedDirectory.name);
                                };
                                doc.body.appendChild(sb);
                              })(
                                selectedDirectory.value,
                                `${window.location.protocol}//${window.location.host}/d/`,
                                window.staticPath,
                                window.location.origin,
                                'directory',
                              )}
                            </script>
                          </When>
                          <Otherwise>
                            <Trail reverse={false}>
                              <If condition={hasCreatePostPermission()}>
                                <CreatePost
                                  handleCreatePost={handleCreatePost}
                                  spaceId={spaceId}
                                  isSpaceUnpublished={!space?.is_published}
                                />
                              </If>
                              <If condition={!isEmpty(pinnedPosts)}>
                                <div className="mb20">
                                  <PinnedPost posts={pinnedPosts} />
                                </div>
                              </If>
                              <Choose>
                                <When condition={isEmpty(posts) && !newPost && !loadingFeed}>
                                  <EmptyState
                                    heading={<I18nCustomFormatter id="space-details-page-empty-state-header" />}
                                    redirectLink="/s/explore/"
                                    // buttonText="Explore Spaces"
                                    illustrationPath="img/spaces/empty-feed.svg"
                                    description=""
                                  />
                                </When>
                                <Otherwise>
                                  <Choose>
                                    <When condition={loadingFeed}>
                                      <WaitingScreen showIcon={false} />
                                    </When>
                                    <Otherwise>
                                      <NewPostTrail reverse={false}>
                                        <If condition={newPost}>
                                          <Post
                                            {...newPost}
                                            handleMenuClick={handleMenuClick}
                                            style={{ marginBottom: '20px' }}
                                          />
                                        </If>
                                      </NewPostTrail>
                                      <Feed
                                        className="space-details-feed"
                                        data={{ posts, handleMenuClick }}
                                        showViewMore={hasMore}
                                        handleViewMore={handleViewMore}
                                      />
                                    </Otherwise>
                                  </Choose>
                                </Otherwise>
                              </Choose>
                            </Trail>
                          </Otherwise>
                        </Choose>
                      </div>
                      <If condition={width > 768}>
                        <InfoCard
                          space={space}
                          spaceId={spaceId}
                          channels={channels}
                          channelId={channelId}
                          loadingBannerImage={loadingBannerImage}
                          bannerImage={bannerImage}
                          bannerImageThumbnail={bannerImageThumbnail}
                          handleChannelCreate={handleChannelCreate}
                          handleChannelSorting={handleChannelSorting}
                          handleChannelClick={handleChannelClick}
                          handleChannelSubscribe={handleChannelSubscribe}
                          handleChannelDelete={handleChannelDelete}
                          handleResourceAdd={handleResourceAdd}
                          handleResourceDelete={handleResourceDelete}
                          handleResourceSorting={handleResourceSorting}
                          subscribe={subscribe}
                          updatingSubscription={updatingSubscription}
                          unsubscribe={unsubscribe}
                          spaceLogo={spaceLogo}
                          applyForVerification={applyForVerification}
                          handleResourceClick={handleResourceClick}
                          setSelectedDirectory={setSelectedDirectory}
                          subscribersOverview={subscribersOverview}
                          toggleSpaceSubscriberModal={toggleSpaceSubscriberModal}
                        />
                      </If>

                      {isChannelSettingsModalVisible && (
                        <ChannelSettings
                          space={space}
                          channels={channels}
                          onClose={toggleChannelSettingsModal}
                          handleChannelSorting={handleChannelSorting}
                          handleChannelDelete={handleChannelDelete}
                          handleChannelCreate={handleChannelCreate}
                        />
                      )}
                    </div>
                  </Otherwise>
                </Choose>
              </When>
              <Otherwise>
                <VerificationGroupSelector
                  groups={verificationData?.groups}
                  isAuthenticated={isUserAuthenticated}
                  onVerify={toggleApplyForVerificationModal}
                  pendingVerifications={pendingVerifications}
                  theme={{
                    primaryColor: window.primaryColor,
                    logo: window.customerLogo,
                    collegeName: window.collegeName,
                  }}
                />
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
        <If condition={isApplyForVerificationModalVisible}>
          <ApplyForVerification
            groups={verificationData.groups}
            user={isAuthenticated ? user : userData}
            isSessionAuthenticated={isSessionAuthenticated}
            isAuthenticated={isAuthenticated}
            isNewUser={isNewUser}
            closeModal={toggleApplyForVerificationModal}
            logout={() =>
              logout({
                returnTo: `${window.location.origin}/s/auth0/logout?returnTo=${
                  window.location.origin + window.location.pathname
                }`,
              })
            }
            collegeEmail={window.collegeEmail}
            collegeName={window.collegeName}
          />
        </If>
        <If condition={window.isSiteAdmin && window.isSiteAdmin !== 'false'}>
          <Button
            icon={<FontAwesomeIcon icon={faCog} className="mr8" />}
            shape="round"
            type="primary"
            className="admin-link-btn"
            href={`/admin/spaces/${spaceId}`}>
            Admin
          </Button>
        </If>
      </Otherwise>
    </Choose>
  );
}

export default SpaceDetails;
