/* eslint-disable max-statements */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faLink, faBook } from '@fortawesome/pro-light-svg-icons';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import ReorderableModal from 'Src/common/components/reorderableModal';
import { RESOURCE_TYPES } from 'Src/spaces/constants';
import { fetchAllDirectories } from 'Src/spaces/actions';
import AddResource from './add';

import './style.scss';

/**
 * Resource component
 */
function Resources({
  items,
  canEdit,
  addResource,
  removeResource,
  sortResource,
  handleResourceClick,
  canListDirectories,
}) {
  const [isReorderModalVisible, setIsReorderModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedResource, setSelectedResource] = useState({});
  const [resourceType, setResourceType] = useState('');
  const [directoriesList, setDirectoriesList] = useState([]);

  useEffect(() => {
    if (resourceType && resourceType === RESOURCE_TYPES.DIRECTORY) {
      fetchAllDirectories().then((res) => {
        setDirectoriesList(res.data.results);
      });
    }
  }, [resourceType]);

  /**
   * toggle resource list modal
   */
  function toggleChannelSettingsModal() {
    setIsReorderModalVisible((val) => !val);
  }

  /**
   * toggle add resource modal
   */
  function toggleAddModal() {
    setIsAddModalVisible((val) => !val);
  }

  /**
   * Handle enter key press
   */
  function handleEnterClick(event, func) {
    if (event.key === 'Enter') {
      func();
    }
  }

  function addResourceL(values) {
    addResource(values);
    toggleAddModal();
  }

  function removeResourceL(resource) {
    removeResource(resource);
    toggleAddModal();
  }

  function handleResourceTypeChange(value) {
    setResourceType(value);
  }

  /**
   * Returns filtered resources for moderators
   */
  function getEditItems() {
    const arr = [];
    items.forEach((item) => {
      if (item.type === 'directory') {
        if (canListDirectories) arr.push(item);
      } else {
        arr.push(item);
      }
    });
    return arr;
  }

  return (
    <div className="resources-wrapper">
      <div className="arc-d-flex arc-flex-v-align-middle mb12">
        <p className="arc-H100 mr8">RESOURCES</p>
        <If condition={canEdit}>
          <FontAwesomeIcon
            className="arc-cursor-p"
            icon={faCog}
            tabIndex={0}
            onClick={toggleChannelSettingsModal}
            onKeyDown={(e) => handleEnterClick(e, toggleChannelSettingsModal)}
          />
        </If>
      </div>
      <div className="resources-list">
        {items.map((item) => {
          return (
            <div className="arc-d-flex mt16">
              <Choose>
                <When condition={item.type === RESOURCE_TYPES.DIRECTORY}>
                  <FontAwesomeIcon icon={faBook} className="arc-color-primary resource-icon" />
                  <a
                    className="resource-name arc-color-black arc-p arc-d-block ml8"
                    onClick={() => {
                      handleResourceClick(item);
                    }}>
                    {item.name}
                  </a>
                </When>
                <Otherwise>
                  <FontAwesomeIcon icon={faLink} className="arc-color-primary resource-icon" />
                  <a className="resource-name arc-color-black arc-p arc-d-block ml8" href={item.value} target="_blank">
                    {item.name}
                  </a>
                </Otherwise>
              </Choose>
            </div>
          );
        })}
      </div>
      {/* <If condition={canEdit}>
        <p
          className="arc-color-primary mt16 arc-cursor-p"
          tabIndex={0}
          onClick={toggleChannelSettingsModal}
          onKeyDown={(e) => handleEnterClick(e, toggleChannelSettingsModal)}>
          <FontAwesomeIcon icon={faCog} className="mr8" />
          <span className="arc-p">
            <I18nCustomFormatter id="resources-settings-label" />
          </span>
        </p>
      </If> */}
      <If condition={isReorderModalVisible}>
        <ReorderableModal
          title={<I18nCustomFormatter id="resources-settings-label" />}
          items={getEditItems()}
          closeModal={toggleChannelSettingsModal}
          handleSort={sortResource}
          hasAdd
          onAdd={() => {
            setSelectedResource({});
            setResourceType('');
            toggleAddModal();
          }}
          onEdit={(resource) => {
            setSelectedResource(resource);
            setResourceType(resource.type);
            toggleAddModal();
          }}
        />
        <If condition={isAddModalVisible}>
          <AddResource
            closeModal={toggleAddModal}
            addResource={addResourceL}
            resource={selectedResource}
            resourceType={resourceType}
            directoriesList={directoriesList}
            removeResource={removeResourceL}
            canListDirectories={canListDirectories}
            handleResourceTypeChange={handleResourceTypeChange}
          />
        </If>
      </If>
    </div>
  );
}

export default Resources;
