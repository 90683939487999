import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { List, Avatar } from 'antd';

import './style.scss';

/**
 * Moderators Component
 */
function Moderators({ data }) {
  return (
    <div className="moderators-wrapper">
      <List
        itemLayout="horizontal"
        dataSource={data}
        bordered={false}
        renderItem={(moderator) => (
          <a href={moderator.profile_url} target="_blank" rel="noreferrer">
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={moderator.present_picture} alt={moderator.name} />}
                title={<p className="arc-color-primary">{moderator.name}</p>}
              />
            </List.Item>
          </a>
        )}
      />
    </div>
  );
}

Moderators.propTypes = {
  data: arrayOf(shape).isRequired,
};

export default Moderators;
