import React from 'react';
import { string } from 'prop-types';
import { LIST_OF_BANNER_IMAGES } from 'Src/spaces/constants';
import { useSpring, animated } from 'react-spring';

import './style.scss';

function Header({ title }) {
  const fadeInAndSlideDownProps = useSpring({
    to: { opacity: 1, height: 150 },
    from: { opacity: 0, height: 80 },
  });
  function getRandomImage() {
    return LIST_OF_BANNER_IMAGES[Math.floor(Math.random() * LIST_OF_BANNER_IMAGES.length)];
  }
  return (
    <animated.div className="space-content-header" style={fadeInAndSlideDownProps}>
      <div className="space-content-header-gradient" />
      <div className="space-content-header-images" style={{ backgroundImage: `url(${getRandomImage()})` }} />
    </animated.div>
  );
}

Header.propTypes = {
  title: string.isRequired,
};

export default Header;
