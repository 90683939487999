import React, { Children } from 'react';
import SessionAuthContext from 'Src/common/context/sessionAuth';

// This is the provider for almabase authentication
function SessionAuthProvider({ children }) {
  /*
   * This in addition with Auth0 provider will control the authentication of the features
   * If user is logged in with django (normal login), the isSession Authentication flag will be true
   * For this to work, you will have to define isUserAuthenticated and csrfToken
  */
  return (
    // Assuming both isUserAuthenticated and csrfToken will come from django template
    <SessionAuthContext.Provider value={{ isSessionAuthenticated: window.isUserAuthenticated, csrfToken: window.csrfToken, userData: window.userData }}>
      {Children.only(children)}
    </SessionAuthContext.Provider>
  );
}

export default SessionAuthProvider;
