import React from 'react';
import { Avatar } from 'antd';
import { faUserLargeSlash, faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray, isEmpty } from 'lodash';

import './style.scss';

export const getProfileAttributeLabel = (profileAttribute, value) => {
  if (profileAttribute === 'class_year') {
    return `Class of ${value}`;
  }

  if (profileAttribute === 'verifications') {
    const groups = value.map((val) => val.name).join(', ');

    if (!groups) {
      return '';
    }

    return (
      <>
        <FontAwesomeIcon icon={faShieldCheck} className="mr6" />
        <span>{groups}</span>
      </>
    );
  }

  return value;
};

export const getTemplate = (item) => {
  const isUnsubscribed = !item.original.is_taggable;
  const profileAttributeKey = item.original.profile_attribute;
  const profileAttributeValue = item.original.profile_attribute_value;
  const profileAttributeHasValue = isArray(profileAttributeValue)
    ? !isEmpty(profileAttributeValue)
    : profileAttributeValue;

  return (
    <div className={`tribute-menu-item arc-d-flex arc-flex-direction-column ${isUnsubscribed && 'disabled-menu-item'}`}>
      <div className="arc-d-flex arc-flex-space-between-align arc-flex-v-align-middle">
        <div className="arc-d-flex arc-flex-v-align-middle">
          <Avatar size={20} />
          <span className={`ml8 arc-p username ${isUnsubscribed && 'arc-color-gray7'}`}>{item.original.key}</span>
        </div>
      </div>
      <div className="ellipses-text">
        <span className="profile-attribute-label arc-color-gray7">
          <Choose>
            <When condition={profileAttributeHasValue}>
              {getProfileAttributeLabel(profileAttributeKey, profileAttributeValue)}
            </When>
            <Otherwise>-</Otherwise>
          </Choose>
        </span>
      </div>
    </div>
  );
};

export const noResultsFoundTemplate = (
  <div className="tribute-no-results arc-d-flex arc-flex-justify-center">
    <FontAwesomeIcon icon={faUserLargeSlash} />
    <p>No search results</p>
  </div>
);
