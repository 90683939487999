import React from 'react';
import { useSpring, useTrail, animated } from 'react-spring';

export const fadeInAnimation = (reverse, delay = 100) => {
  return useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 20 },
    reverse,
    delay,
  });
};

export const fadeInAndSlideAnimation = () => {
  return useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 50 },
    delay: 200,
  });
};

export const slideInAnimation = () => {
  return useSpring({
    from: { x: -280 },
    to: { x: 0 },
  });
};

export const slideUpAnimation = () => {
  return useSpring({
    enter: { marginBottom: -200, opacity: 0 },
    leave: { marginBottom: 0, opacity: 1 },
    delay: 200,
  });
};

export const opacityAnimation = (delay = 0) => {
  return useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay,
  });
};

/**
 * Trail animation
 * @param {*} children
 */
export const Trail = ({ children, reverse }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    reverse,
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    delay: 100,
  });

  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <animated.div key={index} style={style}>
          <animated.div style={{ height }}>{items[index]}</animated.div>
        </animated.div>
      ))}
    </div>
  );
};

/**
 * Trail animation
 * @param {*} children
 */
export const NewPostTrail = ({ children, reverse }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 30, tension: 2000, friction: 200 },
    reverse,
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: -50 },
    delay: 200,
  });

  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <animated.div key={index} style={style}>
          <animated.div style={{ height }}>{items[index]}</animated.div>
        </animated.div>
      ))}
    </div>
  );
};
