import React, { useContext, useEffect } from 'react'
import AlmasightsContext from 'Src/common/context/almasights';
import AuthHelper from 'Src/common/factories/auth';

export const Touchpoint = () => {
  const { almasightsInstance, isLoading } = useContext(AlmasightsContext);

  useEffect(() => {
    if (!isLoading) {
      almasightsInstance.trackEvent({
        touchpointType: 'spaces-viewed-their-feed',
        triggerType: 'view',
        authToken: window.csrfToken,
        jwtToken: AuthHelper.token(),
        object: null,
      });
    }
  }, [isLoading]);

  return (
    <></>
  )
}
