import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { Drawer } from 'antd';
import { animated } from 'react-spring';
import Navigation from 'Src/spaces/containers/NavigationDrawer';
import { slideInAnimation } from 'Src/spaces/animations';

/**
 * Navigation Drawer component
 * @param {*} param0
 */
function NavigationDrawer({ isVisible, toggleDrawer, pathname, width }) {
  return (
    <Choose>
      <When condition={width > 1024}>
        <animated.div style={slideInAnimation()} className="navigation-container">
          <Navigation pathname={pathname} />
        </animated.div>
      </When>
      <Otherwise>
        <Drawer
          title={null}
          visible={isVisible}
          placement="left"
          width={280}
          bodyStyle={{
            height: '100vh',
            overflow: 'hidden',
            padding: 0,
          }}
          onClose={toggleDrawer}
          destroyOnClose>
          <Navigation pathname={pathname} toggleDrawer={toggleDrawer} />
        </Drawer>
      </Otherwise>
    </Choose>
  );
}

NavigationDrawer.propTypes = {
  isVisible: bool.isRequired,
  toggleDrawer: func.isRequired,
  pathname: string.isRequired,
  width: number.isRequired,
};

export default NavigationDrawer;
