/* eslint-disable max-nested-callbacks */
/* eslint-disable max-statements */
import React from 'react';
import { arrayOf, shape, bool, string, number, func } from 'prop-types';
import { isEmpty, find } from 'lodash';
import { Col, Input, Row, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpaceCard } from 'arcl';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { Transition, animated } from 'react-spring';
import EmptyState from 'Src/spaces/components/emptyState';
import WaitingScreen from 'Src/common/components/waitingScreen';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { opacityAnimation } from 'Src/spaces/animations';

import './style.scss';

/**
 * Explore component
 */
function Explore({
  cards,
  width,
  loading,
  categories,
  searchText,
  otherCards,
  setSearchText,
  selectedCategory,
  HandlePressEnter,
  handleCategoryChange,
  updateContextAndNavigate,
}) {
  const AnimatedCol = animated(Col);

  function getSpaceCards(items) {
    return (
      <Row gutter={[width > 768 ? 32 : 16, width > 768 ? 32 : 16]}>
        <Transition
          items={items}
          from={{ opacity: 0, y: 50 }}
          enter={{ opacity: 1, y: 0 }}
          leave={{ opacity: 0, y: 50 }}
          keys={(item) => item.id}>
          {(style, item) => (
            <AnimatedCol style={{ ...style }} xs={24} sm={24} md={12} lg={12} xl={8}>
              <div
                role="link"
                tabIndex={0}
                className="space-card-wrapper arc-cursor-p"
                onClick={() => updateContextAndNavigate(item)}
                onKeyDown={(e) => HandlePressEnter(e, updateContextAndNavigate(item))}>
                <SpaceCard {...item} />
              </div>
            </AnimatedCol>
          )}
        </Transition>
      </Row>
    );
  }

  function renderSpaces() {
    const foundCategory = find(categories, (o) => o.id.toString() === selectedCategory);

    return (
      <Choose>
        <When condition={loading}>
          <div className="loading-container">
            <WaitingScreen showIcon={false} />
          </div>
        </When>
        <Otherwise>
          <div className="explore-content-wrapper">
            <div className="mt16">
              <Choose>
                <When condition={isEmpty(cards) && isEmpty(otherCards) && !loading}>
                  <EmptyState
                    heading="Sorry, no results"
                    illustrationPath="img/spaces/empty-explore-new.svg"
                    description="Try searching something else"
                  />
                </When>
                <When condition={searchText && isEmpty(cards) && !isEmpty(otherCards)}>
                  <div className="mb32">
                    <p className="arc-H250">Sorry, no results found in "{foundCategory.name}"</p>
                    <p>However, we found similar spaces in &quot;other category&quot;</p>
                  </div>
                </When>
                <When condition={!isEmpty(cards)}>
                  <If condition={searchText}>
                    <div className="mb32">
                      <p className="arc-H150 mb12">Results for &quot;{searchText}&quot;</p>
                    </div>
                  </If>
                  <div className="mb16 arc-font-weight-bold-imp">
                    <p className="arc-H250">{foundCategory.name}</p>
                  </div>
                </When>
              </Choose>
            </div>
            <If condition={!isEmpty(cards)}>{getSpaceCards(cards)}</If>
            <If condition={!isEmpty(otherCards) && !foundCategory.is_default}>
              <div>
                <div className="mb16">
                  <p className="arc-H150 mb12 arc-font-weight-bold-imp">OTHERS</p>
                </div>
                {getSpaceCards(otherCards)}
              </div>
            </If>
          </div>
        </Otherwise>
      </Choose>
    );
  }

  return (
    <div className="explore-wrapper">
      <animated.div className="export-wrapper-header" style={opacityAnimation(300)}>
        <h5 className="arc-H500 arc-color-white">
          <I18nCustomFormatter id="explore-label" />
        </h5>
        <div className="search-wrapper">
          <Input
            size="large"
            placeholder="Search"
            value={searchText}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </animated.div>
      <Tabs
        type="card"
        activeKey={selectedCategory}
        className="explore-spaces-tab-container"
        onChange={handleCategoryChange}>
        {categories.map((category) => (
          <Tabs.TabPane tab={category.name} key={category.id} disabled={loading}>
            {renderSpaces()}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

Explore.propTypes = {
  cards: arrayOf(shape()).isRequired,
  otherCards: arrayOf(shape()).isRequired,
  width: number.isRequired,
  loading: bool.isRequired,
  spaces: arrayOf(shape()).isRequired,
  categories: arrayOf(shape()).isRequired,
  searchText: string.isRequired,
  setSearchText: func.isRequired,
  HandlePressEnter: func.isRequired,
  updateContextAndNavigate: func.isRequired,
  selectedCategory: shape().isRequired,
  handleCategoryChange: func.isRequired,
};

export default Explore;
