import React, { useState } from 'react';
import { Modal } from 'antd';
import NoEmailFound from './steps/noEmailFound';
import SelectAndApplyForVerificationGroup from './steps/selectAndApplyForVerificationGroup';
import './style.scss';

const NO_EMAIL_FOUND = 0;
const SELECT_VERIFICATION_GROUP = 1;

/**
 * This as a whole controls the verification flow
 * This flow has three steps:
 * 1) first step is called no email found (:D no better name): This comes when some one logs in and there are no profile matching the entered email,
 *    this will only come in case of Auth0 login, as when you login with django you are bound to have profile. Now on this step, user will have 2
 *    options, either they can try another email which will logout user from current user and bring back to same page or you have an option to get the email
 *    verified. On Click of which user will move to second step, user will
 * 2) This step will list all the verification groups of and user will have to select one and will have to fill the joining form and apply for verification
 * 3) This is just a feedback step, and will allow user to apply for another verification
 */
function ApplyForVerification({
  groups,
  user,
  isAuthenticated,
  isSessionAuthenticated,
  closeModal,
  isNewUser,
  logout,
  mask,
  collegeEmail,
  collegeName = '',
  ...props
}) {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [currentStep, setCurrentStep] = useState(NO_EMAIL_FOUND);
  const [group, setGroup] = useState(null);

  /**
   * Figures out the step of verification process
   * @param {*} step
   */
  function renderBody(step) {
    switch (step) {
      case NO_EMAIL_FOUND:
        return (
          <NoEmailFound
            user={user}
            isNewUser={isNewUser}
            next={() => setCurrentStep(SELECT_VERIFICATION_GROUP)}
            closeModal={closeModal}
            logout={logout}
            isAuthenticated={isAuthenticated}
            isSessionAuthenticated={isSessionAuthenticated}
          />
        );
      case SELECT_VERIFICATION_GROUP:
        return (
          <SelectAndApplyForVerificationGroup
            isSessionAuthenticated={isSessionAuthenticated}
            isAuthenticated={isAuthenticated}
            groups={groups}
            user={user}
            back={() => setCurrentStep(NO_EMAIL_FOUND)}
            setGroup={(grp) => setGroup(grp)}
            collegeEmail={collegeEmail}
            collegeName={collegeName}
            closeModal={closeModal}
          />
        );
      default:
        return (
          <NoEmailFound user={user} closeModal={closeModal} next={() => setCurrentStep(SELECT_VERIFICATION_GROUP)} />
        );
    }
  }

  return (
    /* Not using themex modal as need to overwrite lot of styles */
    <Modal
      title=""
      footer={null}
      visible={isModalVisible}
      wrapClassName="apply-verification-modal"
      onCancel={closeModal}
      mask={mask}
      closable={false}
      maskClosable={false}
      {...props}>
      {renderBody(currentStep)}
    </Modal>
  );
}

export default ApplyForVerification;
