/* eslint-disable max-nested-callbacks */
/* eslint-disable max-statements */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { message } from 'antd';
import postal from 'postal';
import { isEmpty, findIndex } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import useWindowDimensions from 'Src/common/hooks/useWindowDimension';
import SubscriptionsComponent from 'Src/spaces/components/subscriptions';
import VerificationContext from 'Src/common/context/verification';
import AssetManagerHelper from 'Src/common/utilities/asset_manager';
import useSessionAuth from 'Src/common/hooks/useSessionAuth';
import {
  fetchSpaces,
  getSpaceSettings,
  updateSpaceDigestSettings,
  isUserVerified,
  unsubscribeSpace,
} from 'Src/spaces/actions';
import { IMAGE_VARIANT } from 'Src/spaces/constants';
import useDebounce from 'Src/common/hooks/useDebounce';
import transformSpace from 'Src/spaces/adapters/spaceCard';
import history from 'Src/common/utilities/history';
import * as ENDPOINTS from 'Src/spaces/endpoints';
import { MEMBER_STATUSES } from 'Src/spaces/constants';

/**
 * Subscription container
 */
function Subscription() {
  const [spaces, setSpaces] = useState([]);
  const [cards, setCards] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSpaceFetched, setIsSpaceFetched] = useState(false);
  const [digestFrequency, setDigestFrequency] = useState('weekly');
  const debouncedQuery = useDebounce(searchText, 500);
  const { width } = useWindowDimensions();
  const { isAuthenticated } = useAuth0();
  const { isSessionAuthenticated } = useSessionAuth();
  const { pendingVerifications, update } = useContext(VerificationContext);
  const cardsRef = useRef(null);
  const spacesRef = useRef(null);
  cardsRef.current = cards;
  spacesRef.current = spaces;

  /**
   * Handles menu item click event
   */
  function navigateTo(navigationUrl) {
    history.push(navigationUrl);
  }

  /**
   * Handle enter key press event
   */
  function HandlePressEnter(event, navigationUrl) {
    if (event.key === 'Enter') {
      navigateTo(navigationUrl);
    }
  }

  /**
   * Get space cover image
   */
  async function getCoverImage(space) {
    if (isEmpty(space.banner_image_asset)) {
      return `${staticPath}img/spaces/space_default_banner_image.jpeg`;
    }
    const assetManagerHelper = new AssetManagerHelper(space.banner_image_asset.id);
    const variants = await assetManagerHelper.getVariants(IMAGE_VARIANT.medium);
    return variants.url;
  }

  function updateSpace(updatedSpace) {
    const index = findIndex(spacesRef.current, (val) => val.id === updatedSpace.id);
    if (index > -1) {
      spacesRef.current[index].status = updatedSpace.status;
      spacesRef.current[index].count_subscribers = updatedSpace.count_subscribers;
      const cardIndex = findIndex(cardsRef.current, (val) => val.id === updatedSpace.id);
      if (cardIndex > -1) {
        cardsRef.current.splice(cardIndex, 1);
        setCards(cardsRef.current.slice());
      }
    }
  }

  function onAction(e, space) {
    e.stopPropagation();
    e.preventDefault();
    if (space.status === MEMBER_STATUSES.SUBSCRIBED) {
      unsubscribeSpace(space.id).then((res) => {
        updateSpace({
          id: space.id,
          status: MEMBER_STATUSES.UNSUBSCRIBED,
          count_subscribers: space.count_subscribers - 1,
        });
        postal.publish({
          channel: 'spaces',
          topic: 'space.unsubscribed',
        });
        message.success(`Successfully unsubscribed from ${space.name}`);
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    setIsSpaceFetched(false);
    setSpaces([]);
    setCards([]);
    fetchSpaces({
      fields:
        'id,name,slug,latest_post_at,banner_image_asset,is_verified,count_subscribers,groups,permissions,space_url,status',
      extraQueryParams: 'user_status=subscribed',
    }).then((res) => {
      setSpaces(res.data.results);
      setIsSpaceFetched(true);
      res.data.results.forEach(async (space) => {
        space.banner_image_asset_url = await getCoverImage(space);
        const formattedCard = transformSpace(space, onAction);
        setCards((prevCards) => [...prevCards, formattedCard]);
      });
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated || isSessionAuthenticated) {
      getSpaceSettings().then((res) => {
        setIsSubscribed(res.data.is_subscribed_to_digest);
        setDigestFrequency(res.data.digest_frequency);
      });
    }
  }, [isAuthenticated, isSessionAuthenticated]);

  useEffect(() => {
    if (isSpaceFetched && cards.length === spaces.length) {
      setLoading(false);
    }
  }, [cards, spaces, isSpaceFetched]);

  useEffect(() => {
    const filteredSpaces = spaces.filter((val) => val.name.toLowerCase().includes(debouncedQuery.toLowerCase()));
    const newCards = [];
    filteredSpaces.forEach((space) => {
      newCards.push(transformSpace(space));
    });
    setCards(newCards.slice());
  }, [debouncedQuery]);

  /**
   * Handles subscription checkbox change event
   */
  function handleSubscribeChange() {
    updateSpaceDigestSettings(!isSubscribed);
    setIsSubscribed(!isSubscribed);
  }

  /**
   * Updated context and redirects to given url
   */
  function updateContextAndNavigate(space) {
    isUserVerified(ENDPOINTS.SPACE_DETAILS_VFX_PATH.replace(':spaceSlug', space.slug)).then((res) => {
      update({
        isVerifying: false,
        isVerified: res.data.is_verified,
        isNewUser: res.data.is_new_user,
        pendingVerifications,
        update,
        id: res.data.id,
        space_id: res.data.space_id,
        channel_id: res.data.channel_id,
        post_id: res.data.post_id,
        groups: res.data.groups,
      });
      navigateTo(new URL(space.space_url).pathname, space.id);
    });
  }

  return (
    <SubscriptionsComponent
      loading={loading}
      spaces={spaces}
      cards={cards}
      width={width}
      isSubscribed={isSubscribed}
      searchText={searchText}
      digestFrequency={digestFrequency}
      setSearchText={setSearchText}
      HandlePressEnter={HandlePressEnter}
      updateContextAndNavigate={updateContextAndNavigate}
      handleSubscribeChange={handleSubscribeChange}
    />
  );
}

export default Subscription;
