import React, { useEffect } from 'react';
import WaitingScreen from 'Src/common/components/waitingScreen';
import queryString from 'query-string';

/**
  * This is a genetic component for auth0 logout
  * Auth0 does not support any random logout url
  * So we have to create a generic url for logout
  * We have 2 ways to do it, either we create common logout url like /auth0/logout or we create
  * feature specific urls like /f/auth0/logout, d/auth0/logout, g/auth0/logout
  * We are going with feature specific urls like f/auth0/logout, so on logout request will come to specific module only
  * To use this view, add just route to your apps router and point logout route to this component
*/
function Auth0LogoutOverlay() {
  useEffect(() => {
    /* So when we logout from auth0 , we show this component as intermediate step and after that we need to
     * move back to original page, so while logging out from original page, we will pass returnTo attribute
     * which will contain the url where we need to return to from here
    */
    const queryS = queryString.parse(window.location.search);
    if (queryS.returnTo) {
      window.location.href = queryS.returnTo;
    }
  }, []);

  return (
    <WaitingScreen text="Logging out" />
  );
}

export default Auth0LogoutOverlay;
