import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { Heading } from 'arcl';
import { MEMBER_STATUSES } from 'Src/spaces/constants';

function getActionText(space) {
  if (space.status === MEMBER_STATUSES.SUBSCRIBED) {
    return 'UNSUBSCRIBE';
  } else if ([MEMBER_STATUSES.UNSUBSCRIBED, MEMBER_STATUSES.APPROVED, MEMBER_STATUSES.AUTO].includes(space.status)) {
    return 'SUBSCRIBE';
  } else {
    return (
      <div className="arc-d-flex arc-flex-v-align-middle">
        <FontAwesomeIcon icon={faLock} />
        <p className="ml8 arc-color-primary">SUBSCRIBE</p>
      </div>
    );
  }
}

const transform = (space, onAction) => {
  return {
    ...space,
    id: space.id,
    header: (
      <Heading variant="h4" color={window.primaryColor} style={{ fontWeight: 500 }}>
        {space.name}
      </Heading>
    ),
    bannerImage: space.banner_image_asset_url,
    subHeader: space.latest_post_at
      ? `Latest Post : ${moment(space.latest_post_at).fromNow()}`
      : 'Be the first to post',
    metadata: {
      isLocked: !space?.permissions?.can_read_feed,
      followers: space?.count_subscribers,
      color: window.primaryColor,
    },
    tags: space.groups ? space.groups.map((val) => val.name) : [],
    actionText: (
      <a className="arc-H100 arc-color-primary" href="javascript:void(0)" onClick={(e) => onAction(e, space)}>
        {getActionText(space)}
      </a>
    ),
  };
};

export default transform;
