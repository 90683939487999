/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import './style.scss';

/**
 * Component to render first step of verification flow when email is not found
 * @param {*} param0
 */
function NoEmailFound({ user, next, logout, isNewUser, isAuthenticated, isSessionAuthenticated, closeModal }) {
  return (
    <div className="no-email-found-wrapper">
      <div>
        <Button type="link" className="link-btn" onClick={closeModal}>
          <FontAwesomeIcon icon={faArrowLeft} className="mr16 arc-color-primary" />
        </Button>
        <div className="email-wrapper">{user.email}</div>
      </div>
      <img src={window.customerLogo} className="customer-logo" width="50px" alt={`${window.collegeName} logo`} />
      <Choose>
        <When condition={isNewUser}>
          <p className="arc-H200 arc-color-black mt8">
            <I18nCustomFormatter id="new-user-greeting" />
          </p>
          <p className="arc-p arc-color-B65 mt8">
            <I18nCustomFormatter id="no-email-found-title" values={{ email: user.email }} />
          </p>
          <p className="arc-p arc-color-B65 mt8">
            <I18nCustomFormatter id="no-email-found-note" />
          </p>
        </When>
        <Otherwise>
          <p className="arc-H200 arc-color-black mt8">
            <I18nCustomFormatter id="additional-verification-required-label" />
          </p>
          <p className="arc-p arc-color-B65 mt8">
            <I18nCustomFormatter id="welcome-back-label" values={{ name: user.name }} />
          </p>
          <p className="arc-p arc-color-B65 mt8">
            <I18nCustomFormatter id="additional-verification-required-note" values={{ email: user.email }} />
          </p>
          <p className="arc-p arc-color-B65 mt8">
            <I18nCustomFormatter id="additional-verification-try-again" />
          </p>
        </Otherwise>
      </Choose>
      <div className="action-block arc-card-box-shadow" onClick={next}>
        <a
          href="javascript:void(0)"
          className="arc-d-flex arc-flex-justify-space-between arc-flex-vertical-align arc-H200 arc-p arc-color-primary arc-font-weight-400">
          <I18nCustomFormatter id="get-email-verified-title" />
          <FontAwesomeIcon icon={faArrowRight} />
        </a>
        <p className="arc-p arc-color-B55 mt8">
          <I18nCustomFormatter id="get-email-verified-description" />
        </p>
      </div>
      <If condition={isAuthenticated && !isSessionAuthenticated}>
        <div className="action-block arc-card-box-shadow" onClick={logout}>
          <a
            href="javascript:void(0)"
            className="arc-d-flex arc-flex-justify-space-between arc-flex-vertical-align arc-H200 arc-p arc-color-primary arc-font-weight-400">
            <I18nCustomFormatter id="go-back-title" />
            <FontAwesomeIcon icon={faArrowRight} />
          </a>
          <p className="arc-p arc-color-B65 mt8">
            <I18nCustomFormatter id="go-back-description" />
          </p>
        </div>
      </If>
      <Tooltip
        placement="topLeft"
        title={
          <p>
            Verifications are badges that are awarded to you based on your role or affiliation with {window.collegeName}
            . <br />
            <br />
            Your verification badges help you access private and secured content on this platform.
          </p>
        }>
        <p className="arc-color-primary mt20">Learn more about verifications</p>
      </Tooltip>
    </div>
  );
}

export default NoEmailFound;
