import React from 'react';
import { arrayOf, shape, bool, func } from 'prop-types';
import { Feed, Post } from 'arcl';
import { animated } from 'react-spring';
import CreatePost from 'Src/spaces/components/createPost';
import EmptyState from 'Src/spaces/components/emptyState';
import WaitingScreen from 'Src/common/components/waitingScreen';
import { Trail, NewPostTrail, opacityAnimation } from 'Src/spaces/animations';
import { Touchpoint } from './touchpoint'
import './style.scss';
import { isEmpty } from 'lodash';

/**
 * Feeds Component
 */
function FeedComponent({
  posts,
  spaces,
  hasMore,
  loading,
  newPost,
  handleViewMore,
  isUserAuthenticated,
  handleCreatePost,
  handleMenuClick,
  loadingMorePosts,
}) {
  return (
    <Choose>
      <When condition={loading}>
        <div className="loading-container">
          <WaitingScreen showIcon={false} />
        </div>
      </When>
      <Otherwise>
        <Touchpoint />
        <div className="feed-container">
          <div className="feed-wrapper">
            <If condition={isUserAuthenticated && !isEmpty(spaces)}>
              <animated.div className="subscription-wrapper-header" style={opacityAnimation(300)}>
                <div className="search-wrapper">
                  <CreatePost handleCreatePost={handleCreatePost} />
                </div>
              </animated.div>
            </If>
            <div className="posts-container">
              <NewPostTrail reverse={false}>
                <If condition={newPost}>
                  <Post {...newPost} handleMenuClick={handleMenuClick} style={{ marginBottom: '20px' }} />
                </If>
              </NewPostTrail>
              <Trail reverse={false}>
                <Choose>
                  <When condition={posts.length > 0 || newPost}>
                    <Feed
                      data={{ posts, handleMenuClick }}
                      showViewMore={hasMore}
                      handleViewMore={handleViewMore}
                      isViewMoreLoading={loadingMorePosts}
                    />
                  </When>
                  <Otherwise>
                    <EmptyState
                      heading="No posts here"
                      redirectLink="/s/explore/"
                      buttonText="Explore Spaces"
                      illustrationPath="img/spaces/empty-feed.svg"
                      description="You can explore other spaces while something cooks up here"
                    />
                  </Otherwise>
                </Choose>
              </Trail>
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
}

FeedComponent.propTypes = {
  posts: arrayOf(shape()).isRequired,
  spaces: arrayOf(shape()).isRequired,
  hasMore: bool.isRequired,
  loading: bool.isRequired,
  newPost: shape().isRequired,
  handleViewMore: func.isRequired,
  isUserAuthenticated: bool.isRequired,
  handleCreatePost: func.isRequired,
  handleMenuClick: func.isRequired,
  loadingMorePosts: bool.isRequired,
};

export default FeedComponent;
