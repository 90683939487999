function getMenuItems(input) {
  const menuItems = [];
  if (input?.permissions?.can_flag_comment) {
    menuItems.push({ title: 'Report', key: 'report_abuse' });
  }
  if (input?.permissions?.can_delete_comment) {
    menuItems.push({ title: 'Delete', key: 'delete' });
  }
  return menuItems;
}

const transform = (input) => {
  return {
    ...input,
    id: input.id,
    author: {
      avatarConfig: {
        size: 'default',
        url: input.created_by?.present_picture_url,
      },
      title: `${input.created_by?.first_name} ${input.created_by?.last_name}`,
      subtitle: input.created_by?.groups.map((val) => val.name).join(', '),
      color: window.primaryColor,
    },
    description: input.text,
    menuItems: getMenuItems(input),
    profileUrl: input.created_by?.profile_url,
  };
};

export default transform;
