import React, { useState } from 'react';
import { arrayOf, func, shape, bool, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHashtag, faBellOn, faBellSlash } from '@fortawesome/pro-light-svg-icons';
import ChannelSettings from 'Src/spaces/components/channel/settings';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

import './style.scss';

/**
 * The channel component
 */
function Channels({
  channels,
  spaceId,
  handleChannelCreate,
  handleChannelSorting,
  canEdit,
  handleChannelClick,
  handleChannelSubscribe,
  handleChannelDelete,
  channelId,
  setSelectedDirectory,
  space,
}) {
  const [isChannelSettingsModalVisible, setIsChannelSettingsModalVisible] = useState(false);

  /**
   * toggle channel settings
   */
  function toggleChannelSettingsModal() {
    setIsChannelSettingsModalVisible((val) => !val);
  }

  /**
   * Handle enter key press
   */
  function handleEnterClick(event, func) {
    if (event.key === 'Enter') {
      func();
    }
  }

  function handleChannelSelection(channel, spaceId) {
    const element = document.getElementsByClassName('main-content-container')[0];
    element.scroll({ top: 0, behavior: 'smooth' });
    setSelectedDirectory({});
    handleChannelClick(channel, spaceId);
  }

  return (
    <div className="channel-wrapper">
      <div className="arc-d-flex arc-flex-v-align-middle mb12">
        <p className="arc-H100 mr8">TOPICS</p>
        <If condition={canEdit}>
          <FontAwesomeIcon
            className="arc-cursor-p"
            icon={faCog}
            tabIndex={0}
            onClick={toggleChannelSettingsModal}
            onKeyDown={(e) => handleEnterClick(e, toggleChannelSettingsModal)} />
        </If>
      </div>
      <div
        className={`channel-item fixed arc-cursor-p ${!channelId && 'selected'}`}
        tabIndex={0}
        role="button"
        onClick={handleChannelSelection}
        onKeyDown={(e) => handleEnterClick(e, handleChannelSelection)}>
        <FontAwesomeIcon icon={faHashtag} className="arc-color-primary mr8" />
        <span className="arc-color-black arc-p">All posts</span>
      </div>
      {channels.map((channel) => (
        <div className={`channel-item mt16 ${channelId == channel.id ? 'selected' : ''}`}>
          <div
            className="arc-cursor-p"
            tabIndex={0}
            role="button"
            onClick={() => handleChannelSelection(channel)}
            onKeyDown={(e) => handleEnterClick(e, () => handleChannelSelection(channel, spaceId))}>
            <FontAwesomeIcon icon={faHashtag} className="arc-color-primary mr8" />
            <span className="arc-color-black arc-p">{channel.name}</span>
          </div>
          <If condition={!(channel?.permissions?.can_subscribe && channel?.permissions?.can_unsubscribe)}>
            <If condition={channel?.permissions?.can_subscribe || channel?.permissions?.can_unsubscribe}>
              <FontAwesomeIcon
                tabIndex={0}
                role="button"
                onClick={() => handleChannelSubscribe(channel.id, channel?.permissions?.can_unsubscribe)}
                onKeyDown={(e) => {
                  handleEnterClick(e, () => handleChannelSubscribe(channel.id, channel?.permissions?.can_unsubscribe));
                }}
                icon={channel?.permissions?.can_unsubscribe ? faBellOn : faBellSlash}
                className={`arc-color-${channel?.permissions?.can_unsubscribe ? 'primary' : 'B55'} arc-cursor-p`} />
            </If>
          </If>
        </div>
      ))}
      {/* <If condition={canEdit}>
        <p
          className="channel-settings arc-color-primary mt16 arc-cursor-p"
          tabIndex={0}
          onClick={toggleChannelSettingsModal}
          onKeyDown={(e) => handleEnterClick(e, toggleChannelSettingsModal)}>
          <FontAwesomeIcon icon={faCog} className="mr8" />
          <span className="arc-p">
            <I18nCustomFormatter id="channel-settings-label" />
          </span>
        </p>
      </If> */}
      {isChannelSettingsModalVisible && (
        <ChannelSettings
          space={space}
          channels={channels}
          onClose={toggleChannelSettingsModal}
          handleChannelCreate={handleChannelCreate}
          handleChannelSorting={handleChannelSorting}
          handleChannelDelete={handleChannelDelete} />
      )}
    </div>
  );
}

Channels.propTypes = {
  channels: arrayOf(shape()),
  spaceId: string,
  handleChannelCreate: func.isRequired,
  handleChannelSorting: func.isRequired,
  handleChannelClick: func.isRequired,
  handleChannelSubscribe: func.isRequired,
  handleChannelDelete: func.isRequired,
  canEdit: bool,
  channelId: string,
};

Channels.defaultProps = {
  channels: [],
  canEdit: false,
};

export default Channels;
