/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useContext } from 'react';
import { matchPath } from 'react-router';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import useSessionAuth from 'Src/common/hooks/useSessionAuth';
import Header from 'Src/spaces/components/header';
import VerificationContext from 'Src/common/context/verification';
import useWindowDimensions from 'Src/common/hooks/useWindowDimension';
import WaitingScreen from 'Src/common/components/waitingScreen';
import { ROUTES_WITHOUT_VFX } from 'Src/spaces/constants';
import * as Routes from 'Src/spaces/routes';
import history from 'Src/common/utilities/history';
import { getUserProfile } from 'Src/spaces/actions';
import UserProfileContext from 'Src/spaces/context/user';
import HybridAuthContext from 'Src/spaces/context/auth';
import NavigationDrawer from './navigationDrawer';

const SpacesWrapper = ({ component: Component, isPublic, verificationGroups, routeName, ...rest }) => {
  const { isVerified } = useContext(VerificationContext);
  const { isAuthenticated, isLoading } = useAuth0();
  const { isSessionAuthenticated } = useSessionAuth();
  const [userProfile, setUserProfile] = useState({ groups: [] });
  const pathsWithBannerImage = [Routes.EXPLORE_PATH, Routes.SUBSCRIPTION_PATH, Routes.FEEDS_PATH];
  const pathToHeaderMap = {
    [Routes.EXPLORE_PATH]: 'Explore',
    [Routes.SUBSCRIPTION_PATH]: 'My Subscriptions',
  };
  const { width } = useWindowDimensions();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [header, setHeader] = useState('');
  const [isNewEmail, setIsNewEmail] = useState(false);
  const [selectedPathname, setSelectedPathname] = useState(history?.location?.pathname);
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const fadeInProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  function isExplorePath(path) {
    return matchPath(path, {
      path: Routes.EXPLORE_PATH,
      exact: true,
      strict: false,
    });
  }

  function isFeedPath(path) {
    return matchPath(path, {
      path: Routes.FEEDS_PATH,
      exact: true,
      strict: false,
    });
  }

  history.listen((location, action) => {
    setSelectedPathname(location.pathname);
    if (pathsWithBannerImage.includes(getPathNameWithSlash(location.pathname))) {
      setIsHeaderVisible(true);
      setHeader(pathToHeaderMap[location.pathname]);
    } else {
      setIsHeaderVisible(false);
      setHeader('');
    }
  });

  function getPathNameWithSlash(pathname) {
    if (pathname) {
      if (pathname[pathname.length - 1] !== '/') {
        return pathname + '/';
      }
      return pathname;
    }
    return '';
  }

  useEffect(() => {
    const { pathname } = history.location;
    if (pathsWithBannerImage.includes(getPathNameWithSlash(pathname))) {
      setIsHeaderVisible(true);
      setHeader(pathToHeaderMap[pathname]);
    } else {
      setIsHeaderVisible(false);
      setHeader('');
    }
  }, []);

  useEffect(() => {
    if (!isLoading && (isAuthenticated || isSessionAuthenticated)) {
      setFetchingProfile(true);
      getUserProfile()
        .then((res) => {
          setUserProfile(res.data);
        })
        .catch(() => {
          setIsNewEmail(true);
          if (ROUTES_WITHOUT_VFX.includes(history?.location?.pathname) && !isExplorePath(history?.location?.pathname)) {
            history.push(Routes.EXPLORE_PATH);
          }
        })
        .finally(() => {
          setFetchingProfile(false);
        });
    } else if (!isLoading && !isAuthenticated && !isSessionAuthenticated) {
      if (
        ROUTES_WITHOUT_VFX.includes(getPathNameWithSlash(history?.location?.pathname)) &&
        !isFeedPath(history?.location?.pathname)
      ) {
        history.push(Routes.FEEDS_PATH);
      }
    }
  }, [isLoading, isAuthenticated, isSessionAuthenticated]);

  if (fetchingProfile) {
    return <WaitingScreen text="Fetching information" />;
  }

  return (
    <HybridAuthContext.Provider value={{ isUserAuthenticated: isSessionAuthenticated || isAuthenticated }}>
      <UserProfileContext.Provider value={{ userProfile, isNewEmail }}>
        <animated.div style={fadeInProps} className="page-container">
          <If condition={width <= 1024}>
            <div className="spaces-header">
              <FontAwesomeIcon
                icon={faBars}
                className="arc-color-black"
                size="lg"
                onClick={() => setIsDrawerVisible((val) => !val)}
              />
              <img src={window.customerLogo} alt="College Logo" className="ml32" />
            </div>
          </If>
          <div className="spaces-container">
            <NavigationDrawer
              isVisible={isDrawerVisible}
              toggleDrawer={() => setIsDrawerVisible(!isDrawerVisible)}
              pathname={selectedPathname}
              width={width}
            />
            <div className="main-content-container">
              <animated.div style={fadeInProps} className="main-content-wrapper">
                <If condition={isHeaderVisible}>
                  <Header />
                </If>
                <Component verificationGroups={verificationGroups} {...rest} />
              </animated.div>
            </div>
          </div>
        </animated.div>
      </UserProfileContext.Provider>
    </HybridAuthContext.Provider>
  );
};

export default Sentry.withProfiler(SpacesWrapper);
