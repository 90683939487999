import React from 'react';
import { Button, Modal, Form, Input, Popconfirm, Select } from 'antd';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash } from '@fortawesome/pro-light-svg-icons';
import { RESOURCE_TYPES } from 'Src/spaces/constants';

import './add.scss';

const { Option } = Select;

/**
 * Add resource component
 */
function AddResource({
  resource = {},
  closeModal,
  addResource,
  removeResource,
  directoriesList,
  resourceType,
  canListDirectories,
  handleResourceTypeChange,
}) {
  const [form] = Form.useForm();

  /**
   * Handle resource type change
   */
  function onResourceChange(value) {
    handleResourceTypeChange(value);
    // Empty value field when resource type changes.
    form.setFieldsValue({ ['value']: '' });
  }

  return (
    <Modal
      title={
        <div className="arc-d-flex arc-flex-v-align-middle arc-flex-space-between-align">
          <span>
            <FontAwesomeIcon icon={faArrowLeft} className="arc-color-primary mr16 arc-cursor-p" onClick={closeModal} />
            <span className="arc-H150">{isEmpty(resource) ? 'New Resource' : resource.name}</span>
          </span>
          <If condition={!isEmpty(resource)}>
            <Popconfirm
              title="Are you sure you want to delete this resource?"
              onConfirm={() => removeResource(resource)}>
              <FontAwesomeIcon icon={faTrash} className="arc-color-primary arc-cursor-p" />
            </Popconfirm>
          </If>
        </div>
      }
      footer={[
        <Button type="primary" key="submit" onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
      visible
      closable={false}
      onCancel={closeModal}
      wrapClassName="resource-add-modal">
      <Form form={form} layout="vertical" initialValues={resource} onFinish={addResource}>
        <Form.Item name="id" label="Id" hidden>
          <Input type="text" size="large" hidden />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please enter resource name',
            },
            {
              max: true,
              message: 'Name can have maximum 255 characters',
            },
          ]}>
          <Input type="text" size="large" />
        </Form.Item>

        <Form.Item
          name="type"
          label="Select resource type"
          rules={[
            {
              required: true,
              message: 'Please select a resource type',
            },
          ]}>
          <Select onChange={onResourceChange}>
            <Option value={RESOURCE_TYPES.LINK}>Link</Option>
            <If condition={canListDirectories}>
              <Option value={RESOURCE_TYPES.DIRECTORY}>Directory</Option>
            </If>
          </Select>
        </Form.Item>

        <If condition={resourceType === RESOURCE_TYPES.DIRECTORY}>
          <Form.Item
            name="value"
            label="Select directory"
            rules={[
              {
                required: true,
                message: 'Please select a directory',
              },
            ]}>
            <Select>
              {directoriesList.map((directory) => (
                <Option value={directory.id}>{directory.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </If>

        <If condition={resourceType === RESOURCE_TYPES.LINK}>
          <Form.Item
            name="value"
            label="Resource"
            rules={[
              {
                required: true,
                message: 'Please enter resource URL',
              },
              {
                type: 'url',
                message: 'Please enter valid URL',
              },
              {
                max: true,
                message: 'Url can have maximum 255 characters',
              },
            ]}>
            <Input type="url" size="large" />
          </Form.Item>
        </If>
      </Form>
    </Modal>
  );
}

export default AddResource;
