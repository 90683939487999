export const BASE_PATH = '/s';
export const FEEDS_PATH = `${BASE_PATH}/feed/`;
export const SPACE_FEEDS_PATH = `${BASE_PATH}/:slug/feed/`;
export const POST_PATH = '/sp/:postId/';
export const EXPLORE_PATH = `${BASE_PATH}/explore/`;
export const SPACE_DETAILS = `${BASE_PATH}/:spaceSlug/`;
export const SUBSCRIPTION_PATH = `${BASE_PATH}/subscriptions/`;
export const CHANNEL_FEED = `${BASE_PATH}/:spaceSlug/c/:channelSlug`;
export const SPACES_AUTH0_LOGOUT = `${BASE_PATH}/auth0/logout`;
export const SPACES_AUTH0_CALLBACK = `${BASE_PATH}/auth0/callback/`;
