import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Header component for verification application step
 * @param {*} param0 
 */
function Header({ icon, title, verificationGroupName, }) {
  return (
    <div className="verification-header">
      <div className="verification-icon arc-d-inline-block arc-v-align-middle">
        <FontAwesomeIcon icon={icon} className="arc-color-primary verification-form-icon mr8" />
      </div>
      <div className="verification-title arc-d-inline-block arc-v-align-middle">
        <p className="arc-H100 arc-color-black arc-font-weight-700">{title}</p>
        <p className="arc-H200 arc-color-black arc-font-weight-400">{verificationGroupName}</p>
      </div>
    </div>
  )
}

export default Header;
