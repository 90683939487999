import React, { useEffect, useState } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import history from 'Src/common/utilities/history';
import * as ROUTES from 'Src/spaces/routes';
import { ROUTES_NAME } from 'Src/spaces/constants';
import Feed from 'Src/spaces/containers/Feed';
import Explore from 'Src/spaces/containers/Explore';
import PostDetails from 'Src/spaces/containers/Post';
import SpaceDetails from 'Src/spaces/containers/SpaceDetails';
import Subscriptions from 'Src/spaces/containers/Subscriptions';
import AuthHelper from 'Src/common/factories/auth';
import Auth0LogoutOverlay from 'Src/common/components/authentication/logoutOverlay';
import WaitingScreen from 'Src/common/components/waitingScreen';
import { initSentry } from 'Src/common/utilities/sentry_util';
import VerificationWrapper from './verificationWrapper';

import './style.scss';

const APP_REFRESH_TIME = 299;

// This will init sentry and log errors for the
// This will only log errors for production environment
if (window.environment === 'prod') {
  initSentry();
}

/**
 * Routes component
 */
function Routes() {
  const { getIdTokenClaims, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const alreadyLoggedIn = localStorage.getItem('isAuthenticated');
    if (isAuthenticated && !isLoading && alreadyLoggedIn === 'false') {
      window.location.href = `/select-account/?next=${window.location.pathname + window.location.search}`;
    }
  }, [isAuthenticated, isLoading, AuthHelper.token()]);

  useEffect(() => {
    setLoading(true);
    let idTokenInterval = null;
    const alreadyLoggedIn = localStorage.getItem('isAuthenticated');
    if (!isLoading) {
      const setToken = async () => {
        await getAccessTokenSilently();
        const claims = await getIdTokenClaims();
        if (claims) {
          AuthHelper.set(claims.__raw);
        }
        if (loading && alreadyLoggedIn !== 'false') {
          setLoading(false);
        }
      };
      if (isAuthenticated) {
        setToken();
        idTokenInterval = setInterval(() => {
          setToken();
        }, APP_REFRESH_TIME);
        const targetUrl = sessionStorage.getItem('targetUrl');
        if (targetUrl && alreadyLoggedIn !== 'true') {
          history.push(targetUrl);
        }
      } else {
        setLoading(false);
      }
    }
    return () => {
      if (idTokenInterval) {
        clearInterval(idTokenInterval);
      }
    };
  }, [isLoading, isAuthenticated]);

  if (loading) {
    return <WaitingScreen text="Checking Verifications" />;
  }

  return (
    <Router history={history}>
      <Switch>
        <Route path={ROUTES.BASE_PATH} render={() => <Redirect to={ROUTES.FEEDS_PATH} />} exact />
        <Route
          exact
          path={`${ROUTES.SPACES_AUTH0_CALLBACK}`}
          render={() => <WaitingScreen text="Checking Verifications" />}
          isPublic
        />
        <Route
          path={ROUTES.EXPLORE_PATH}
          render={(props) => (
            <VerificationWrapper component={Explore} props={props} routeName={ROUTES_NAME.EXPLORE} isPublic />
          )}
          exact
        />
        <Route
          path={ROUTES.FEEDS_PATH}
          render={(props) => (
            <VerificationWrapper component={Feed} props={props} routeName={ROUTES_NAME.FEED} isPublic />
          )}
          exact
        />
        <Route
          path={ROUTES.POST_PATH}
          render={(props) => (
            <VerificationWrapper component={PostDetails} props={props} routeName={ROUTES_NAME.POST_DETAILS} />
          )}
          exact
        />
        <Route
          path={ROUTES.SUBSCRIPTION_PATH}
          render={(props) => (
            <VerificationWrapper
              component={Subscriptions}
              props={props}
              routeName={ROUTES_NAME.SUBSCRIPTION}
              isPublic
            />
          )}
          exact
        />
        <Route
          path={ROUTES.SPACE_DETAILS}
          render={(props) => (
            <VerificationWrapper component={SpaceDetails} props={props} routeName={ROUTES_NAME.SPACE_DETAILS} />
          )}
          exact
        />
        <Route
          path={ROUTES.CHANNEL_FEED}
          render={(props) => (
            <VerificationWrapper component={SpaceDetails} props={props} routeName={ROUTES_NAME.CHANNEL_FEED} />
          )}
          exact
        />
        <Route path={ROUTES.SPACES_AUTH0_LOGOUT} component={Auth0LogoutOverlay} exact />
      </Switch>
    </Router>
  );
}

export default Routes;
