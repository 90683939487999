export const ADMIN_SPACES_BASE_PATH = '/spaces/';
export const SPACES_BASE_PATH = '/spaces/';
export const SPACE_DETAILS_VFX_PATH = '/spaces/:spaceSlug/verify';
export const CHANEL_FEED_VFX_PATH = '/spaces/:spaceSlug/channels/:channelSlug/verify';
export const SPACE_POST_DETAILS_VFX_PATH = 'spaces/posts/:postId/verify/';
export const SPACE_EXPLORE_VFX_PATH = '/spaces/explore/verify';
export const SPACE_FEED_VFX_PATH = '/spaces/feed/verify';
export const SPACE_SUBSCRIPTIONS_VFX_PATH = '/spaces/subscriptions/verify';
export const POST_REPORT_ABUSE_PATH = '/spaces/posts/:postId/report_abuse/';
export const SPACE_CATEGORIES_API_PATH = '/spaces/categories';
export const DIRECTORIES_API_PATH = '/directories';
export const SPACE_POST_SEND_EMAIL = '/spaces/:spaceId/post/:postId/email';
export const EDIT_POST_PATH = '/spaces/posts/:postId';
export const PIN_POST_PATH = '/spaces/posts/:postId/pin';
export const FOLLOW_POST_PATH = '/spaces/posts/:postId/follow';
export const UNFOLLOW_POST_PATH = '/spaces/posts/:subscriberId/follow';
export const SPACE_SUBSCRIBERS_LIST_PATH = '/spaces/:spaceId/subscribers';
export const SEND_MESSAGE_PATH = '/create-email-new/';
export const LIST_MEMBERS_PATH = '/spaces/{spaceId}/members';
