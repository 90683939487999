import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import * as ROUTES from 'Src/spaces/routes';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

import './style.scss';

/**
 * New email wrapper component
 */
function NewEmailWrapper({ hasSchoolLogo = true, hasExploreButton = true, homepageUrl }) {
  return (
    <div className="new-email-wrapper">
      <If condition={hasSchoolLogo}>
        <Choose>
          <When condition={homepageUrl}>
            <a href={homepageUrl}>
              <img src={window.customerLogo} alt="School Logo" className="mb24" />
            </a>
          </When>
          <Otherwise>
            <Link to={ROUTES.FEEDS_PATH}>
              <img src={window.customerLogo} alt="School Logo" className="arc-cursor-p" />
            </Link>
          </Otherwise>
        </Choose>
      </If>
      <div className="new-email-container">
        <img src={`${window.staticPath}img/spaces/new-email.png`} className="new-email-img arc-cursor-p" />
        <p className="arc-H150 arc-color-black mt16">
          <I18nCustomFormatter id="new-email-header" />
        </p>
        <p className="arc-support arc-color-B55 mt16">
          <I18nCustomFormatter id="new-email-description-line1" />
        </p>
        <p className="arc-support arc-color-B55 mt16">
          <I18nCustomFormatter id="new-email-description-line2" />
        </p>
        <If condition={hasExploreButton}>
          <Link to={ROUTES.EXPLORE_PATH}>
            <Button type="primary" shape="round" className="mt16" className="explore-btn">
              Explore
            </Button>
          </Link>
        </If>
      </div>
    </div>
  );
}

export default NewEmailWrapper;
