import React from 'react';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from 'Src/common/utilities/dom_utils';

import './style.scss';

/**
 * Verification group component which forms list of verification groups
 * @param {*} param0
 */
function VerificationGroupTag({ group }) {
  return (
    <div className="verification-group-tag arc-d-inline-block mr16 mb16" style={{ backgroundColor: `${window.primaryColor}1F` }}>
      <FontAwesomeIcon icon={faShieldCheck} className="group-icon arc-color-primary" />
      <span className="arc-color-black arc-p m8 group-name">{group.name}</span>
    </div>
  );
}

export default VerificationGroupTag;
