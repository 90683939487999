/* eslint-disable max-nested-callbacks */
import { isEmpty, findIndex } from 'lodash';
import { isUserVerified } from 'Src/spaces/actions';
import { ROUTES_NAME } from 'Src/spaces/constants';
import * as ENDPOINTS from 'Src/spaces/endpoints';
import history from 'Src/common/utilities/history';

export const handleRedirect = (input, routeName, verificationData) => {
  let url = '';
  let redirectUrl = '';
  let refreshSource = '';
  if (routeName === ROUTES_NAME.SPACE_DETAILS) {
    url = ENDPOINTS.SPACE_DETAILS_VFX_PATH.replace(':spaceSlug', input.space.slug);
    redirectUrl = input.space.space_url;
    refreshSource = 'space';
  } else if (routeName === ROUTES_NAME.CHANNEL_FEED) {
    url = ENDPOINTS.CHANEL_FEED_VFX_PATH.replace(':spaceSlug', input.space.slug).replace(
      ':channelSlug',
      input.channel.slug,
    );
    redirectUrl = input.channel.channel_url;
    refreshSource = 'channel';
  } else if (routeName === ROUTES_NAME.POST_DETAILS) {
    url = ENDPOINTS.SPACE_POST_DETAILS_VFX_PATH.replace(':postId', input.id);
    redirectUrl = input.post_url;
    refreshSource = 'postDetail';
  }
  isUserVerified(url).then((res) => {
    verificationData.update({
      isVerifying: false,
      isVerified: res.data.is_verified,
      isNewUser: res.data.is_new_user,
      pendingVerifications: verificationData.pendingVerifications,
      update: verificationData.update,
      id: res.data.id,
      space_id: res.data.space_id,
      channel_id: res.data.channel_id,
      post_id: res.data.post_id,
      groups: res.data.groups,
    });
    const { pathname } = new URL(redirectUrl);
    history.push(pathname);

    // Scroll feed to top when inside space details page
    let elementToScroll = document.getElementsByClassName('main-content-container');
    if (!isEmpty(elementToScroll)) {
      elementToScroll[0].scrollTo({ top: 0, behavior: 'smooth' });
    }
  });
};

export const onReaction = (posts, postId, summary) => {
  const index = findIndex(posts, (val) => val.id === postId);
  if (index > -1) {
    const post = posts[index];
    const reactionIndex = findIndex(post.reactionsSummary, (reaction) => reaction.kind === summary.kind);
    let method = '';
    if (reactionIndex > -1 && post.reactionsSummary[reactionIndex].count > -1) {
      if (!post.reactionsSummary[reactionIndex].self) {
        post.reactionsSummary[reactionIndex].count += 1;
        method = 'post';
      } else {
        post.reactionsSummary[reactionIndex].count -= 1;
        method = 'delete';
      }
      post.reactionsSummary[reactionIndex].self = !post.reactionsSummary[reactionIndex].self;
      posts[index] = post;
    }
  }
  return posts;
};
