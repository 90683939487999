import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons';

import './style.scss';

function WaitingScreen({ text, icon = faShieldCheck, showIcon = true }) {
  /*
   * This component creates a full screen overlay of loading screen with given text
   * This is fixed full screen component which takes text and icon as arguments
   */
  return (
    <div className="fullpage-verification-loader">
      <div className="loading-wrapper">
        <If condition={showIcon}>
          <FontAwesomeIcon icon={icon} className="arc-color-primary verification-icon" />
        </If>
        <h3 className="arc-H100 arc-color-B55 verification-label">{text}</h3>
        <div className="three-dot-loader">
          <div className="square-dot dot1"></div>
          <div className="square-dot dot2"></div>
          <div className="square-dot dot3"></div>
        </div>
      </div>
    </div>
  );
}

export default WaitingScreen;
