import React, { useEffect, useState, Children } from 'react';
import VerificationContext from 'Src/common/context/verification';

/**
 * Verification provider provides a flag verification to app which decides whether user is verified to see the content
 */
function VerificationProvider({ children, getVerification }) {
  const [verification, setVerification] = useState({
    isVerified: false,
    isVerifying: true,
    update,
    pendingVerifications: [],
  });

  function update(val) {
    setVerification(val);
  }

  useEffect(() => {
    if (typeof getVerification !== 'undefined') {
      getVerification().then(val => {
        const pendingVerifications = val.groups.filter(group => group.status === 'pending');
        setVerification({
          ...val,
          isVerified: val.is_verified,
          isVerifying: false,
          isNewUser: val.is_new_user,
          pendingVerifications,
          update,
          groups: val.groups,
        });
      });
    } else {
      verification.isVerifying = false;
      verification.update = update;
      setVerification({ ...verification });
    }
  }, []);

  return (
    <VerificationContext.Provider value={verification}>
      {/* This makes sure that there is only 1 child of this provider */}
      {Children.only(children)}
    </VerificationContext.Provider>
  );
}

export default VerificationProvider;
