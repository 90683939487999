import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import {
  bool, element, string, func,
} from 'prop-types';

/**
 * We have a fairly consistent modal headers across the features
 */
function ModalHeader({
  title, subtitle, hasBackArrow, onArrowClick, extra,
}) {
  return (
    <div className="header arc-d-flex arc-flex-v-align-middle arc-flex-justify-space-between">
      <div className="arc-d-flex arc-flex-v-align-middle">
        <If condition={hasBackArrow}>
          <FontAwesomeIcon
            className="arc-color-primary arc-cursor-p mr16"
            onClick={onArrowClick}
            icon={faArrowLeft}
          />
        </If>
        <div className="header-details arc-d-inline-block">
          <p className="arc-H200 arc-color-black">{title}</p>
          <p className="arc-color-B55 arc-text-ellipsis arc-p arc-font-weight-400">{subtitle}</p>
        </div>
      </div>
      <If condition={extra}>
        {extra}
      </If>
    </div>
  );
}

ModalHeader.propTypes = {
  title: string,
  subtitle: string,
  onArrowClick: func,
  hasBackArrow: bool,
  extra: element,
};

ModalHeader.defaultProps = {
  title: '',
  subtitle: '',
  onArrowClick: () => {},
  hasBackArrow: false,
  extra: null,
};

export default ModalHeader;
