import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

const ThemeXSortableList = SortableContainer((props) => {
  const SortableItem = props.sortableItem;
  return (
    <ul className={props.wrapperClassName}>
      {props.items.map((item, index) => (
        <SortableItem key={item.id} index={index} disabled={item.disabled} value={item} {...props} />
      ))}
    </ul>
  );
});

export default ThemeXSortableList;
