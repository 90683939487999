import React, { useEffect, useState } from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { func, arrayOf, shape } from 'prop-types';
import { Modal, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowLeft, faEdit, faPlus } from '@fortawesome/pro-light-svg-icons';
import queryString from 'query-string';

import ThemeXSortableList from 'Src/common/components/themeXSortableList';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import CreateOrUpdateChannel from './createOrUpdate';

import './style.scss';

const DragHandle = SortableHandle(() => (
  <span className="arc-d-inline-block arc-vertical-m">
    <FontAwesomeIcon icon={faBars} className="arc-reorder-icon draggable-item" />
  </span>
));

const SortableItem = SortableElement(({ value, deleteCategory, setChannel }) => (
  <li className="sortable-list-item arc-d-inline-block arc-vertical-m arc-page-bg arc-card-border-radius pt16 pr8 pb16 pl16 mt16 ml0 mr0">
    <DragHandle />
    <span className="arc-p arc-d-inline-block arc-vertical-m arc-text-ellipsis sortable-list-item-title ml16">
      {value.name}
    </span>
    <span>
      <FontAwesomeIcon
        icon={faEdit}
        className="mr16  arc-float-right arc-color-primary mt6 arc-cursor-p"
        onClick={() => {
          setChannel(value);
        }} />
    </span>
  </li>
));

/**
 * Channel settings component
 */
function ChannelSettings({ channels, onClose, handleChannelSorting, handleChannelCreate, handleChannelDelete, space }) {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isCRUDModalVisible, setIsCRUDModalVisible] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({});

  // Opening create channel modal via query params
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);

    if (queryParams.create_topic === 'true') {
      setIsCRUDModalVisible(true);
    }
  }, []);

  /**
   * Handles modal close
   */
  function handleClose() {
    setIsModalVisible(false);
    onClose();
  }

  /**
   * Handles modal save
   */
  function onSave(values) {
    handleChannelCreate(values);
    setIsCRUDModalVisible(false);
  }

  /**
   * Handles modal save
   */
  function onDelete(channel) {
    handleChannelDelete(channel);
    setIsCRUDModalVisible(false);
  }

  /**
   * Sets the selected channel
   */
  function setChannel(value) {
    setSelectedChannel(value);
    setIsCRUDModalVisible(true);
  }

  return (
    <Modal
      title={
        <div className="arc-d-flex arc-flex-justify-space-between arc-flex-v-align-middle">
          <div>
            <FontAwesomeIcon icon={faArrowLeft} className="arc-color-primary mr16 arc-cursor-p" onClick={handleClose} />
            <span className="arc-H200">
              <I18nCustomFormatter id="channel-settings-label" />
            </span>
          </div>
          <div
            className="arc-color-primary arc-cursor-p"
            onClick={() => {
              if (!space?.is_published) {
                notification.error({
                  message: 'Adding topics or posts to unpublished space is not allowed',
                  description:
                    'We do not allow adding topics and posts to unpublished space. Please publish this space to add topics and posts to space.',
                });
              } else {
                setSelectedChannel({});
                setIsCRUDModalVisible(true);
              }
            }}>
            <FontAwesomeIcon icon={faPlus} className="mr8" />
            {' '}
            Add topic
          </div>
        </div>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleClose}
      closable={false}
      wrapClassName="channel-settings-modal">
      <div className="channel-settings-wrapper">
        <div className="sortable-container">
          <ThemeXSortableList
            items={channels}
            sortableItem={SortableItem}
            deleteCategory={() => {}}
            setChannel={setChannel}
            onSortEnd={handleChannelSorting}
            pressDelay={200}
            useDragHandle />
        </div>
      </div>
      <If condition={isCRUDModalVisible}>
        <CreateOrUpdateChannel
          channel={selectedChannel}
          onClose={() => setIsCRUDModalVisible(false)}
          onSave={onSave}
          onDelete={onDelete} />
      </If>
    </Modal>
  );
}

ChannelSettings.defaultProps = {
  channels: [],
};

ChannelSettings.propTypes = {
  channels: arrayOf(shape()),
  onClose: func.isRequired,
  deleteCategory: func.isRequired,
  handleChannelSorting: func.isRequired,
  handleChannelDelete: func.isRequired,
};

export default ChannelSettings;
