/* eslint-disable max-statements */
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Modal, Input, Form, Upload, Button, Popconfirm, Switch, Tooltip, message, Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keys, isEmpty, forEach, merge, debounce } from 'lodash';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { faEdit, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tribute from 'tributejs';
import ReactDOMServer from 'react-dom/server';

import instance from 'Src/common/utilities/axios_util';
import { fetchSpaces, getMembers } from 'Src/spaces/actions';
import ModalHeader from 'Src/common/components/modalHeader';
import axios from 'axios';
import { mapArrayAsIdObject } from 'Src/common/utilities/data_util';
import WaitingScreen from 'Src/common/components/waitingScreen';
import FroalaEditorComponent from 'Src/common/components/froalaEditor';
import VerificationContext from 'Src/common/context/verification';
import useSessionAuth from 'Src/common/hooks/useSessionAuth';
import { fetchPostAttachments, encodeTaggedUsersText } from 'Src/common/utilities/spaces_utils';
import { getTemplate } from '../../common';

import './style.scss';

/**
 * Post create and update modal
 */
function PostModal({ handleSubmit, spaceId, togglePostModal, postData = {} }) {
  const [selectChannelModalOpen, setSelectChannelModalOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channels, setChannels] = useState([]);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [summary, setSummary] = useState('');
  const [loadingChannels, setLoadingChannels] = useState(true);
  const selectChannelRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [assetIds, setAssetIds] = useState([]);
  const [sendAsEmail, setSendAsEmail] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const selectedSpaceRef = useRef(spaceId);
  const tributeRef = useRef(null);
  const [froalaInstance, setFroalaInstance] = useState(null);
  const { csrfToken } = useSessionAuth();
  const [form] = Form.useForm();
  const verificationData = useContext(VerificationContext);
  const { channel_id: channelId } = verificationData;

  /**
   * Handles enter key press
   */
  function handleEnterKeyPress(event, func) {
    if (event.key === 'Enter') {
      func();
    }
  }

  const getAllChannels = useCallback(async (spaces) => {
    const allChannels = [];
    for (const space of spaces) {
      if (space?.permissions?.can_create_post_in_any_channel) {
        if (spaceId) {
          if (space.id === spaceId) {
            const formattedChannel = {
              spaceName: space.name,
              spaceId: space.id,
              canSendEmail: space.permissions?.can_send_email,
              channels: space.channels.filter((val) => val.permissions?.can_create_post),
            };
            allChannels.push(formattedChannel);
          }
        } else {
          const formattedChannel = {
            spaceName: space.name,
            spaceId: space.id,
            canSendEmail: space.permissions?.can_send_email,
            channels: space.channels.filter((val) => val.permissions?.can_create_post),
          };
          allChannels.push(formattedChannel);
        }
      }
    }
    setAvailableChannels(allChannels);
    setChannels(allChannels);

    // Pre select channel if user is inside the channel
    if (channelId) {
      allChannels.forEach((space) => {
        space.channels.forEach((channel) => {
          if (channelId === channel.id) {
            selectedSpaceRef.current = space.spaceId;
            setSelectedChannel({
              name: channel.name,
              id: channel.id,
              spaceName: space.spaceName,
              canSendEmail: space.canSendEmail,
            });
          }
        });
      });
    }
  });

  useEffect(() => {
    fetchSpaces({
      fields: 'id,name,description,permissions,channels,can_moderators_send_email',
      extraQueryParams: '',
    }).then(async (res) => {
      await getAllChannels(res.data.results);
      setLoadingChannels(false);
    });

    if (!isEmpty(postData)) {
      setSelectedChannel({
        name: postData.channel.name,
        id: postData.channel.id,
        spaceName: postData.space.name,
        canSendEmail: postData.permissions.can_send_email,
      });
      setSummary(postData.descriptionString);
      setTaggedUsers(postData.taggedUsers);
      form.setFieldsValue(postData);
      form.setFieldsValue({ attachments: postData.attachments.map((attachment) => attachment.id) });
      fetchPostAttachments(postData, (attachments) => {
        const formattedAttachments = [];
        const newAssetsIds = [];
        attachments.forEach((attachment) => {
          formattedAttachments.push({
            uid: attachment.id,
            status: 'done',
            url: attachment.url,
            thumbUrl: attachment.thumbnailUrl,
            name: attachment.name,
            assetId: attachment.id,
          });
          newAssetsIds.push(attachment.id);
        });
        setAssetIds(newAssetsIds);
        setFileList(formattedAttachments);
      });
    }
  }, []);

  /**
   * Toggles the select channel modal
   */
  function toggleSelectChannelModalOpen() {
    if (selectChannelModalOpen) {
      selectChannelRef.current.focus();
    }
    setAvailableChannels(channels);
    setSelectChannelModalOpen(!selectChannelModalOpen);
  }

  /**
   * Handles the selection of a channel
   */
  function handleChannelSelect(channel) {
    selectedSpaceRef.current = channel.spaceId;
    setSelectedChannel(channel);
    toggleSelectChannelModalOpen();
    form.setFieldsValue({ channel: channel.id });
  }

  /**
   * Handles create post
   */
  function onCreatePost(value) {
    const encodedDescription = encodeTaggedUsersText(froalaInstance?.el.innerHTML, taggedUsers);

    value.description = encodedDescription;
    value.channel = { id: selectedChannel.id };
    if (!isEmpty(value.attachments)) {
      value.attachments = mapArrayAsIdObject(value.attachments);
    }
    selectedSpaceRef.current = '';
    setSelectedChannel(null);
    setSummary('');
    setFileList([]);
    form.resetFields();
    handleSubmit(value, sendAsEmail);
    setSendAsEmail(false);
  }

  function validateChannel() {
    if (selectedChannel) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Please select a topic'));
  }

  function validateHeading(_, value) {
    if (value?.trim()) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('This field can not be empty'));
  }

  function handleChanelSearch(event) {
    const { value } = event.target;
    const foundSpace = [];
    if (value) {
      channels.forEach((space) => {
        const foundChannels = [];
        space.channels.forEach((channel) => {
          if (channel.name.toLowerCase().includes(value.toLowerCase())) {
            foundChannels.push(channel);
          }
        });
        if (foundChannels.length) {
          foundSpace.push({
            spaceName: space.spaceName,
            spaceId: space.spaceId,
            channels: foundChannels,
            canSendEmail: space.canSendEmail,
          });
        }
      });
      setAvailableChannels(foundSpace);
    } else {
      setAvailableChannels(channels);
    }
  }

  function getPresignedPost(file) {
    // This function will call the backend with a file and key name
    // It returns a post policy with which we can upload to S3
    // It is a bunch of fields through which S3 can identify us, including access key, encoded policy, file key etc.
    // cleaning the file name to keep key and url valid
    const cleanedName = file.name.replace(/[^a-zA-Z0-9.-]+/g, '_');
    const headers = {
      'X-CSRFToken': csrfToken,
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const formData = new FormData();
    formData.append('name', cleanedName);
    formData.append('source_type', 'space');
    formData.append('source_id', selectedSpaceRef.current);
    formData.append('access_level', 'protected');

    return instance.post('assets/', formData, { headers });
  }

  function beforeFileUpload(file) {
    const isBigSize = file.size / 1024 / 1024 < 20;
    if (!isBigSize) {
      message.error('Image must be smaller than 20MB');
    }
    return isBigSize;
  }

  function processAntdImageUpload({ file, fileUrl, assetId }) {
    const fileObj = {
      uid: file.uid,
      status: 'done',
      url: fileUrl,
      thumbUrl: fileUrl,
      name: file.name,
      assetId,
    };
    fileList.push(fileObj);
    setFileList([...fileList]);
    // when first file is uploaded, no change in how to set values for single and multi
    assetIds.push(assetId);
    form.setFieldsValue({ attachments: [...assetIds] });
    setAssetIds([...assetIds]);
    setUploading(false);
  }

  function uploadImage(file, callback) {
    getPresignedPost(file).then((response) => {
      const postPolicy = response.data;
      const { asset_id: assetId } = postPolicy;
      const formData = new FormData();
      // Post Policy returned has 2 fields
      // fields - contains aws signature, key, policy, etc encoded
      // url - url of the bucket in s3 ie bucketname.aws.s3, this is where we POST to.
      keys(postPolicy.fields).map((field) => formData.append(field, postPolicy.fields[field]));
      const postUrl = postPolicy.url;
      formData.append('file', file);
      formData.append('Content-Type', file.type);
      // fileUrl is where the file is located, bucket + key name
      const fileUrl = response.data.asset_url;
      axios
        .post(postUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          callback({ file, fileUrl, assetId });
        });
    });
  }

  function customRequest({ file }, callback) {
    setUploading(true);
    uploadImage(file, callback);
  }

  function handleSendAsEmailConfirmation() {
    setSendAsEmail(!sendAsEmail);
  }

  function handleFileDelete(files) {
    if (files.length < assetIds.length) {
      const idsArr = [];
      files.forEach((file) => {
        idsArr.push(file.assetId);
      });
      form.setFieldsValue({ attachments: idsArr });
      setAssetIds(idsArr);
      setFileList(files);
    }
  }

  useEffect(() => {
    if (selectedChannel?.spaceId || spaceId) {
      froalaInstance?.edit.on();
    } else {
      froalaInstance?.edit.off();
    }
  }, [froalaInstance, selectedChannel, spaceId]);

  function handleBeforeUpload(images) {
    let editor = this;
    if (isEmpty(selectedSpaceRef.current)) {
      message.warning('Please select a topic before uploading image.');
    } else {
      editor.image.showProgressBar();

      uploadImage(images[0], (...args) => {
        editor.image.insert(args[0].fileUrl, null, null, editor.image.get());
      });
    }

    return false;
  }

  function handleInitialized() {
    let editor = this;
    tributeRef.current = new Tribute({
      values: debounce((text, cb) => {
        getMembers(selectedSpaceRef.current, text).then((res) => {
          forEach(res.data.results, (item) => {
            merge(item, item.profile, { key: item.profile.full_name });
            delete item.profile;
          });
          cb(res.data.results);

          const disabledItems = document.querySelectorAll('.disabled-menu-item.tribute-menu-item');

          forEach(disabledItems, (item) => {
            item?.parentNode?.classList?.add('disabled-menu-item');
          });
        });
      }, 300),
      lookup: 'key',
      fillAttr: 'key',
      selectTemplate: (item) => {
        setTaggedUsers((prevTaggedUsers) => [...prevTaggedUsers, item]);
        return `<span class="fr-deletable fr-tribute">@${item.original.key}</span>`;
      },
      menuItemTemplate: (item) => ReactDOMServer.renderToString(getTemplate(item)),
      noMatchTemplate: '',
    });

    tributeRef.current?.attach(editor.el);
    setFroalaInstance(editor);

    editor.events.on(
      'keydown',
      (e) => {
        if (e.which === 13 && tributeRef.current.isActive) {
          return false;
        }
      },
      true,
    );
  }

  return (
    <Modal
      visible
      className="create-post-modal"
      closable={false}
      // onCancel={togglePostModal}
      title={<ModalHeader title="Create Post" hasBackArrow onArrowClick={togglePostModal} />}
      width={500}
      footer={null}>
      <Form name="create-post" onFinish={onCreatePost} className="create-post-form" form={form}>
        <Form.Item name="channel" rules={[{ validator: validateChannel }]}>
          {/* Disable edit channel functionality for edit post */}
          <div
            className={`select-channel-btn ${isEmpty(postData) ? '' : 'select-channel-btn-disabled'}`}
            onClick={() => {
              isEmpty(postData) ? toggleSelectChannelModalOpen() : null;
            }}
            onKeyDown={(e) => {
              isEmpty(postData) ? handleEnterKeyPress(e, toggleSelectChannelModalOpen) : null;
            }}
            role="button"
            tabIndex={0}
            ref={selectChannelRef}>
            <Choose>
              <When condition={selectedChannel?.name}>
                <div>
                  <p>{selectedChannel?.spaceName}</p>
                  <p className="arc-color-primary">{selectedChannel?.name}</p>
                </div>
                <FontAwesomeIcon icon={faEdit} className="arc-color-primary" />
              </When>
              <Otherwise>
                <p className="arc-color-primary">Select Topic</p>
              </Otherwise>
            </Choose>
          </div>
        </Form.Item>
        {/* <div className="channel-selection-info-container">
              <FontAwesomeIcon icon={faInfoCircle} />
              <p>
                Please use this channel only to post opportunities that might be useful for current students and recent
                graduates.
              </p>
            </div> */}
        <Form.Item
          name="heading"
          rules={[{ required: true, message: 'Title is required', validator: validateHeading }]}>
          <Input placeholder="Title" size="large" />
        </Form.Item>
        <FroalaEditorComponent
          model={summary}
          onModelChange={setSummary}
          toolbarInline={false}
          quickInsertButtons={false}
          showCharacterCount={false}
          placeholder="Add a summary. Tag people in the post summary using &lsquo;@&rsquo;"
          toolbarButtons={[
            'bold',
            'italic',
            'underline',
            'formatOL',
            'formatUL',
            'paragraphFormat',
            'insertImage',
            'insertLink',
            'clearFormatting',
            'fullscreen',
          ]}
          paragraphFormat={{ H1: 'Large Heading', H3: 'Small Heading', N: 'Normal' }}
          uploadImageOptions={{
            imageUpload: true,
            imageDefaultAlign: 'left',
            imageAllowedTypes: ['jpeg', 'jpg', 'png', 'webp', 'gif'],
            imageUploadURL: null,
          }}
          handleBeforeUpload={handleBeforeUpload}
          handleInitialized={handleInitialized}
          useClasses={false}
        />
        <Form.Item name="attachments" className="add-attachment">
          <Upload
            listType="text"
            disabled={!selectedChannel}
            customRequest={(event) => customRequest(event, processAntdImageUpload)}
            multiple
            beforeUpload={(file) => beforeFileUpload(file, true)}
            fileList={fileList}
            onChange={({ fileList }) => handleFileDelete(fileList)}
            accept=".jpg, .jpeg, .png, .gif, .pdf"
            showUploadList={{
              showPreviewIcon: false,
              showDownloadIcon: false,
              showRemoveIcon: true,
            }}>
            <Button icon={<FontAwesomeIcon icon={faPaperclip} />} loading={uploading} />
          </Upload>
          {/* <div className="upload-btn-container">
                <Upload className="upload-btn">
                  <Button icon={<FontAwesomeIcon icon={faImage} />} />
                </Upload>
                <Upload className="upload-btn">
                  <Button icon={<FontAwesomeIcon icon={faPaperclip} />} />
                </Upload>
              </div> */}
        </Form.Item>
        <Form.Item>
          <div className="footer-btn-container">
            <div className="send-as-email-container">
              <If condition={selectedChannel?.canSendEmail}>
                <Popconfirm
                  title="When you click post, this message will be sent immediately to all subscribers. Continue?"
                  onConfirm={handleSendAsEmailConfirmation}
                  disabled={sendAsEmail}
                  okText="Yes"
                  cancelText="No">
                  <Switch
                    size="small"
                    checked={sendAsEmail}
                    onClick={() => (sendAsEmail ? handleSendAsEmailConfirmation() : null)}
                  />
                </Popconfirm>
                <p className="ml12">Send as announcement</p>
                <Tooltip title="As a moderator, you can toggle on to send this post out immediately to all subscribers">
                  <FontAwesomeIcon icon={faInfoCircle} className="ml6" />
                </Tooltip>
              </If>
            </div>
            <Button type="primary" htmlType="submit" loading={uploading} disabled={!summary}>
              Post
            </Button>
          </div>
        </Form.Item>
      </Form>
      <If condition={selectChannelModalOpen}>
        <Modal
          visible
          className="select-channel-modal"
          closable={false}
          onCancel={toggleSelectChannelModalOpen}
          title={<ModalHeader title="Select Topic" hasBackArrow onArrowClick={toggleSelectChannelModalOpen} />}
          width={500}
          footer={null}>
          <Input placeholder="Search" className="channel-search" size="large" onChange={handleChanelSearch} />
          <Choose>
            <When condition={loadingChannels}>
              <WaitingScreen showIcon={false} />
            </When>
            <Otherwise>
              {availableChannels.map((space) => (
                <div className="channels-parent-container" key={space.spaceId}>
                  <h6 className="arc-H100">{space.spaceName}</h6>
                  <div className="channel-list-container">
                    {space.channels.map((channel) => (
                      <p
                        key={channel.id}
                        className="channel-name"
                        tabIndex={0}
                        onClick={() =>
                          handleChannelSelect({
                            name: channel.name,
                            id: channel.id,
                            spaceName: space.spaceName,
                            spaceId: space.spaceId,
                            canSendEmail: space.canSendEmail,
                          })
                        }
                        onKeyDown={(e) =>
                          handleEnterKeyPress(e, () =>
                            handleChannelSelect({
                              name: channel.name,
                              id: channel.id,
                              spaceName: space.spaceName,
                              spaceId: space.spaceId,
                              canSendEmail: space.canSendEmail,
                            }),
                          )
                        }>
                        # {channel.name}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </Otherwise>
          </Choose>
        </Modal>
      </If>
    </Modal>
  );
}

export default PostModal;
