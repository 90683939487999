/* eslint-disable max-statements */
import React, { useState, useEffect, useContext } from 'react';
import { func, string } from 'prop-types';
import postal from 'postal';
import VerificationContext from 'Src/common/context/verification';
import UserProfileContext from 'Src/spaces/context/user';
import HybridAuthContext from 'Src/spaces/context/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import history from 'Src/common/utilities/history';
import { fetchSpaces, isUserVerified, getSpaceSettings } from 'Src/spaces/actions';
import * as ENDPOINTS from 'Src/spaces/endpoints';
import AssetManagerHelper from 'Src/common/utilities/asset_manager';
import { LIST_OF_BANNER_IMAGES } from 'Src/spaces/constants';
import Navigation from 'Src/spaces/components/navigation';

/**
 * Navigation drawer container
 */
function NavigationDrawer({ toggleDrawer, pathname }) {
  const { pendingVerifications, update, space_id: spaceId } = useContext(VerificationContext);
  const { userProfile, isNewEmail } = useContext(UserProfileContext);
  const { isUserAuthenticated } = useContext(HybridAuthContext);
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spaceImages, setSpaceImages] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState('');
  const { loginWithPopup, logout } = useAuth0();
  const [spaceSettings, setSpaceSettings] = useState();
  //   const { width } = useWindowDimensions();

  const searchParams = `${
    window.location.search
      ? window.location.search.concat('&is_verification_popup_open=true')
      : '?is_verification_popup_open=true'
  } `;

  /**
   * Sets cover image for space
   */
  async function setCoverImage(space) {
    if (spaceImages[space.id]) {
      return;
    }
    if (isEmpty(space.banner_image_asset)) {
      spaceImages[space.id] = LIST_OF_BANNER_IMAGES[0];
      setSpaceImages({ ...spaceImages });
    } else {
      const assetManagerHelper = new AssetManagerHelper(space.banner_image_asset.id);
      const variants = await assetManagerHelper.getVariants();
      spaceImages[space.id] = variants.thumbnailUrl;
      setSpaceImages({ ...spaceImages });
    }
  }

  function fetchAndSetupSpaces() {
    fetchSpaces({ extraQueryParams: 'user_status=subscribed' })
      .then((res) => {
        setSpaces(res.data.results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (isUserAuthenticated) {
      fetchAndSetupSpaces();
      postal.subscribe({
        channel: 'spaces',
        topic: 'space.subscribed',
        callback: (data, envelope) => {
          fetchAndSetupSpaces();
        },
      });
      postal.subscribe({
        channel: 'spaces',
        topic: 'space.unsubscribed',
        callback: (data, envelope) => {
          fetchAndSetupSpaces();
        },
      });
      getSpaceSettings().then((res) => {
        setSpaceSettings(res.data);
      });
    }
  }, [isUserAuthenticated]);

  useEffect(() => {
    spaces.forEach((space) => {
      setCoverImage(space);
    });
  }, [spaces]);

  useEffect(() => {
    setSelectedSpace(spaceId);
  }, [spaceId]);

  /**
   * Handles menu item click event
   */
  function navigateTo(navigationUrl, selectedSpaceId) {
    // This is called for small screen devices
    if (toggleDrawer) {
      toggleDrawer();
    }
    setSelectedSpace(selectedSpaceId);
    history.push(navigationUrl);
  }

  /**
   * Handle enter key press event
   */
  function HandlePressEnter(event, navigationUrl) {
    if (event.key === 'Enter') {
      navigateTo(navigationUrl);
    }
  }

  /**
   * Login function
   */
  function handleLogin() {
    const newWindow = window.open();
    // Set isAuthenticated in local storage for account selector redirect
    localStorage.setItem('isAuthenticated', false);
    loginWithPopup(
      {
        // adding prompt login so it does not re authenticate user automatically ones he is logged out
        prompt: 'login',
        appState: {
          targetUrl: `${window.location.pathname + searchParams}`,
        },
        primaryColor: window.primaryColor,
        logo: window.customerLogo,
      },
      {
            timeoutInSeconds: 600,
            popup: newWindow,
        },
    );
  }

  /**
   * Handles logout
   */
  function handleLogout() {
    logout({
      returnTo: `${window.location.origin}/s/auth0/logout?returnTo=${
        window.location.origin + window.location.pathname
      }`,
    });
  }

  /**
   * Handles url redirect
   */
  function handleProfileRedirect(url) {
    window.location.href = url;
  }

  /**
   *
   */
  function updateContextAndNavigate(space) {
    isUserVerified(ENDPOINTS.SPACE_DETAILS_VFX_PATH.replace(':spaceSlug', space.slug)).then((res) => {
      update({
        isVerifying: false,
        isVerified: res.data.is_verified,
        isNewUser: res.data.is_new_user,
        pendingVerifications,
        update,
        id: res.data.id,
        space_id: res.data.space_id,
        channel_id: res.data.channel_id,
        post_id: res.data.post_id,
        groups: res.data.groups,
      });
      navigateTo(new URL(space.space_url).pathname, space.id);
    });
  }

  return (
    <Navigation
      spaces={spaces}
      loading={loading}
      pathname={pathname}
      navigateTo={navigateTo}
      userProfile={userProfile}
      spaceImages={spaceImages}
      handleLogin={handleLogin}
      handleLogout={handleLogout}
      selectedSpace={selectedSpace}
      HandlePressEnter={HandlePressEnter}
      isUserAuthenticated={isUserAuthenticated}
      handleProfileRedirect={handleProfileRedirect}
      updateContextAndNavigate={updateContextAndNavigate}
      isNewEmail={isNewEmail}
      spaceSettings={spaceSettings} />
  );
}

NavigationDrawer.propTypes = {
  toggleDrawer: func,
  pathname: string.isRequired,
};

export default NavigationDrawer;
