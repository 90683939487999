import { Button } from 'antd';
/* eslint-disable max-nested-callbacks */
/* eslint-disable max-statements */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { animated } from 'react-spring';
import { PostDetails, Heading, Comments } from 'arcl';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faArrowRight, faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import pluralize from 'pluralize';
import WaitingScreen from 'Src/common/components/waitingScreen';
import NotFound from 'Src/spaces/components/emptyState';
import ApplyForVerification from 'Src/common/components/verifications/applyForVerification';
import VerificationGroupSelector from 'Src/common/components/verifications/groupSelector';
import { fadeInAnimation } from 'Src/spaces/animations';
import VerificationContext from 'Src/common/context/verification';
import useSessionAuth from 'Src/common/hooks/useSessionAuth';
import PostActions from './postActions';

import './style.scss';

/**
 * Post component
 */
function PostDescription({
  post,
  is404,
  setRef,
  reverse,
  comments,
  addComment,
  userProfile,
  commentCount,
  handleMenuClick,
  verificationData,
  handleCommentMenu,
  isUserAuthenticated,
  handleScrollToReply,
  toggleApplyForVerificationModal,
  isApplyForVerificationModalVisible,
  getNextPost,
  history,
  hasMoreComments,
  fetchMoreComments,
  handleFollowChange,
  location,
}) {
  const { isVerified, pendingVerifications } = useContext(VerificationContext);
  const { isAuthenticated, user, logout } = useAuth0();
  const { isSessionAuthenticated, userData, csrfToken } = useSessionAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const action = urlParams.get('action');
    if (action && action === 'unsubscribe' && post?.permissions?.can_create_comment && post?.followed) {
      handleFollowChange(null, { csrfToken });
    }
  }, [location]);

  /**
   * Handles enter key press
   */
  function handleEnterKeyPress(event, func) {
    if (event.key === 'Enter') {
      func();
    }
  }

  function handleGoBack() {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/s/feed/');
    }
  }

  function getPostActionItems() {
    let arr = [
      {
        icon: <FontAwesomeIcon icon={faArrowRight} style={{ marginRight: '10px' }} />,
        text: 'Next',
        handler: () => getNextPost(),
      },
    ];
    if (isVerified) {
      arr = [
        {
          icon: <FontAwesomeIcon icon={faReply} style={{ marginRight: '10px' }} />,
          text: 'Reply',
          handler: handleScrollToReply,
        },
        ...arr,
      ];
    }
    return arr;
  }

  return (
    <>
      <Choose>
        <When condition={is404}>
          <NotFound
            heading="Not Found"
            redirectLink="/s/feed/"
            buttonText="Go back to feed"
            illustrationPath="img/spaces/404.svg"
            description="The post you are looking for has been removed."
          />
        </When>
        <Otherwise>
          <Choose>
            <When condition={post?.permissions?.can_read_comments}>
              <div className="post-details-wrapper">
                <div className="post-details-container">
                  <animated.div style={fadeInAnimation(reverse)} className="post-container">
                    <div
                      className="post-details-back-arrow arc-color-primary"
                      tabIndex={0}
                      onClick={handleGoBack}
                      onKeyDown={(e) => handleEnterKeyPress(e, handleGoBack)}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                      <p className="ml12">Go Back</p>
                    </div>
                    <PostDetails {...post} handleMenuClick={handleMenuClick} toggleFollow={handleFollowChange}>
                      <Choose>
                        <When condition={post?.permissions?.can_create_comment}>
                          <div className="comments-container mt32">
                            <Heading variant="h6" color="rgba(0,0,0,0.55)">
                              {pluralize('COMMENTS', commentCount, true)}
                            </Heading>
                            <Comments
                              className="comments-wrapper"
                              comments={comments}
                              handleMenuClick={handleCommentMenu}
                              isReplyEnabled={post.permissions.can_create_comment}
                              hasMore={hasMoreComments}
                              onMore={fetchMoreComments}
                              replyConfig={{
                                author: {
                                  avatarConfig: {
                                    size: 'default',
                                    url: userProfile ? userProfile.present_picture_url : null,
                                  },
                                  title: `${userProfile.first_name} ${userProfile.last_name}`,
                                  subtitle: [userProfile.groups.map((g) => g.name)].join(', '),
                                  color: window.primaryColor,
                                },
                                setRef,
                                onReply: addComment,
                                id: 'reply-component',
                                placeholder: "Add a comment. Use '@' to tag people",
                              }}
                            />
                          </div>
                        </When>
                        <Otherwise>
                          <VerificationGroupSelector
                            groups={verificationData.groups}
                            isAuthenticated={isUserAuthenticated}
                            onVerify={toggleApplyForVerificationModal}
                            pendingVerifications={pendingVerifications}
                            theme={{
                              primaryColor: window.primaryColor,
                              logo: window.customerLogo,
                              collegeName: window.collegeName,
                            }}
                          />
                        </Otherwise>
                      </Choose>
                    </PostDetails>
                  </animated.div>
                  <PostActions actions={getPostActionItems()} post={post} />
                </div>
              </div>
            </When>
            <Otherwise>
              <VerificationGroupSelector
                groups={verificationData.groups}
                isAuthenticated={isUserAuthenticated}
                onVerify={toggleApplyForVerificationModal}
                pendingVerifications={pendingVerifications}
                theme={{
                  primaryColor: window.primaryColor,
                  logo: window.customerLogo,
                  collegeName: window.collegeName,
                }}
              />
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
      <If condition={isApplyForVerificationModalVisible}>
        <ApplyForVerification
          groups={verificationData.groups}
          user={isAuthenticated ? user : userData}
          isSessionAuthenticated={isSessionAuthenticated}
          isAuthenticated={isAuthenticated}
          isNewUser={verificationData.isNewUser}
          closeModal={toggleApplyForVerificationModal}
          logout={() =>
            logout({
              returnTo: `${window.location.origin}/s/auth0/logout?returnTo=${
                window.location.origin + window.location.pathname
              }`,
            })
          }
          collegeEmail={window.collegeEmail}
          collegeName={window.collegeName}
        />
      </If>
    </>
  );
}

export default withRouter(PostDescription);
