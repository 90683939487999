import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { Modal, Form, Input, Select, Button, Popconfirm } from 'antd';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash } from '@fortawesome/pro-light-svg-icons';

import './style.scss';

/**
 * Channel settings create or update modal.
 */
function ChannelCreateOrUpdate({ channel, onClose, onSave, onDelete }) {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [form] = Form.useForm();

  /**
   * Handles modal close
   */
  function handleClose() {
    setIsModalVisible(false);
    onClose();
  }

  return (
    <Modal
      title={
        <div className="arc-d-flex arc-flex-v-align-middle arc-flex-space-between-align">
          <span>
            <FontAwesomeIcon icon={faArrowLeft} className="arc-color-primary mr16 arc-cursor-p" onClick={handleClose} />
            <span className="arc-H150">{isEmpty(channel) ? 'New Topic' : channel.name}</span>
          </span>
          <If condition={!isEmpty(channel)}>
            <Popconfirm title="Are you sure you want to delete this topic?" onConfirm={() => onDelete(channel)}>
              <FontAwesomeIcon icon={faTrash} className="arc-color-primary arc-cursor-p" />
            </Popconfirm>
          </If>
        </div>
      }
      visible={isModalVisible}
      footer={[
        <Button type="primary" key="submit" onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
      onCancel={handleClose}
      closable={false}
      wrapClassName="channel-crud-modal">
      <div className="channel-crud-wrapper">
        <Form form={form} layout="vertical" onFinish={onSave} initialValues={channel}>
          <Form.Item label="Id" name="id" hidden>
            <Input size="large" hidden />
          </Form.Item>
          <Form.Item
            label="Topic Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter topic name',
              },
            ]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Allow only moderators to post on this topic"
            name="only_moderators_can_post"
            rules={[
              {
                required: true,
                message: 'Please select an option',
              },
            ]}>
            <Select size="large">
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

ChannelCreateOrUpdate.defaultProps = {
  channel: {},
};

ChannelCreateOrUpdate.propTypes = {
  channel: shape(),
  onClose: func.isRequired,
  onSave: func.isRequired,
  onDelete: func.isRequired,
};

export default ChannelCreateOrUpdate;
