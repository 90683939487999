/* eslint-disable max-statements */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { isEmpty, findIndex, find } from 'lodash';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import queryString from 'query-string';
import postal from 'postal';
import HybridAuthContext from 'Src/spaces/context/auth';
import useSessionAuth from 'Src/common/hooks/useSessionAuth';
import ExploreComponent from 'Src/spaces/components/explore';
import useDebounce from 'Src/common/hooks/useDebounce';
import { fetchSpaces, isUserVerified, subscribeSpace, unsubscribeSpace, fetchAllCategories } from 'Src/spaces/actions';
import transformSpace from 'Src/spaces/adapters/spaceCard';
import AssetManagerHelper from 'Src/common/utilities/asset_manager';
import useWindowDimensions from 'Src/common/hooks/useWindowDimension';
import VerificationContext from 'Src/common/context/verification';
import ApplyForVerification from 'Src/common/components/verifications/applyForVerification';
import * as ENDPOINTS from 'Src/spaces/endpoints';
import * as ROUTES from 'Src/spaces/routes';
import { IMAGE_VARIANT, MEMBER_STATUSES } from 'Src/spaces/constants';

/**
 * Explore container
 */
function Explore({ history }) {
  const [spaces, setSpaces] = useState([]);
  const [cards, setCards] = useState([]);
  const [otherCards, setOtherCards] = useState([]);
  const [searchText, setSearchText] = useState('');
  // Removed this toggle for now. Keeping this state for future implementation
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const debouncedQuery = useDebounce(searchText, 500);
  const [selectedSpace, setSelectedSpace] = useState({});
  const [isSpaceFetched, setIsSpaceFetched] = useState(false);
  const [isReordered, setIsReordered] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const { isUserAuthenticated } = useContext(HybridAuthContext);
  const { isSessionAuthenticated, userData } = useSessionAuth();
  const { isAuthenticated, user, logout, loginWithPopup } = useAuth0();
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const { width } = useWindowDimensions();
  const { isNewUser, pendingVerifications, update } = useContext(VerificationContext);
  const cardsRef = useRef(null);
  const spacesRef = useRef(null);
  const selectedSpaceRef = useRef(null);
  cardsRef.current = cards;
  spacesRef.current = spaces;
  selectedSpaceRef.current = selectedSpace;

  function updateSpace(updatedSpace) {
    const index = findIndex(spacesRef.current, (val) => val.id === updatedSpace.id);
    if (index > -1) {
      spacesRef.current[index].status = updatedSpace.status;
      spacesRef.current[index].count_subscribers = updatedSpace.count_subscribers;
      const cardIndex = findIndex(cardsRef.current, (val) => val.id === updatedSpace.id);
      if (cardIndex > -1) {
        cardsRef.current[cardIndex] = transformSpace(spacesRef.current[index], onAction);
        setCards(cardsRef.current.slice());
      }
    }
  }

  function toggleVerificationModal() {
    setIsVerificationModalOpen((val) => !val);
  }

  function onAction(e, space) {
    e.stopPropagation();
    e.preventDefault();
    if ([MEMBER_STATUSES.UNSUBSCRIBED, MEMBER_STATUSES.APPROVED, MEMBER_STATUSES.AUTO].includes(space.status)) {
      subscribeSpace(space.id).then(() => {
        updateSpace({
          id: space.id,
          status: MEMBER_STATUSES.SUBSCRIBED,
          count_subscribers: space.count_subscribers + 1,
        });
        postal.publish({
          channel: 'spaces',
          topic: 'space.subscribed',
        });
        message.success(`Successfully subscribed to ${space.name}`);
      });
    }
    if (space.status === MEMBER_STATUSES.SUBSCRIBED) {
      unsubscribeSpace(space.id).then(() => {
        updateSpace({
          id: space.id,
          status: MEMBER_STATUSES.UNSUBSCRIBED,
          count_subscribers: space.count_subscribers - 1,
        });
        postal.publish({
          channel: 'spaces',
          topic: 'space.unsubscribed',
        });
        message.success(`Successfully unsubscribed from ${space.name}`);
      });
    }
    if (space.status === MEMBER_STATUSES.NOT_MEMBER) {
      setSelectedSpace(space);
      selectedSpaceRef.current = space;
      if (!isVerificationModalOpen && !isUserAuthenticated) {
        handleLogin();
      } else {
        setIsVerificationModalOpen(!isVerificationModalOpen);
      }
    }
  }

  function handleLogin() {
    const searchParams = `${
      window.location.search
        ? window.location.search.concat('&is_verification_popup_open=true')
        : `?is_verification_popup_open=true&id=${selectedSpaceRef.current.id}`
    } `;
    const newWindow = window.open();
    const targetUrl = `/select-account?next=${window.location.pathname + searchParams}`;
    sessionStorage.setItem('targetUrl', targetUrl);
    loginWithPopup(
      {
        // adding prompt login so it does not re authenticate user automatically ones he is logged out
        prompt: 'login',
        primaryColor: window.primaryColor,
        logo: window.customerLogo,
      },
      {
        timeoutInSeconds: 600,
        popup: newWindow,
      },
    );
  }

  /**
   * Handles menu item click event
   */
  function navigateTo(navigationUrl) {
    history.push(navigationUrl);
  }

  /**
   * Handle enter key press event
   */
  function HandlePressEnter(event, navigationUrl) {
    if (event.key === 'Enter') {
      navigateTo(navigationUrl);
    }
  }

  /**
   * Get space cover image
   */
  async function getCoverImage(space) {
    if (isEmpty(space.banner_image_asset)) {
      return `${window.staticPath}img/spaces/space_default_banner_image.jpeg`;
    }
    const assetManagerHelper = new AssetManagerHelper(space.banner_image_asset.id);
    try {
      const variants = await assetManagerHelper.getVariants(IMAGE_VARIANT.medium);
      return variants.url;
    } catch {
      return `${window.staticPath}img/spaces/space_default_banner_image.jpeg`;
    }
  }

  useEffect(() => {
    setCategoriesLoading(true);
    fetchAllCategories().then((res) => {
      const { results, count } = res.data;
      setCategories(results);
      if (count) {
        const foundCategory = find(results, (o) => o.is_default === true);
        setSelectedCategory(foundCategory?.id?.toString());
      }
      setCategoriesLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!categoriesLoading) {
      setLoading(true);
      setIsSpaceFetched(false);
      setSpaces([]);
      setCards([]);
      setOtherCards([]);
      setIsReordered(false);
      let extraQueryParams = `search=${debouncedQuery}`;
      const foundCategory = find(categories, (o) => o.id.toString() === selectedCategory);

      if (selectedCategory) {
        extraQueryParams = `${extraQueryParams}&category=${selectedCategory}`;
      }

      // Fetch spaces
      fetchSpaces({
        fields:
          'id,name,slug,latest_post_at,banner_image_asset,is_verified,count_subscribers,groups,permissions,space_url,status',
        extraQueryParams,
      }).then((res) => {
        setSpaces(res.data.results);
        setIsSpaceFetched(true);
        res.data.results.forEach(async (space) => {
          space.banner_image_asset_url = await getCoverImage(space);
          const formattedCard = transformSpace(space, onAction);
          setCards((prevCards) => [...prevCards, formattedCard]);
        });
      });

      if (debouncedQuery && !foundCategory?.is_default) {
        // Fetch spaces
        extraQueryParams = `${extraQueryParams}&exclude_category=true`;
        fetchSpaces({
          fields:
            'id,name,slug,latest_post_at,banner_image_asset,is_verified,count_subscribers,groups,permissions,space_url,status',
          extraQueryParams,
        }).then((res) => {
          res.data.results.forEach(async (space) => {
            space.banner_image_asset_url = await getCoverImage(space);
            const formattedCard = transformSpace(space, onAction);
            setOtherCards((prevCards) => [...prevCards, formattedCard]);
          });
        });
      }
    }
  }, [selectedCategory, debouncedQuery, categoriesLoading]);

  useEffect(() => {
    if (isSpaceFetched && cards.length === spaces.length && !isReordered) {
      setLoading(false);
      // When authentication is done, we add query param space id and verification pop up true to open verification just after authentication
      const queryS = queryString.parse(history.location.search);
      if (!isEmpty(queryS) && queryS.is_verification_popup_open && queryS.id) {
        const index = findIndex(spaces, (val) => val.id === queryS.id);
        if (index > -1) {
          selectedSpaceRef.current = spaces[index];
          setSelectedSpace(spaces[index]);
          toggleVerificationModal();
          history.push(ROUTES.EXPLORE_PATH);
          sessionStorage.removeItem('targetUrl');
        }
      }

      const arr = [];
      spaces.forEach((space) => {
        const foundCard = find(cards, (o) => o.id === space.id);
        arr.push(foundCard);
      });
      setCards(arr);
      setIsReordered(true);
    }
  }, [cards, spaces, isSpaceFetched]);
  /**
   *
   */
  function updateContextAndNavigate(space) {
    isUserVerified(ENDPOINTS.SPACE_DETAILS_VFX_PATH.replace(':spaceSlug', space.slug)).then((res) => {
      update({
        isVerifying: false,
        isVerified: res.data.is_verified,
        isNewUser: res.data.is_new_user,
        pendingVerifications,
        update,
        id: res.data.id,
        space_id: res.data.space_id,
        channel_id: res.data.channel_id,
        post_id: res.data.post_id,
        groups: res.data.groups,
      });
      navigateTo(new URL(space.space_url).pathname, space.id);
    });
  }

  function handleCategoryChange(val) {
    setSelectedCategory(val);
  }

  return (
    <>
      <ExploreComponent
        cards={cards}
        width={width}
        loading={loading}
        spaces={spaces}
        categories={categories}
        searchText={searchText}
        otherCards={otherCards}
        setSearchText={setSearchText}
        selectedCategory={selectedCategory}
        HandlePressEnter={HandlePressEnter}
        handleCategoryChange={handleCategoryChange}
        updateContextAndNavigate={updateContextAndNavigate}
      />
      <If condition={isVerificationModalOpen}>
        <ApplyForVerification
          groups={selectedSpaceRef.current.groups}
          user={isAuthenticated ? user : userData}
          isSessionAuthenticated={isSessionAuthenticated}
          isAuthenticated={isAuthenticated}
          isNewUser={isNewUser}
          closeModal={toggleVerificationModal}
          logout={() =>
            logout({
              returnTo: `${window.location.origin}/s/auth0/logout?returnTo=${
                window.location.origin + window.location.pathname
              }`,
            })
          }
          collegeEmail={window.collegeEmail}
          collegeName={window.collegeName}
        />
      </If>
    </>
  );
}

export default withRouter(Explore);
