/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from 'react';
import { Link } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Heading, Text, ProfileCard, VerificationCard } from 'arcl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll, faBinoculars, faBell, faHome } from '@fortawesome/pro-light-svg-icons';
import { faFacebook, faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import { Avatar, Divider, List } from 'antd';
import { string, bool, arrayOf, shape, func } from 'prop-types';
import { animated } from 'react-spring';
import * as ROUTES from 'Src/spaces/routes';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { slideUpAnimation, opacityAnimation } from 'Src/spaces/animations';
import NewEmailWrapper from '../newEmailWrapper';

import './style.scss';

/**
 * Navigation component
 */
function Navigation({
  spaces,
  loading,
  pathname,
  navigateTo,
  userProfile,
  spaceImages,
  handleLogin,
  handleLogout,
  selectedSpace,
  HandlePressEnter,
  isUserAuthenticated,
  handleProfileRedirect,
  updateContextAndNavigate,
  isNewEmail,
  spaceSettings,
}) {
  return (
    <div className="navigation-container">
      <If condition={isUserAuthenticated}>
        <Choose>
          <When condition={isNewEmail}>
            <NewEmailWrapper homepageUrl={spaceSettings?.homepage_url} />
          </When>
          <Otherwise>
            <animated.div className="navigation-header pl24 pr24 pt16" style={opacityAnimation()}>
              <>
                <Choose>
                  <When condition={spaceSettings?.homepage_url}>
                    <a href={spaceSettings?.homepage_url}>
                      <img src={window.customerLogo} alt="School Logo" className="mb24" />
                    </a>
                  </When>
                  <Otherwise>
                    <Link to={ROUTES.FEEDS_PATH}>
                      <img src={window.customerLogo} alt="School Logo" className="mb24" />
                    </Link>
                  </Otherwise>
                </Choose>
                <If condition={spaceSettings?.homepage_url}>
                  <div
                    className="header-item mb16"
                    onClick={() => {
                      window.location.href = spaceSettings.homepage_url;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        window.location.href = spaceSettings.homepage_url;
                      }
                    }}
                    role="link"
                    tabIndex={0}>
                    <FontAwesomeIcon icon={faHome} className="mr16 route-icon home-icon" />
                    <Heading variant="h5" color="rgba(0,0,0,0.85)" style={{ display: 'inline-block', fontWeight: 400 }}>
                      <I18nCustomFormatter id="homepage-url-label" />
                    </Heading>
                  </div>
                </If>
                <div
                  className={`header-item ${ROUTES.FEEDS_PATH === pathname ? 'selected' : ''}`}
                  onClick={() => navigateTo(ROUTES.FEEDS_PATH)}
                  onKeyDown={(e) => HandlePressEnter(e, ROUTES.FEEDS_PATH)}
                  role="link"
                  tabIndex={0}>
                  <FontAwesomeIcon icon={faScroll} className="mr16 route-icon" />
                  <Heading variant="h5" color="rgba(0,0,0,0.85)" style={{ display: 'inline-block', fontWeight: 400 }}>
                    <I18nCustomFormatter id="feed-label" />
                  </Heading>
                </div>
                <div
                  className={`header-item mt16 ${ROUTES.EXPLORE_PATH === pathname ? 'selected' : ''}`}
                  onClick={() => navigateTo(ROUTES.EXPLORE_PATH)}
                  onKeyDown={(e) => HandlePressEnter(e, ROUTES.EXPLORE_PATH)}
                  role="link"
                  tabIndex={0}>
                  <FontAwesomeIcon icon={faBinoculars} className="mr16 route-icon" />
                  <Heading variant="h5" color="rgba(0,0,0,0.85)" style={{ display: 'inline-block', fontWeight: 400 }}>
                    <I18nCustomFormatter id="explore-label" />
                  </Heading>
                </div>
                <If condition={isUserAuthenticated && !isNewEmail}>
                  <div
                    className={`header-item mt16 ${ROUTES.SUBSCRIPTION_PATH === pathname ? 'selected' : ''}`}
                    onClick={() => navigateTo(ROUTES.SUBSCRIPTION_PATH)}
                    onKeyDown={(e) => HandlePressEnter(e, ROUTES.SUBSCRIPTION_PATH)}
                    role="link"
                    tabIndex={0}>
                    <FontAwesomeIcon icon={faBell} className="mr16 route-icon" />
                    <Heading variant="h5" color="rgba(0,0,0,0.85)" style={{ display: 'inline-block', fontWeight: 400 }}>
                      <I18nCustomFormatter id="subscriptions-label" />
                    </Heading>
                  </div>
                </If>
              </>
            </animated.div>
            <Divider />
            <animated.div className="navigation-body" style={opacityAnimation()}>
              <div className="spaces-list-container">
                <If condition={!loading}>
                  <ConfigProvider
                    renderEmpty={() => <NewEmailWrapper hasExploreButton={false} hasSchoolLogo={false} />}>
                    <List
                      itemLayout="horizontal"
                      dataSource={spaces}
                      renderItem={(space) => (
                        <List.Item
                          role="link"
                          tabIndex={0}
                          onClick={() => updateContextAndNavigate(space)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              updateContextAndNavigate(space);
                            }
                          }}
                          className={`space-list-item pl16 pr24 ${space.id === selectedSpace ? 'selected' : ''}`}>
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                src={spaceImages[space.id]}
                                style={{ backgroundColor: window.primaryColor }}
                                alt={space.name}
                              />
                            }
                            title={
                              <Text
                                variant="paragraph"
                                color="#000"
                                style={{
                                  lineHeight: '18px',
                                  width: '180px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}>
                                {space.name}
                              </Text>
                            }
                          />
                        </List.Item>
                      )}
                      split={false}
                    />
                  </ConfigProvider>
                </If>
              </div>
            </animated.div>
            <animated.div style={slideUpAnimation()} className="profile-card">
              <ProfileCard
                color={window.primaryColor}
                profile={{
                  name: `${userProfile.first_name} ${userProfile.last_name}`,
                  verification_groups: [userProfile.groups.map((group) => group.name)],
                  picture_url: userProfile.present_picture_url,
                }}
                DropdownItems={{
                  menuItems: [
                    {
                      key: 1,
                      title: 'My Profile',
                      onClick: () => handleProfileRedirect(userProfile.profile_url),
                    },
                    { key: 2, title: 'Logout', onClick: handleLogout },
                  ],
                }}
              />
            </animated.div>
          </Otherwise>
        </Choose>
      </If>
      {/* </If> */}
      <If condition={!isUserAuthenticated}>
        <div className="verification-card-container">
          <div>
            <Choose>
              <When condition={window.homePageURL}>
                <a href={window.homePageURL}>
                  <img src={window.customerLogo} alt="School Logo" className="mb24 verification-card-banner-image" />
                </a>
              </When>
              <Otherwise>
                <Link to={ROUTES.FEEDS_PATH}>
                  <img src={window.customerLogo} alt="School Logo" className="mb24 verification-card-banner-image" />
                </Link>
              </Otherwise>
            </Choose>
            <VerificationCard
              customMessage={
                <div>
                  <p>
                    <I18nCustomFormatter id="logged-out-custom-message-header" values={{ collegeName: collegeName }} />
                  </p>
                  <p className="mt16">
                    <I18nCustomFormatter
                      id="logged-out-custom-message-description"
                      values={{ collegeName: collegeName }}
                    />
                  </p>
                </div>
              }
              socialLoginList={[
                <FontAwesomeIcon icon={faFacebook} />,
                <FontAwesomeIcon icon={faGoogle} />,
                <FontAwesomeIcon icon={faApple} />,
              ]}
              handleVerify={handleLogin}
            />
            <div className="navigation-footer">
              <a href={termsOfUsePageUrl || '/terms-of-use'} target="_blank">
                <I18nCustomFormatter id="terms-and-conditions" />
              </a>
              <a href={privacyPolicyPageUrl || '/privacy-policy'} target="_blank" className="arc-color-primary mt8">
                <I18nCustomFormatter id="privacy-policy" />
              </a>
            </div>
          </div>
          <div
            className={`header-item logged-out-explore-wrapper mt16 ${
              ROUTES.EXPLORE_PATH === pathname ? 'selected' : ''
            }`}
            onClick={() => navigateTo(ROUTES.EXPLORE_PATH)}
            onKeyDown={(e) => HandlePressEnter(e, ROUTES.EXPLORE_PATH)}
            role="link"
            tabIndex={0}>
            <FontAwesomeIcon icon={faBinoculars} className="mr16" />
            <Heading variant="h5" color="rgba(0,0,0,0.85)" style={{ display: 'inline-block', fontWeight: 400 }}>
              <I18nCustomFormatter id="explore-label" />
            </Heading>
          </div>
        </div>
      </If>
    </div>
  );
}

Navigation.propTypes = {
  spaces: arrayOf(shape()).isRequired,
  loading: bool.isRequired,
  pathname: string.isRequired,
  navigateTo: func.isRequired,
  userProfile: shape().isRequired,
  spaceImages: arrayOf(shape()).isRequired,
  handleLogin: func.isRequired,
  handleLogout: func.isRequired,
  selectedSpace: string.isRequired,
  HandlePressEnter: func.isRequired,
  isUserAuthenticated: bool.isRequired,
  handleProfileRedirect: func.isRequired,
  updateContextAndNavigate: func.isRequired,
  isNewEmail: bool,
};

export default Navigation;
